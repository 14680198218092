import React from 'react'
import styled from 'styled-components';

const SelectBasic = styled.select`
    background: white;
    border: 2px solid #8a8a8a;
    border-radius: 5px;
    font-size: 14px;
    /* height: 30px; */
    padding: 5px 3px;
    width: 50px;
    font-weight: bold;
    text-align: center;
    :focus{ outline: none;}
    height: 100%;
`;

export default function Select({ value, title, children, onChange }) {
    return (
        <SelectBasic
            title={title}
            id={value}
            name={value}
            onChange={onChange}
            defaultValue={value}
        >
            {children}
        </SelectBasic>
    )
}
