import React from 'react'
// import Avatar from "./../../assets/profile.png";

export default function Preload() {
    return (
        <div className="preloader flex-column justify-content-center align-items-center">
            {/* <img className="animation__shake" src={Avatar} alt="AdminLTELogo" height={60} width={60} /> */}
            <h2 className="animation__shake" style={{ fontFamily: 'Satisfy', fontSize: '50px', color: '#dc3545', fontWeight: 'bold' }}>
                Soya Sari
            </h2>
        </div>
    )
}
