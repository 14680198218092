import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function NavItem({
    to,
    icon,
    title,
    dataWidget,
    bg,
    badge,
    subtitle,
    data
}) {
    const [isHover, setIsHover] = useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(!isHover);
    };

    // const globalData = useSelector(state => state.globalData)
    return (
        <Link
            className={`nav-item nav-link  ${bg && 'bg-warning fw-bold elevation-3'}`}
            data-widget={dataWidget}
            style={{
                borderRadius: '10px',
                color: isHover ? 'rgba(255,255,255,.8)' : 'rgba(255,255,255,.8)',
                background: isHover ? 'rgba(255,255,255,.1)' : '',
            }}
            to={to}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <i
                style={{
                    // verticalAlign: 'middle',
                    fontSize: '23px',
                    // background:'red',
                    padding: '1px',
                    borderRadius: '50%'
                }}
                className={icon + ` ${data > 0 && badge && 'text-danger'}`}
            />
            <p
                className={`pl-2 ${data > 0 && badge && 'text-danger fw-bold'}`}
                style={{
                    fontSize: '16px',
                }}
            >
                {title}{subtitle && <span className='active-animation'>.</span>}
                {' '}
                {
                    badge && data !== 0 &&
                    <span className="badge badge-danger">{data}</span>
                }
            </p>
        </Link>

    )
}
