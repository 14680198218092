export function formatDefaultDate(dateString) {
    if (!dateString)
        return '-'
    const date = new Date(dateString)

    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    return `${day}/${month}/${year}`
}

export const ToTwoDecimal = (val) => {
    const newVal = parseFloat(val.toFixed(2))
    return newVal
}