import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ButtonSubmitSave from '../../../components/button/ButtonSubmitSave'
import { toastToggleAction } from '../../../redux/action/toastAction'
import InputFile from '../../../components/textField/InputFile'
import IconCheckBordered from '../../../components/icon/IconCheckBordered'
import Label from '../../../components/label/Label'
import { ClientUploadImageApi } from '../../../http/Services/ClientApi'
// import { recall } from '../../globalParams/GlobalDataFilter'
import { setRecall2 } from '../../../redux/action/globalParamsAction'

export default function ClientImageModalCreate({
    id,
    open,
    openModalImageCreate,
    // setReset,
    // resett,
    // recallAction
    // load,
    // setLoad
}) {
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const dispatch = useDispatch()
    const [fileIn, setFileIn] = useState(null)
    const [upload, setUpload] = useState(false)

    const reset = () => {
        setFileIn(null)
        setUpload(false)
    };

    const uploadImage = (e) => {
        console.log(e.target.files[0].size)
        if (e.target.files[0].size > 5000000) {
            dispatch(toastToggleAction({
                show: true,
                bg: 'danger',
                title: 'Error!',
                message: 'El limite por imagen es de 5 MB!',
                variant: 'bold'
            }))
        }
        else {
            setFileIn(e.target.files)
            setUpload(true)
        }

    }

    const handleSubmit = (event) => {
        event.preventDefault();
        uploadImg()
    };

    const uploadImg = async () => {
        try {
            setButtonDisabled(true)
            const data = new FormData()
            data.append("file", fileIn[0])

            data.append("upload_preset", "jbq6smvh")

            const res = await fetch(
                "https://api.cloudinary.com/v1_1/ddapdj8v2/image/upload",
                {
                    method: "POST",
                    body: data
                }
            )
            const file = await res.json()
            if (res.status === 200) {
                let upImage = {
                    user_id: id,
                    foto_negocio: file.secure_url
                }
                try {

                    let res = await ClientUploadImageApi(upImage)
                    if (res.status === 200 || res.status === 201) {
                        dispatch(toastToggleAction({
                            show: true,
                            bg: 'success',
                            title: 'Exito!',
                            message: 'Imagen guardada correctamente!',
                            variant: 'bold'
                        }))
                        // setGeoCurrent(null)
                        dispatch(setRecall2())
                        setFileIn(null)
                        setUpload(false)
                        setButtonDisabled(false)
                        // setReset(!resett)
                        openModalImageCreate()
                        // recall(setLoad, load)
                    }
                    else {
                        dispatch(toastToggleAction({
                            show: true,
                            bg: 'warning',
                            title: 'Alerta!',
                            message: 'La imagen podria no haberse cargado correctamente!',
                            variant: 'bold'
                        }))
                        setButtonDisabled(false)
                    }
                } catch (error) {
                    dispatch(toastToggleAction({
                        show: true,
                        bg: 'danger',
                        title: 'Error!',
                        message: 'Error al guardar imagen!',
                        variant: 'bold'
                    }))
                    setButtonDisabled(false)
                }
            }
            else {
                dispatch(toastToggleAction({
                    show: true,
                    bg: 'danger',
                    title: 'Error!',
                    message: 'Formato de archivo incorrecto!',
                    variant: 'bold'
                }))
                setButtonDisabled(false)
            }
        } catch (error) {
            dispatch(toastToggleAction({
                show: true,
                bg: 'danger',
                title: 'Error!',
                message: 'Formato de archivo incorrecto!',
                variant: 'bold'
            }))
            setButtonDisabled(false)
        }
    }


    return (
        <Modal show={open} onHide={openModalImageCreate} className='elevation-1' size='lg'>
            <Modal.Header closeButton>
                <Modal.Title className='h6 fw-bold text-secondary'>Subir Foto de negocio</Modal.Title>
            </Modal.Header>
            <React.Fragment>
                <form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <div className={`col-md-12 ${upload && 'd-none'}`}>
                            <Label
                                title='Foto de negocio'
                            >
                                <InputFile
                                    onChange={uploadImage}
                                    placeholder='Foto del negocio'
                                    // value={values.avatarImg}
                                    name='avatarImg'
                                />
                            </Label>
                        </div>
                        <div className={`col-md-12 my-2 text-center table-success ${!upload && 'd-none'} `}>
                            <button type='button' onClick={reset} className='btn btn-link text-dark' disabled={buttonDisabled}><i className="bi bi-x-lg" /></button>{' '}<small><strong>imagen seleccionada...</strong></small><IconCheckBordered />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={openModalImageCreate}>
                            Cancelar
                        </Button>
                        <ButtonSubmitSave
                            text='Guardar'
                            color='#00bfa5'
                            disabled={buttonDisabled}
                        />
                    </Modal.Footer>
                </form>
            </React.Fragment>
        </Modal >
    )
}
