import React from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { toastDisabledAction } from '../../redux/action/toastAction';

export default function ToastBasic() {
    const dispatch = useDispatch();
    const toast = useSelector(state => state.toast)
    return (
        <ToastContainer className="p-4 rounded position-fixed" position={'top-center'}
            style={{
                borderRadius: '20px'
            }}

        >
            <Toast
                className="d-inline-block m-1 rounded"
                bg={toast.bg}
                style={{
                    borderRadius: '20px'
                }}

                onClose={() => dispatch(toastDisabledAction())} show={toast.show} delay={3500} autohide
            >
                <Toast.Header
                    // style={{
                    //     color: 'white',
                    // }}
                    closeButton
                    // closeVariant='white'
                    className='px-3'
                >
                    {/* {toast.img &&
                        <img
                            src={toast.img}
                            className="rounded me-2"
                            alt=""
                        />
                    } */}
                    <span
                        style={{
                            fontSize: '20px'
                        }}
                    >
                        {toast.bg === 'danger' &&
                            <i className="bi bi-exclamation-circle-fill pr-1" />
                        }
                        {toast.bg === 'success' &&
                            <i className="bi bi-check-circle-fill pr-1" />
                        }
                        {toast.bg === 'warning' &&
                            <i className="bi bi-exclamation-triangle-fill pr-1" />
                        }
                    </span>
                    <span
                        className="me-auto"
                        style={{
                            fontWeight: 900,
                            fontSize: '14px'
                        }}
                    >
                        {toast.title}
                    </span>
                </Toast.Header>
                {
                    toast.message &&
                    <Toast.Body>
                        <strong>{toast.message}</strong>
                    </Toast.Body>
                }
            </Toast>
        </ToastContainer>
    )
}
