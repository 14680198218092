import React from 'react'

export default function ButtonBar({
    text,
    disabled,
    color = '#dc3545',
    onClick,
    fs,
    background
}) {
    return (
        <button
            onClick={onClick}
            // style={{ background: '#00bfa5', color: 'white' }}
            className={`btn elevation-2 border border-none bg-${color}`}
            style={{
                color: 'rgb(250,250,250)',
                fontSize: fs ? '14px' : '14px',
                
                padding: '7px 10px',
                // width:'100%',
                fontWeight: 'bold',
                background: background,
            }}
            disabled={disabled}
        >
            {text}
        </button>
    )
}
