import React from 'react'
import styled from 'styled-components'

const Div = styled.div`
    background:#ffc107;
      :hover{
        background: #ebb20a;
      }
    `;

export default function CardButton({ title, icon, onClick }) {
    return (
        <Div
            className="small-box"
            onClick={onClick}
            style={{
                cursor: 'pointer'
            }}
        >
            <div className="inner">
                <div className='col-12 d-flex justify-content-center'>
                    <div
                        style={{
                            fontSize: '60px',
                            color: 'rgba(10,10,10,.3)'
                        }}
                    >
                        {icon}
                    </div>
                </div>
                <h5
                    className='animation__shake d-flex justify-content-center'
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    {title}
                </h5>
            </div>
        </Div>
    )
}
