import axios from 'axios'
import config from '../config.json'
axios.defaults.baseURL = config.requestURL

axios.interceptors.request.use(
    (config) => {
        // const token_seguridad = window.localStorage.getItem('token')
        const token_seguridad = window.sessionStorage.getItem('token')
        if (token_seguridad) {
            config.headers.Authorization = 'Bearer ' + token_seguridad
            return config
        }
        else {
            return config
        }
    },
    (error) => {
        return Promise.reject(error)
    },
)

export default axios
