import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import ButtonSubmitSave from '../button/ButtonSubmitSave'
import { Form, Formik } from 'formik'
import { ModalFormField } from './ModalFormField'
import { requestAuth } from '../../http/httpRequest'
import { useDispatch } from 'react-redux'
import { setRecall1, setRecall2 } from '../../redux/action/globalParamsAction'
import { toastToggleAction } from '../../redux/action/toastAction'
import { useNavigate } from 'react-router-dom'

export const ModalForm = ({
    show,
    onHide,
    formData,
    method,
    urlApi,
    back,
    title
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return (
        <Modal show={show} onHide={onHide} className='elevation-1' size='lg'>
            <Modal.Header closeButton>
                <Modal.Title className='h6 fw-bold text-secondary'>{title}</Modal.Title>
            </Modal.Header>
            {
                <Formik
                    initialValues={formData.initialValues}
                    onSubmit={async (values, { resetForm }) => {
                        setIsSubmitting(true);
                        await requestAuth(
                            method,
                            urlApi,
                            values
                        )
                            .then((response) => {
                                if (back) {
                                    dispatch(setRecall1())
                                    navigate('..')
                                    // dispatch(setRecall2())
                                }
                                else {
                                    dispatch(setRecall1())
                                    dispatch(setRecall2())
                                }
                                onHide()
                                dispatch(
                                    toastToggleAction({
                                        show: true,
                                        bg: 'success',
                                        title: 'EXITO!',
                                        message: response?.data?.message ?? 'Guardado correctamente!',
                                        variant: 'bold',
                                    })
                                );
                            }
                            )
                            .catch(error => {
                                dispatch(
                                    toastToggleAction({
                                        show: true,
                                        bg: 'danger',
                                        title: 'ERROR!',
                                        message: error?.response?.data?.message ?? 'Error al guardar los datos del formulario!',
                                        variant: 'bold',
                                    })
                                );
                            })
                        setIsSubmitting(false)
                    }}
                >
                    {({ values, setFieldValue, handleChange, errors, touched }) => (
                        <Form>
                            <Modal.Body>
                                <ModalFormField
                                    formData={formData}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={onHide}>
                                    Cancelar
                                </Button>
                                <ButtonSubmitSave
                                    text='Guardar'
                                    color='#00bfa5'
                                    disabled={isSubmitting}
                                />
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            }
        </Modal>
    )
}