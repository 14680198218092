import React from 'react'
import { Table } from './Table'
import { Tr } from './Tr'
import { SimpleThParam, ThParam } from '../../views/globalParams/ThParam'
import Loading from '../../views/globalParams/Loading'
import styled from 'styled-components'
import Avatar from '../../assets/profile.png'
import { VerificationImageExist } from '../../utils/VerificationImage'
import { TagDic } from '../../utils/dictionary'
import Info from '../../views/globalParams/filter/Info'
import { useNavigate } from 'react-router-dom'
import ErrorCard from '../../views/globalParams/ErrorCard'

const TrBasic = styled.tr`
    /* :nth-child(even){ */
    :nth-child(odd){
        background: rgb(231, 231, 231);
    }
    :hover{
        background: rgb(214, 214, 214);
    }
`;

export const TableContainer = ({ headers, data, setData }) => {
    const navigate = useNavigate()
    const navToggle = (item) => {
        if (item?.id === data?.selected?.id) {
            navigate('.')
        }
        else {
            data.nav && navigate(`${data.navParam}${item[data.navRef]}`)
        }
    }
    return (
        data?.loading ?
            <Loading />
            :
            !data?.error ?
                <>
                    {
                        !data.hiddenInfo &&
                        <Info
                            params={{ totalItems: data?.params?.totalItems, totalPages: data?.params?.totalPages, currentPage: data?.params?.currentPage, pageSize: data?.params?.pageSize }}
                        />
                    }
                    <div className='overflow-auto'>
                        <Table>
                            <thead>
                                <Tr
                                >
                                    {
                                        headers?.map((head, index) => (
                                            < ThParam
                                                key={index}
                                                label={head.label}
                                            />
                                        ))
                                    }
                                </Tr>
                            </thead>
                            {data?.data?.length > 0 ?
                                <tbody>
                                    {
                                        data?.data?.map((item, index) => (
                                            <TrBasic
                                                key={index}
                                                style={{
                                                    fontSize: '15px',
                                                    cursor: 'pointer'
                                                }}
                                                className={`${item?.id === data?.selected?.id ? 'table-primary' : ''}`}
                                                onClick={() => {
                                                    setData(x => ({ ...x, selected: item?.id === x?.selected?.id ? null : item }))
                                                    navToggle(item)
                                                }}
                                            >
                                                {
                                                    headers?.map((head, idx) => (
                                                        <Cell
                                                            key={idx}
                                                            head={head}
                                                            item={item}
                                                            setData={setData}
                                                            navToggle={navToggle}
                                                        />
                                                    ))
                                                }
                                            </TrBasic>
                                        ))
                                    }
                                </tbody >
                                :
                                <tbody>
                                    <tr>
                                        <td className='text-sm p-2' style={{ color: 'rgba(150,150,150)' }} colSpan={headers.length}>
                                            <i className='fa fa-triangle-exclamation' /> Sin registros para mostrar!
                                        </td>
                                    </tr>
                                </tbody>
                            }
                        </Table>
                    </div >
                    {
                        !data.hiddenInfo &&
                        <Info
                            params={{ totalItems: data?.params?.totalItems, totalPages: data?.params?.totalPages, currentPage: data?.params?.currentPage, pageSize: data?.params?.pageSize }}
                        />
                    }
                </>
                :
                <ErrorCard />
    )
}

export const SimpleTableContainer = ({ headers, data, loading }) => {
    // const navigate = useNavigate()
    // const navToggle = (item) => {
    //     if (item?.id === data?.selected?.id) {
    //         navigate('.')
    //     }
    //     else {
    //         data.nav && navigate(`${data.navParam}${item[data.navRef]}`)
    //     }
    // }
    return (
        loading ?
            <Loading />
            :
            !data?.error ?
                data?.length > 0 ?
                    <Table>
                        <thead>
                            <Tr
                            >
                                {
                                    headers?.map((head, index) => (
                                        < SimpleThParam
                                            key={index}
                                            label={head.label}
                                        />
                                    ))
                                }
                            </Tr>
                        </thead>
                        <tbody>
                            {
                                data?.map((item, index) => (
                                    <TrBasic
                                        key={index}
                                        style={{
                                            fontSize: '15px',
                                            cursor: 'pointer'
                                        }}
                                    // className={`${item?.id === data?.selected?.id ? 'table-primary' : ''}`}
                                    // onClick={() => {
                                    //     // setData(x => ({ ...x, selected: item?.id === x?.selected?.id ? null : item }))
                                    //     // navToggle(item)
                                    // }}
                                    >
                                        {
                                            headers?.map((head, idx) => (
                                                <Cell
                                                    key={idx}
                                                    head={head}
                                                    item={item}
                                                />
                                            ))
                                        }
                                    </TrBasic>
                                ))
                            }
                        </tbody >
                    </Table>
                    :
                    <div className='text-sm p-2 font-italic text-bold text-center' style={{ color: 'rgba(170,170,170)' }} colSpan={headers.length}>
                        Sin registros!
                    </div>
                :
                <ErrorCard />
    )
}

const Cell = ({ head, item, setData, navToggle }) => {
    const TdBasic = styled.td`
  padding: 4px;
  border: 1px solid #c7c7c7;
  overflow: hidden;
`;

    return (
        <TdBasic>
            <div className="d-flex align-items-center gap-1" style={{ justifyContent: head.alignRight ? 'end' : '' }}>
                {
                    head.avatar &&
                    <AvatarParam
                        image={item[head.avatar]}
                    />
                }
                {
                    head.type === 'list' &&
                    <ListData
                        head={head}
                        item={item}
                    />
                }
                {
                    head.multiLine &&
                    <ValidationMultiLine
                        head={head}
                        item={item}
                    />
                }
                {
                    head.type === 'tag-simple' &&
                    <TagSimple
                        head={head}
                        item={item}
                    />
                }
                {
                    head.type === 'tag-bool' &&
                    <TagBool
                        head={head}
                        item={item}
                    />
                }
                {
                    head.type === 'date' &&
                    <CellDate
                        head={head}
                        item={item}
                    />
                }
                {
                    head.type === 'acciones' &&
                    <CellButtons
                        head={head}
                        item={item}
                        setData={setData}
                        navToggle={navToggle}
                    />
                }
            </div>
        </TdBasic>
    )
}

const AvatarParam = ({ image }) => {
    return (
        <img
            className="p-0"
            src={image ? require(`../../assets/${VerificationImageExist(image)}`) : Avatar}
            alt="img"
            style={{
                width: '30px',
                height: '30px',
                alignItems: 'center',
                textAlign: 'center',
                verticalAlign: 'middle'
            }}
        />
    )
}

const ValidationMultiLine = ({ head, item }) => {
    return (
        <div>
            {
                head.multiLine ?
                    head.value.map((val, index) => (
                        <div
                            key={index}
                            style={{ fontSize: '13px' }}
                        >
                            {item[val]}
                        </div>
                    ))
                    :
                    <div>
                        {item[head.value]}
                    </div>
            }
        </div>
    )
}

const ListData = ({ head, item }) => {
    return (
        <ul style={{ fontSize: '13px', marginBottom: '0px', marginLeft: '0px', paddingLeft: '15px' }}>
            {
                item[head.value]?.map((val, index) => (
                    <li
                        key={index}
                    >
                        {
                            head.items?.map((i, idx) => (
                                <div
                                    key={idx}
                                >
                                    {val[i]}
                                </div>
                            ))
                        }
                    </li>
                ))
            }
        </ul >
    )
}

const TagSimple = ({ head, item }) => {
    return (
        <div style={{ margin: 'auto' }}>
            <div style={{
                color: TagDic[item[head.value]],
                fontSize: '13px',
                fontStyle: 'italic',
                fontWeight: 'bold',
                textTransform: 'lowercase',
            }}>
                {item[head.value] && (
                    <>
                        <span style={{ textTransform: 'uppercase' }}>
                            {item[head.value].charAt(0)}
                        </span>
                        {item[head.value].substring(1)}
                    </>
                )}
            </div>
        </div>
    )
}

const TagBool = ({ head, item }) => {
    return (
        <div style={{ margin: 'auto' }}>
            <div style={{
                color: TagDic[item[head.value]],
                fontSize: '13px',
                fontStyle: 'italic',
                fontWeight: 'bold',
            }}>
                {item[head.value] ?
                    <span className='text-success'>
                        {head.refBool[0]}
                    </span>
                    :
                    <span className='text-danger'>
                        {head.refBool[1]}
                    </span>
                }
            </div>
        </div>
    )
}

const CellDate = ({ head, item }) => {
    return (
        <div>
            <div style={{
                color: TagDic[item[head.value]],
                fontSize: '13px',
            }}>
                {item[head.value] && (
                    (() => {
                        switch (head.funcTransform) {
                            case 'dateDetailString':
                                <div>demo</div>
                                break;
                            default:
                                return item[head.value]
                        }
                    })()
                )}
            </div>
        </div>
    )
}

const CellButtons = ({ head, item, setData, navToggle }) => {
    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            className='d-flex gap-1'
        >
            {
                head?.actions?.map((ac, index) => (
                    <button
                        key={index}
                        onClick={() => {
                            ac?.func(item)
                            setData(x => ({ ...x, selected: item }))
                            navToggle(item)
                        }}
                        className={`btn btn-${ac?.disabledFunc && ac?.disabledFunc(item) ? 'secondary' : ac?.adCNButton} py-1 px-2`}
                        disabled={ac?.disabledFunc && ac?.disabledFunc(item)}
                    >
                        <i className={`fa-solid fa-${ac?.adCNIcon ?? ''}`} />
                    </button>
                ))
            }
        </div>
    )
}