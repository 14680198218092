import React from 'react'

export default function InputTextBasic({ name, placeholder, id, icon, type, onChange, value, disabled, minLength = '', maxLength = '', pattern }) {
    return (
        <div className="input-group mb-1">
            <input
                type={type}
                className="form-control"
                id={id}
                placeholder={placeholder}
                name={name}
                onChange={onChange}
                value={value}
                disabled={disabled}
                required={true}
                minLength={minLength}
                maxLength={maxLength}
                pattern={pattern}
            />
            {
                icon &&
                <div className="input-group-append">
                    <div className="input-group-text">
                        {icon}
                    </div>
                </div>
            }
        </div>
    )
}
