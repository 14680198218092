import React from 'react'
import { Form } from 'react-bootstrap'

export default function InputFile({
    label,
    onChange,
    placeholder,
    value,
    name,
    disabled
}) {
    return (
        // <Form.Group controlId="formFile" className="mb-2">
        //     <Form.Label
        //         style={{
        //             fontSize: '13px',
        //             fontWeight: 'bold'
        //         }}
        //         className='m-0'
        //     >
        //         {label}
        //     </Form.Label>
        <Form.Control
            type="file"
            style={{
                boxShadow: 'none',
                // fontSize: '13px'
            }}
            size='sm'
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            name={name}
            disabled={disabled}
        />
        // </Form.Group>
    )
}
