import React, { useEffect, useState } from 'react'
import ButtonBarFlex from '../../../components/button/ButtonBarFlex'
import { DateConvert } from '../../../utils/dateConvert'
import { DefaultRequestAuth } from '../../../http/httpRequest'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setRecall1 } from '../../../redux/action/globalParamsAction'
import ButtonBar from '../../../components/button/ButtonBar'
import { ModalForm } from '../../../components/modal/ModalForm'
import { CobrarDeudaForm, DevolucionDeGarantiasForm, DevolucionDePrestamosForm, GastoForm, VenderProductoNoRetornableForm, VenderProductoRetornableForm } from './MiCuadernoFormDatas'

export const MiCuadernoCard = ({ loading }) => {
    const params = useParams()
    const [dataCuaderno, setDataCuaderno] = useState({
        data: {},
        recall: true,
        nav: false,
        navParam: '',
        navRef: 'id',
        loading: false,
        subId: 'clientId',
        params: null,
        filters: [],
        selected: null,
        modalVenderProductoRetornable: false,
        modalVenderProductoNoRetornable: false,
        modalEditarCliente: false,
        modalEliminarCliente: false,
        hiddenInfo: true
    })
    const dispatch = useDispatch()
    const globalParams = useSelector(state => state.globalParams)
    const getClientes = async () => {
        await DefaultRequestAuth(
            'get',
            `/cuaderno/${params.cuaderno_id}`,
            setDataCuaderno,
            'data',
            dispatch,
        )
    }

    useEffect(() => {
        getClientes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalParams.recall1])
    return (
        <>
            <div className='col-12'>
                <div className='card'>
                    <div className='card-body overflow-auto' style={{ fontSize: '14px' }}>
                        <div className='row gap-4 d-flex justify-content-center'>
                            <div
                                className='col-12 col-lg-3'
                            >
                                <div className='row gy-0 gx-1'>
                                    <div className='col-12 p-0'>
                                        <span className='card-text'
                                            style={{
                                                fontWeight: '600'
                                            }}
                                        >Número: </span>
                                        <span className='card-text fw-bold text-danger h3' >{dataCuaderno.data.codigo}</span>
                                    </div>
                                    <div className='col-12 p-0'>
                                        <span className='card-text'
                                            style={{
                                                fontWeight: '600'
                                            }}
                                        >Estado: </span>
                                        <span className={`card-text fw-bold text-${dataCuaderno.data?.abierto ? 'primary' : 'danger'}`} >{dataCuaderno.data?.abierto ? 'abierto' : 'cerrado'}</span>
                                    </div>
                                    <div className='col-12 p-0'>
                                        <span className='card-text'
                                            style={{
                                                fontWeight: '600'
                                            }}
                                        >Vendedor: </span>
                                        <span className='card-text fw-bold text-primary' >{(dataCuaderno.data?.nombres ? dataCuaderno.data?.nombres : '') + ' ' + (dataCuaderno.data?.apellido_paterno ? dataCuaderno.data?.apellido_paterno : '') + ' ' + (dataCuaderno.data?.apellido_materno ? dataCuaderno.data?.apellido_materno : '')}</span>
                                    </div>
                                    <div className='col-12 p-0'>
                                        <span className='card-text'
                                            style={{
                                                fontWeight: '600'
                                            }}
                                        >Fecha de apertura:</span>
                                        <span className='card-text fw-bold text-primary' > {DateConvert(dataCuaderno.data?.createdAt)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-4 container'>
                                <ColItem
                                    label='Ventas Bs.'
                                    value={dataCuaderno.data?.venta_total}
                                />
                                <ColItem label='Deudas Bs.' value={dataCuaderno.data?.deuda_total} />
                                <ColItem label='Tranferencias Bs.' value={dataCuaderno.data?.transferencia_total} />
                                <ColItem label='Cobros Bs.' value={dataCuaderno.data?.cobros_total} />
                                <ColItem label='Gastos Bs.' value={dataCuaderno.data?.gastos_total} />
                                <ColItem label='Efectivo Bs.' value={dataCuaderno.data?.efectivo_total} borderBotton={false} />
                            </div>
                            <div className='col-12 col-lg-4 container'>
                                <ColItem label='Garantías Bs.' value={dataCuaderno.data?.garantias_total} />
                                <ColItem label='Gar. ingresos Bs.' value={dataCuaderno.data?.garantias_ingreso} />
                                <ColItem label='Gar. egresos Bs.' value={dataCuaderno.data?.garantias_egreso} />
                                <ColItem label='Efvo. sobrante Bs.' value={dataCuaderno.data?.tipo === 'sobrante' ? dataCuaderno.data?.faltante_sobrante_calculado_string : '0,00'} />
                                <ColItem label='Efvo. faltante Bs.' value={dataCuaderno.data?.tipo === 'faltante' ? dataCuaderno.data?.faltante_sobrante_calculado_string : '0,00'} />
                                <ColItem label='Efvo. neto Bs.' value={dataCuaderno.data?.efectivo_total_neto} borderBotton={false} />
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex flex-wrap gap-1 p-1'>
                        <ButtonBarFlex
                            text='Recargar'
                            // color='purple'
                            onClick={() => dispatch(setRecall1())}
                            fs='small'
                            icon='ion-loop'
                        />
                        {/* {(window.sessionStorage.getItem('dp') === 'adm' || window.sessionStorage.getItem('dp') === 'pvnt') && noteData?.id &&
                            // handleShowUpdateNoteCash &&
                            // <div className='col-6 col-sm-2'>
                            <ButtonBarFlex
                                text='Registrar efectivo'
                                disabled={noteData?.estado === 'abierto' ? false : true}
                                // color='warning'
                                onClick={() => console.log('asd')}
                                fs='small'
                            />
                            // </div>
                        } */}
                        <ButtonBar
                            text='Vender producto retornable'
                            color='danger'
                            onClick={() => setDataCuaderno(x => ({ ...x, modalVenderProductoRetornable: true }))}
                            fs='small'
                        />
                        <ButtonBar
                            text='Vender producto no retornable'
                            color='secondary'
                            onClick={() => setDataCuaderno(x => ({ ...x, modalVenderProductoNoRetornable: true }))}
                            fs='small'
                        />
                        <ButtonBar
                            text='Cobrar deuda'
                            color='primary'
                            onClick={() => setDataCuaderno(x => ({ ...x, modalCobrarDeuda: true }))}
                            fs='small'
                        />
                        <ButtonBar
                            text='Anotar gasto'
                            color='warning'
                            onClick={() => setDataCuaderno(x => ({ ...x, modalGasto: true }))}
                            fs='small'
                        />
                        <ButtonBar
                            text='Devolución de préstamos'
                            color='info'
                            onClick={() => setDataCuaderno(x => ({ ...x, devolucionPrestamo: true }))}
                            fs='small'
                        />
                        <ButtonBar
                            text='Devolución de garantía'
                            color='success'
                            onClick={() => setDataCuaderno(x => ({ ...x, devolucionGarantia: true }))}
                            fs='small'
                        />
                    </div>
                    <LoadingFlex
                        loading={loading}
                    />
                </div>
            </div>
            {
                dataCuaderno.modalVenderProductoRetornable &&
                <ModalForm
                    show={dataCuaderno.modalVenderProductoRetornable}
                    onHide={() => setDataCuaderno(x => ({ ...x, modalVenderProductoRetornable: false }))}
                    urlApi={'/cuaderno_historial/venta-retornable'}
                    method='post'
                    formData={VenderProductoRetornableForm(dataCuaderno.data)}
                    title='Venta de producto retornable'
                />
            }
            {
                dataCuaderno.modalVenderProductoNoRetornable &&
                <ModalForm
                    show={dataCuaderno.modalVenderProductoNoRetornable}
                    onHide={() => setDataCuaderno(x => ({ ...x, modalVenderProductoNoRetornable: false }))}
                    urlApi={'/cuaderno_historial/venta-no-retornable'}
                    method='post'
                    formData={VenderProductoNoRetornableForm(dataCuaderno.data)}
                    title='Venta de producto no retornable'
                />
            }
            {
                dataCuaderno.modalCobrarDeuda &&
                <ModalForm
                    show={dataCuaderno.modalCobrarDeuda}
                    onHide={() => setDataCuaderno(x => ({ ...x, modalCobrarDeuda: false }))}
                    urlApi={`/deuda`}
                    method='post'
                    formData={CobrarDeudaForm(dataCuaderno.data)}
                    title='Cobrar deuda'
                />
            }
            {
                dataCuaderno.modalGasto &&
                <ModalForm
                    show={dataCuaderno.modalGasto}
                    onHide={() => setDataCuaderno(x => ({ ...x, modalGasto: false }))}
                    urlApi={`/cuaderno_historial/gasto`}
                    method='post'
                    formData={GastoForm(dataCuaderno.data)}
                    title='Anotar deuda'
                />
            }
            {
                dataCuaderno.devolucionPrestamo &&
                <ModalForm
                    show={dataCuaderno.devolucionPrestamo}
                    onHide={() => setDataCuaderno(x => ({ ...x, devolucionPrestamo: false }))}
                    urlApi={`/deudaEnvasePrestamo`}
                    method='post'
                    formData={DevolucionDePrestamosForm(dataCuaderno.data)}
                    title='Devolución de prestamo'
                />
            }
            {
                dataCuaderno.devolucionGarantia &&
                <ModalForm
                    show={dataCuaderno.devolucionGarantia}
                    onHide={() => setDataCuaderno(x => ({ ...x, devolucionGarantia: false }))}
                    urlApi={`/deudaEnvaseGarantia`}
                    method='post'
                    formData={DevolucionDeGarantiasForm(dataCuaderno.data)}
                    title='Devolución de garantía'
                />
            }
        </>
    )
}

const LoadingFlex = ({ loading }) => {
    return (
        <div className={`d-${loading ? 'flex' : 'none'}`}
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                background: 'rgba(255,255,255,.8)',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <span className='loader'></span>
        </div>
    )
}

const ColItem = ({ label, value }) => {
    return (
        <div className='row'>
            <div className='col' style={{ borderBottomWidth: '1px', borderBottomStyle: 'dashed' }}>{label}</div>
            <div className='col text-right text-danger fw-bold' style={{ borderBottomWidth: '1px', borderBottomStyle: 'dashed' }}>{value}</div>
        </div>
    )
}