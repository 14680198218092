import { combineReducers } from 'redux'
import { authReducer } from './authReducer';
import { globalDataReducer } from './globalDataReducer';
import { locationClientReducer } from './locationClientReducer';
import { noteReducer } from './noteReducer';
import { toastIconReducer } from './toastIconReducer';
import { toastReducer } from './toastReducer';
import { userReducer } from './userReducer';
import { globalParamsReducer } from './globalParamsReducer';

const reducer = combineReducers({
    auth: authReducer,
    locationClient: locationClientReducer,
    toast: toastReducer,
    toastIcon: toastIconReducer,
    note: noteReducer,
    globalData: globalDataReducer,
    user: userReducer,
    globalParams: globalParamsReducer
});

export default reducer;