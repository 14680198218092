import React, { useState } from 'react';
// import {FaArrowCircleUp} from 'react-icons/fa';
import styled from 'styled-components';

const Button = styled.div`
   position: fixed; 
   /* width: 100%; */
   /* right: 0%; */
   bottom: 0px;
   right: 0px;
   /* height: 38px; */
   font-size: 2.2rem;
   z-index: 1000;
   cursor: pointer;
   border-radius: 50%;
   padding: 0px 10px;
`

export const ScrollButton = () => {

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 10) {
            setVisible(true)
        }
        else if (scrolled <= 10) {
            setVisible(false)
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <Button className="bi bi-arrow-up-circle-fill text-danger opacity-75" onClick={scrollToTop} style={{ display: visible ? 'inline' : 'none' }}>
            
        </Button>
    );
}

// export default ScrollButton;