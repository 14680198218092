import React from 'react'

export default function Thead({ columns }) {
    const click = () => { }
    return (
        <thead
            style={{
                fontSize: '13px',
            }}
        >
            <tr style={{ padding: '0px' }}>
                {
                    columns.map(col => (

                        col[1]
                            ?
                            <th
                                key={col}
                                style={{
                                    overflow: 'hidden',
                                    background: '#495057',
                                    color: '#eee',
                                    cursor: 'pointer',
                                }}
                                className='border border-secondary table-secondary'
                                onClick={() => click(col[1]())}
                            >
                                <div className='row p-0 m-0'
                                >
                                    <div className="col-9 p-0 d-flex"
                                        style={{
                                            alignItems: 'center'
                                        }}

                                    >{col[0]}</div>
                                    <div className="col-3 p-0 d-flex"
                                        style={{
                                            alignItems:'center',
                                            justifyContent:'right'
                                        }}
                                    >
                                        {col[2] && <div
                                            className="p-0 btn btn-link"
                                            style={{
                                                fontSize: '9px',
                                                textDecoration: 'none',
                                                color:'#8d9194'
                                            }}
                                        >
                                            <div className='bi bi-caret-up-fill d-flex' />
                                            <div className='bi bi-caret-down-fill d-flex' />
                                        </div>
                                        }
                                    </div>
                                </div>
                            </th>
                            :
                            <th
                                key={col}
                                style={{
                                    overflow: 'hidden',
                                    background: '#495057',
                                    color: '#eee',
                                    verticalAlign: 'middle'
                                }}
                                className='border border-secondary table-secondary'
                            >
                                {
                                    col[0]
                                }
                            </th>
                    ))
                }
            </tr>
        </thead >
    )
}