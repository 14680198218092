import { GET_GLOBAL_DATA } from "../type";

export const initialState = {
    orders: 0,
    newClients: 0,
    openNotes: 0,
    accounts: 0,
    barrelAccounts: 0
}

export function globalDataReducer(state = initialState, action) {
    switch (action.type) {
        case GET_GLOBAL_DATA:
            state = action.payload
            return state
        // case RECALL_GLOBAL_DATA: {

        //     const getGlobalData = async () => {
        //         try {
        //             let res = await GetGlobalDataApi();
        //             if (res.status === 200) {
        //                 console.log(res.data)
        //                 state = res.data
        //                 console.log(state)
        //                 return state
        //             }
        //         } catch (error) {
        //             console.log('error in reducers')
        //         }
        //     }
        //     getGlobalData()
        //     return {
        //         state
        //     }
        // }

        default:
            return state;
    }
}