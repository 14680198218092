import axios from "axios";

export async function GetProductsApi() {
    const response = axios({
        url: `/product`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}
