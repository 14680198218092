import React from 'react'

export default function SelecterDataButton({
    disabled,
    onClick,
    data
}) {
    return (
        <div
            style={{ textAlign: 'center' }}>
            <button className='btn btn-link p-0 m-0 ' type='button' disabled={disabled} onClick={onClick}>
                <i className="bi bi-pencil-square btn p-0 text-info" />
            </button>
            <em style={{ fontSize: '15px', fontWeight: 'bold', display: 'inline-block' }} >
                {data.nombre + ' ' + (data.apellido_paterno ? data.apellido_paterno : '') + ' ' + (data.apellido_materno ? data.apellido_materno : '')}
            </em>
        </div>
    )
}
