import React, { useEffect, useState } from 'react'
import { VerificationImageExist } from '../../../utils/VerificationImage'
import Avatar from '../../../assets/profile.png'
import GeneralContainer from '../../globalParams/GeneralContainer'
import { ThParam } from '../../globalParams/ThParam'
import { Tr } from '../../../components/table/Tr'
import { Table } from '../../../components/table/Table'
import { Td } from '../../../components/table/Td'
import { onChangeInputData, order, orderNum, recall } from '../../globalParams/GlobalDataFilter'
import Paginator from '../../globalParams/filter/Paginator'
import Searcher from '../../globalParams/filter/Searcher'
import Info from '../../globalParams/filter/Info'
import Loading from '../../globalParams/Loading'
import Detail from '../../globalParams/Detail'
import { useDispatch, useSelector } from 'react-redux'
import { toastToggleAction } from '../../../redux/action/toastAction'
import ButtonIcon from '../../../components/button/ButtonIcon'
import { DateConvert } from '../../../utils/dateConvert'
import { GetGlobalDataApi } from '../../../http/Services/UserApi'
import { getGlobalDataAction } from '../../../redux/action/globalDataAction'
import { DeleteOrderApi, GetOrdersApi } from '../../../http/Services/OrderApi'
import ModalChangeOrderState from './ModalChangeOrderState'
import ModalConfirmation from '../../../components/modal/ModalConfirmation'

export default function Orders() {

    const dispatch = useDispatch()
    const user = useSelector(state => state.auth)

    const [stateData, setStateData] = useState("loading...")
    const [load, setLoad] = useState(false)
    const [data, setData] = useState([])
    const [back, setBack] = useState([])
    const [items, setItems] = useState([])
    const [item, setItem] = useState({})
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [total, setTotal] = useState(0)
    const [desc, setDesc] = useState(true)
    const [byQuery, setByQuery] = useState("")
    const [full, setFull] = useState(true)
    const [modalConfirmation, setModalConfirmation] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [modalOrderState, setModalOrderState] = useState(false)

    useEffect(() => {
        setStateData("loading...")
        setItem({})
        const getOrders = async () => {
            try {
                let res = await GetOrdersApi();
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        setStateData("")
                        setByQuery("")
                    }
                    else {
                        setStateData("sin registros...")
                    }
                    setDesc(false)
                    setCurrentPage(1)
                    setData(res.data)

                    getGlobalData()
                }
            } catch (error) {
                setStateData("Error al cargar lista")
            }

        }
        getOrders()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load])


    useEffect(() => {
        setTotal(back.length)
        let div = back.length / perPage
        if (div - parseInt(div) === 0) {
            setTotalPages(div)
        }
        else {
            setTotalPages(parseInt(div) + 1)
        }
    }, [back, perPage])

    useEffect(() => {
        setCurrentPage(1)
    }, [perPage])


    useEffect(() => {
        // no replicar a = b por que se toma como si fuera el mismo
        const dataBackup = Array.from(data)
        setItems([
            ...dataBackup
                // .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery) || a.telefono.toLowerCase().includes(byQuery))
                .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery))
                .splice((currentPage * perPage - perPage), perPage)
        ])
        setBack([
            ...data
                // .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery) || a.telefono.toLowerCase().includes(byQuery))
                .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery))
        ])

        let div = back.length / perPage
        if (div - parseInt(div) === 0) {
            setTotalPages(div)
        }
        else {
            setTotalPages(parseInt(div) + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, currentPage, perPage])

    const getGlobalData = async () => {
        try {
            let res = await GetGlobalDataApi();
            if (res.status === 200) {
                dispatch(getGlobalDataAction(res.data))
            }
        } catch (error) {
            console.log('error in reducers')
        }
    }

    const onChangeInput = (e) => {
        onChangeInputData(
            setByQuery,
            e,
            data,
            currentPage,
            setCurrentPage,
            setItems,
            perPage,
            setBack,
            setStateData
        )
    }

    const deleteData = async () => {
        try {
            setButtonDisabled(true)
            let res = await DeleteOrderApi(item.id)
            if (res.status === 200) {
                handleCloseConfirmation()
                recall(setLoad, load)
                setButtonDisabled(false)
                getGlobalData()

                dispatch(toastToggleAction({
                    show: true,
                    bg: 'success',
                    title: 'Exito!',
                    message: 'Eliminado correctamente!',
                    variant: 'bold'
                }))
            }
        } catch (error) {
            setButtonDisabled(false)
            dispatch(toastToggleAction({
                show: true,
                bg: 'danger',
                title: 'Error!',
                message: error.response && error.response.data.message ? error.response.data.message : 'No se puede eliminar el cliente!',
                variant: 'bold'
            }))
        }
    }

    const handleCloseConfirmation = () => setModalConfirmation(false)
    const handleShowConfirmation = () => {
        setModalConfirmation(true);
    }
    const handleShowOrderState = () => {
        setModalOrderState(true);
    }
    const handleCloseOrderState = () => setModalOrderState(false)

    return (
        <GeneralContainer>
            <div className="row gx-3">
                <div className={`col-12 col-md-${full ? '12' : '6'}`}>
                    {
                        <>
                            <div className="card card-danger card-outline">
                                <div className="p-1">
                                    <Searcher
                                        onChangeInput={onChangeInput}
                                        byQuery={byQuery}
                                    />
                                </div>
                                <div className="p-1">
                                    <Paginator
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        totalPages={totalPages}
                                        setPerPage={setPerPage}
                                        perPage={perPage}
                                        setLoad={setLoad}
                                        load={load}
                                        stateData={stateData}
                                        setFull={setFull}
                                        full={full}
                                    />
                                </div>
                                <div className=" p-1 table-warning rounded-top">
                                    <Info
                                        total={total}
                                        currentPage={currentPage}
                                        perPage={perPage}
                                        totalPages={totalPages}
                                    />
                                </div>
                                <div className="card-body p-1">
                                    <div className="content-basic">
                                        <Table>
                                            <thead>
                                                <Tr>
                                                    {/* <ThParam
                                    label='Id'
                                    onClick={() => orderNum('id')}
                                /> */}
                                                    <ThParam
                                                        label='Código'
                                                        onClick={() => order('codigo', data, desc, setData, setDesc)}
                                                        width='110px'
                                                    />
                                                    <ThParam
                                                        label='Cliente'
                                                        onClick={() => order('cliente', data, desc, setData, setDesc)}
                                                        width='130px'
                                                    />
                                                    <ThParam
                                                        label='Celular'
                                                        onClick={() => order('telefono', data, desc, setData, setDesc)}
                                                        width='90px'
                                                    />
                                                    <ThParam
                                                        label='Producto'
                                                        onClick={() => order('telefono', data, desc, setData, setDesc)}
                                                        width='90px'
                                                    />
                                                    <ThParam
                                                        label='Cantidad'
                                                        onClick={() => order('telefono', data, desc, setData, setDesc)}
                                                        width='85px'
                                                    />
                                                    <ThParam
                                                        label='Estado'
                                                        onClick={() => order('estado', data, desc, setData, setDesc)}
                                                        width='75px'
                                                    />
                                                    <ThParam
                                                        label='Fecha de registro'
                                                        onClick={() => orderNum('id', data, desc, setData, setDesc)}
                                                        width='170px'
                                                    />
                                                    {
                                                        user && user.departament.nombre === "Administración" &&
                                                        <ThParam
                                                            label='Acciones'
                                                            // onClick={() => order('estado', data, desc, setData, setDesc)}
                                                            width='75px'
                                                        />
                                                    }
                                                </Tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    stateData !== "" ?
                                                        <tr>
                                                            <td colSpan={5}>
                                                                {stateData === "loading..."
                                                                    ?
                                                                    <Loading />
                                                                    :
                                                                    <div
                                                                        style={{
                                                                            fontSize: '14px',
                                                                            fontWeight: 'bold',
                                                                            color: 'rgba(0,0,0,.4)',
                                                                            padding: '5px'
                                                                        }}
                                                                    >
                                                                        {stateData}
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                        :
                                                        items?.map((data) => (
                                                            <Tr
                                                                key={data.id}
                                                                onClick={() => setItem(data)}
                                                                mark={item && item.id === data.id && true}
                                                            >
                                                                <Td
                                                                    width='110px'
                                                                >
                                                                    <div className="row g-0">
                                                                        <div className="col-12 d-flex"
                                                                            style={{
                                                                                alignItems: 'center',
                                                                                overflow: 'hidden'
                                                                            }}
                                                                        >
                                                                            <img
                                                                                className="p-0"
                                                                                src={data && data.avatar
                                                                                    ?
                                                                                    require(`../../../assets/${VerificationImageExist(data.avatar)}`) : Avatar}
                                                                                alt="img"
                                                                                style={{
                                                                                    width: '30px',
                                                                                    height: '30px',
                                                                                    alignItems: 'center',
                                                                                    textAlign: 'center',
                                                                                    verticalAlign: 'middle'
                                                                                }}
                                                                            />
                                                                            <span className='pl-1'>
                                                                                {data.codigo ? data.codigo : '---- ----'}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Td>
                                                                <Td
                                                                    width='130px'
                                                                >
                                                                    {data.cliente}{' '}
                                                                    {data.apellido_paterno}{' '}
                                                                    {data.apellido_materno}
                                                                </Td>
                                                                <Td
                                                                    width='90px'
                                                                >
                                                                    {data.telefono}
                                                                </Td>
                                                                <Td
                                                                    width='90px'
                                                                >
                                                                    {data.producto}
                                                                </Td>
                                                                <Td
                                                                    width='85px'
                                                                >
                                                                    {data.cantidad}
                                                                </Td>
                                                                <Td
                                                                    width='75px'
                                                                >
                                                                    {
                                                                        user && user.departament.nombre === "Administración" &&
                                                                        <button
                                                                            className='btn btn-link text-decoration-none p-0'
                                                                        >
                                                                            <small><b><i className={`text-${data.estado ? (data.estado === 'pendiente' ? 'danger' : (data.estado === 'entregado' ? 'success' : 'secondary')) : 'secondary'}`}>{data.estado}</i></b></small>
                                                                        </button>
                                                                    }
                                                                </Td>
                                                                <Td
                                                                    width='170px'
                                                                >
                                                                    {data.created_at ? DateConvert(data.created_at) : ''}
                                                                </Td>
                                                                {
                                                                    user && user.departament.nombre === "Administración" &&
                                                                    <Td
                                                                        width='75px'
                                                                        center={true}
                                                                    >
                                                                        <ButtonIcon
                                                                            icon={"bi bi-pencil-square"}
                                                                            variant="warning"
                                                                            title="Editar estado"
                                                                            onClick={() => handleShowOrderState(data.id)}
                                                                        />{' '}
                                                                        <ButtonIcon
                                                                            icon={"bi bi-trash-fill"}
                                                                            variant="danger"
                                                                            title="eliminar"
                                                                            onClick={() => handleShowConfirmation(data.id)}
                                                                        />

                                                                    </Td>
                                                                }
                                                            </Tr>
                                                        ))
                                                }
                                            </tbody >
                                        </Table >
                                    </div>
                                </div>
                                {
                                    total > perPage && stateData !== "loading..." &&
                                    <>
                                        <div className=" p-1 table-warning rounded-top">
                                            <Info
                                                total={total}
                                                currentPage={currentPage}
                                                perPage={perPage}
                                                totalPages={totalPages}
                                            />
                                        </div>
                                        <div className="p-1">
                                            <Paginator
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                totalPages={totalPages}
                                                setPerPage={setPerPage}
                                                perPage={perPage}
                                                setLoad={setLoad}
                                                load={load}
                                                stateData={stateData}
                                                setFull={setFull}
                                                full={full}
                                                hiddenpaginator={true}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
                {
                    item.id &&
                    <div className={`col-12 col-md-${full ? '12' : '6'}`}>
                        <div className="card">
                            <div className='card-header p-1'>
                                <div className='row'>
                                    <div className='col-8'>
                                        Detalles del pedido
                                    </div>
                                    <div className="col-4 d-flex justify-content-end">
                                        <div className='p-0'>
                                            <i className='bi bi-x-lg btn py-0 px-1' onClick={() => setItem({})} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="d-flex justify-content-end p-2">
                                    {
                                        user && user.departament.nombre === "Administración" &&
                                        <>
                                            <i
                                                className='bi bi-pencil-square btn btn-warning ml-2 elevation-2'
                                                onClick={() => handleShowOrderState(data.id)}
                                            />
                                            <i
                                                className='bi bi-trash-fill btn btn-danger ml-2 elevation-2'
                                                onClick={() => handleShowConfirmation(data.id)}
                                            />
                                        </>
                                    }
                                </div>
                                <Detail
                                    detail={[
                                        { label: 'Cliente', value: item.cliente + ' ' + (item.apellido_paterno ? item.apellido_paterno : '') + ' ' + (item.apellido_materno ? item.apellido_materno : '') },
                                        { label: 'Vendedor', value: item.u_nombre + ' ' + (item.u_apellido_paterno ? item.u_apellido_paterno : '') + ' ' + (item.u_apellido_materno ? item.u_apellido_materno : '') },
                                        { label: 'Producto', value: item.producto },
                                        { label: 'Descripción del producto', value: item.descripcion },
                                        { label: 'Cantidad', value: item.cantidad },
                                        { label: 'Precio por unidad', value: (item.precio ? item.precio : 0) + ' Bs.' },
                                        { label: 'Precio total', value: (item.total_precio ? item.total_precio : 0) + ' Bs.' },
                                        { label: 'Estado del pedido', value: item.estado },
                                        { label: 'Fecha de pedido', value: DateConvert(item.created_at) }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <ModalConfirmation
                show={modalConfirmation}
                handleClose={handleCloseConfirmation}
                text='¿Seguro que desea eliminar este pedido?'
                buttonText='Eliminar'
                variant='danger'
                onClick={() => deleteData()}
                buttonDisabled={buttonDisabled}
            />
            <ModalChangeOrderState
                show={modalOrderState}
                handleClose={handleCloseOrderState}
                text='Cambiar estado de pedido:'
                buttonDisabled={buttonDisabled}
                setButtonDisabled={setButtonDisabled}
                order={item}
                load={load}
                setLoad={setLoad}
            />
        </GeneralContainer>
    )
}

// 001-001

// adicionar campo codigo, numero_referencia en la tabla cliente


// boton ir arriba