import { DateConvert } from "../../utils/dateConvert"

export const onChangeInputData = (
    setByQuery,
    e,
    data,
    currentPage,
    setCurrentPage,
    setItems,
    perPage,
    setBack,
    setStateData
) => {
    // setByQuery(e.target.value ? e.target.value.toLowerCase().replace("  ", " ") : "")
    setByQuery(e.target.value ? e.target.value.toLowerCase().replace("  ", " ") : "")
    const dataBackup = Array.from(data)
    setCurrentPage(1)

    setItems([
        ...dataBackup
            .filter(a =>
                a.full_data?.replace("  ", " ").trim().toLowerCase().includes(e.target.value ? e.target.value.toLowerCase().replace("  ", " ") : "")
                ||
                (a.created_at != null && DateConvert(a.created_at).replace("  ", " ").trim().toLowerCase().includes(e.target.value ? e.target.value.toLowerCase().replace("  ", " ") : ""))
            )
            .splice((currentPage * perPage - perPage), perPage)
    ])

    setBack([
        ...dataBackup
            .filter(a =>
                a.full_data?.replace("  ", " ").trim().toLowerCase().includes(e.target.value ? e.target.value.toLowerCase().replace("  ", " ") : "")
                ||
                (a.created_at != null && DateConvert(a.created_at).replace("  ", " ").trim().toLowerCase().includes(e.target.value ? e.target.value.toLowerCase().replace("  ", " ") : ""))
            )
    ])

    if (dataBackup.filter(a => a.full_data?.replace("  ", " ").trim().toLowerCase().includes(e.target.value ? e.target.value.toLowerCase().replace("  ", " ") : "")
        ||
        (a.created_at != null && DateConvert(a.created_at).replace("  ", " ").trim().toLowerCase().includes(e.target.value ? e.target.value.toLowerCase().replace("  ", " ") : ""))
    ).length === 0) {
        setStateData("No se encontraron resultados")
    }
    else {
        setStateData("")
    }

}

export const prevHandler = (currentPage, setCurrentPage) => {

    if (currentPage > 1) {
        setCurrentPage(currentPage - 1)
    }
}

export const nextHandler = (currentPage, totalPages, setCurrentPage) => {
    if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1)
    }
}

export const perPageHandler = (setPerPage, perPage) => {
    setPerPage(perPage + 5)
}

export const recall = (setLoad, load) => {
    setLoad(!load)
}

export const order = (val, data, desc, setData, setDesc) => {
    const dataBackup = Array.from(data)
    if (!desc) {
        setData(dataBackup.sort((a, b) => (a[val]).localeCompare(b[val])).reverse())
    } else {
        setData(dataBackup.sort((a, b) => a[val].localeCompare(b[val])))
    }

    setDesc(!desc)
}
export const orderNum = (val, data, desc, setData, setDesc) => {
    const dataBackup = Array.from(data)
    if (desc) {
        setData(dataBackup.sort(function (a, b) { return a[val] - b[val] }).reverse())
    } else {
        setData(dataBackup.sort(function (a, b) { return a[val] - b[val] }))
    }
    setDesc(!desc)

}