import React from 'react'
import styled from 'styled-components'

const TdBasic = styled.td`
  padding: 5px;
  border: 1px solid #c7c7c7;
  overflow: hidden;
`;

export const Td = ({ children, width, fontWeight = '', onClick, center, size='' }) => {
    return (
        <TdBasic
            style={{
                width: width,
                maxWidth: width,
                minWidth: width,
                fontWeight: fontWeight,
                fontSize: size,
                textAlign: center ? 'center' : '',
            }}
            onClick={onClick}
        >
            {children}
        </TdBasic>
    )
}
