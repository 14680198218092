import React from 'react'
// import { useSelector } from 'react-redux'
// import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import { Outlet } from 'react-router-dom'

export default function Content() {
    // const location = useLocation()
    // const param = useParams()
    // const user = useSelector(state => state.auth)


    // function pathRoute(pathname) {
    //     switch (pathname) {
    //         case '/dashboard/home':
    //             return 'Inicio'
    //         case '/dashboard/profile':
    //             return 'Perfíl'
    //         case '/dashboard/clients':
    //             return 'Clientes'
    //         case '/dashboard/sellers':
    //             return 'Vendedores'
    //         case '/dashboard/points-of-sale':
    //             return 'Puntos de venta'
    //         case '/dashboard/orders':
    //             return 'Pedidos'
    //         case '/dashboard/notes':
    //             return 'Cuadernos diarios'
    //         case '/dashboard/debtors':
    //             return 'Deudas'
    //         case '/dashboard/barrel-debtors':
    //             return 'Deuda Turriles'
    //         case '/dashboard/my-orders':
    //             return 'Mis pedidos'
    //         case '/dashboard/warehouse':
    //             return 'Almacén'
    //         case '/dashboard/warehouses':
    //             return 'Almacenes'
    //         case '/dashboard/my-debts':
    //             return 'Mis deudas'
    //         case '/dashboard/accounts':
    //             return 'Deudas'
    //         case '/dashboard/barrel-accounts':
    //             return 'Deuda Turriles'
    //         case '/dashboard/my-barrel-debts':
    //             return 'Turriles a cuenta'
    //         default:
    //             break;
    //     }
    //     console.log(param)
    //     if (pathname.includes('/dashboard/notes/')) {

    //         if (user && user.departament.nombre === "Administración") {
    //             return 'Cuadernos diarios/' + param.noteId
    //         } else {
    //             return 'Cuaderno diario'
    //         }
    //     }
    //     else if (pathname.includes('/dashboard/clients/')) {
    //         return 'Clientes/' + param['*']
    //     }
    //     else if (pathname.includes('/dashboard/warehouse/')) {
    //         return 'Almacén/' + param.wareHouseId
    //     }
    //     else {
    //         return "Ruta desconocida";
    //     }
    // }

    // function pathFinItemRemove(pathname) {

    //     let val = pathname.split('/')
    //     val = val.slice(0, -1)
    //     val = val.filter(Boolean)
    //     // console.log(val)
    //     let result = val.join('/')
    //     return "/" + result

    // }
    return (
        <div className="content-wrapper pt-2" >
            {/* <div className="content-header px-1" >
                <div className="container-fluid py-0">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            {pathRoute(location.pathname) === "Inicio" ?
                                <li className={`breadcrumb-item active`} style={{ fontSize: '14px', fontWeight: 'bold' }}>Inicio</li>
                                :
                                <li className={`breadcrumb-item`} style={{ fontSize: '14px', fontWeight: 'bold' }}><Link to="/dashboard/home" className='text-decoration-none'>Inicio</Link></li>
                            }
                            {
                                pathRoute(location.pathname) !== "Inicio" &&
                                <>
                                    {
                                        pathRoute(location.pathname).split('/').length === 1 ?
                                            <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '14px', fontWeight: 'bold' }}>{pathRoute(location.pathname)}</li>
                                            :
                                            <>
                                                <li className="breadcrumb-item" aria-current="page" style={{ fontSize: '14px', fontWeight: 'bold' }}><Link to={pathFinItemRemove(location.pathname)} className='text-decoration-none'>{pathRoute(location.pathname).split('/')[0]}</Link></li>
                                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: '14px', fontWeight: 'bold' }}>{pathRoute(location.pathname).split('/')[1]}</li>
                                            </>
                                    }
                                </>
                            }
                        </ol>
                    </nav>
                </div>
            </div> */}
            <Outlet />
        </div>
    )
}
