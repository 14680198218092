import React from 'react'

export default function BoxAuth({ children, title, subtitle }) {
    return (
        <div className="hold-transition login-page" style={{ background: '#dc3545' }}>
            <div className="login-box">
                <div className="login-logo" style={{ fontFamily: 'Satisfy', fontSize: '40px', fontWeight: 'bold', color: '#fffb07' }}>
                    <b>{title}</b>
                </div>
                <div className="card" >
                    <div className="card-body login-card-body" >
                        <p className="login-box-msg text-muted"><em>{subtitle}</em></p>
                        {children}
                    </div>
                </div>
            </div>
        </div >
    )
}
