import React from 'react'

export default function ErrorCard() {
    return (
        <div className="p-5 pl-2 text-md-center d-flex w-full table-secondary"
            style={{
                fontSize: '14px',
                fontWeight: 'bold',
                // color: 'rgba(0,0,0,.4)'
                alignItems: 'center',
                verticalAlign: 'middle',
                margin: 'auto',
                textAlign:'center',
                justifyContent:'center'
            }}
        >
            {/* {stateData} */}
            <i className='fa fa-triangle-exclamation text-secondary'/><span className='ml-2 text-secondary'>Error al cargar la tabla!</span>
        </div>
    )
}
