import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Avatar from '../../../assets/profile.png'
import MapView from "../../../components/maps/MapView";
import { VerificationImageExist } from '../../../utils/VerificationImage'
import ClientImageModalCreate from './ClientImageModalCreate'

export default function ClientProfile({ data, loading }) {
    const auth = useSelector(state => state.auth)
    const [open, setOpen] = useState(false)

    useEffect(() => {

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth])

    const openModalImageCreate = () => {
        setOpen(!open)
    }

    return (
        data?.id ?
            <div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card card-danger card-outline">
                            <div className="card-body box-profile p-1">
                                <div className="text-center">
                                    <img className="" src={data && data?.avatar ? require(`../../../assets/${VerificationImageExist(data?.avatar)}`) : Avatar} alt="img" style={{ width: '150px', height: '150px' }} />
                                </div>
                                <h3 className="profile-username text-center">{data?.nombres || '---'} {data?.apellido_paterno || '---'}</h3>
                                <p className="text-muted text-center">{data?.tipo_negocio && (data?.tipo_negocio === "Fabrica" ? "Fábrica" : data?.tipo_negocio)}</p>
                                <ul className="list-group list-group-unbordered mb-3 p-1 text-sm">
                                    <li className="list-group-item">
                                        <i className="ion-ios-telephone"></i>
                                        <b> Teléfono</b> <span className="float-right" style={{ textDecoration: 'none' }}>{data?.telefono || '--'}</span>
                                    </li>
                                    <li className="list-group-item">
                                        <i className="ion-locked"></i>
                                        <b> Código</b> <span className="float-right" style={{ textDecoration: 'none' }}>{data?.codigo || '--'}</span>
                                    </li>
                                    {
                                        data?.estado === "Activo" ?
                                            <li className="list-group-item  text-success">
                                                <i className="ion-checkmark"></i>
                                                <b> Estado</b> <span className="float-right" style={{ textDecoration: 'none' }}>{data?.estado || '--'}</span>
                                            </li>
                                            :
                                            <li className="list-group-item">
                                                <i className="ion-alert-circled"></i>
                                                <b> Estado</b> <span className="float-right" style={{ textDecoration: 'none' }}>{data?.estado || '--'}</span>
                                            </li>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="card card-warning card-outline">
                            <div className="card-header">
                                Datos del cliente
                            </div>
                            <div className="card-body p-2 m-0">
                                {/* <small><strong> Codigo de cliente</strong></small>
                                    <p className="text-muted p-0 m-0"><small>{data?.codigo || '--'}</small></p> */}
                                <small><strong> Nombre</strong></small>
                                <p className="text-muted p-0 m-0"><small>{data?.nombres || '--'}</small></p>
                                <hr className='my-1' />
                                <small><strong> Apellido paterno</strong></small>
                                <p className="text-muted p-0 m-0"><small>{data?.apellido_paterno || '--'}</small></p>
                                <hr className='my-1' />
                                <small><strong> Apellido materno</strong></small>
                                <p className="text-muted p-0 m-0"><small>{data?.apellido_materno || '--'}</small></p>
                                <hr className='my-1' />
                                <small><strong> Carnet</strong></small>
                                <p className="text-muted p-0 m-0"><small>{data?.carnet || '--'}</small></p>
                                <hr className='my-1' />
                                <small><strong> Telefono</strong></small>
                                <p className="text-muted p-0 m-0"><small>{data?.telefono || '--'}</small></p>
                                <hr className='my-1' />
                                <small><strong> Zona</strong></small>
                                <p className="text-muted p-0 m-0"><small>{data?.zona || '--'}</small></p>
                                <hr className='my-1' />
                                <small><strong> Observaciones</strong></small>
                                <p className="text-muted p-0 m-0"><small>{data?.obs || '--'}</small></p>
                                <hr className='my-1' />
                                <small><strong> Tiempo de consumo por turril</strong></small>
                                <p className="text-muted p-0 m-0"><small>{data?.tiempo_de_consumo || '--'}</small></p>
                                <hr className='my-1' />
                                <small><strong> Vendedores encargados</strong></small>
                                <ul>
                                    {
                                        data?.vendedores?.map((ven, index) => (
                                            <p
                                                key={index}
                                                className="text-muted p-0 m-0"
                                            >
                                                <li>
                                                    <small>{ven.nombres}</small>
                                                </li>
                                            </p>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col col-md-6">
                        <div className="card card-info card-outline">
                            <div className="card-header">
                                <div className="d-flex">
                                    <div className="">Foto del negocio </div>
                                    <div className="ml-auto align-self-center">
                                        <i
                                            className='bi bi-image-fill btn p-0'
                                            // className='ion-ios-camera-outline btn py-0'
                                            style={{ fontSize: '17px' }}
                                            onClick={() => openModalImageCreate()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-2 m-0 d-flex justify-content-center">
                                {
                                    (data?.foto_negocio)
                                    &&
                                    <img src={data?.foto_negocio} className="img-fluid" alt="..." />
                                }
                                {
                                    (!data?.foto_negocio || data?.foto_negocio === "")
                                    &&
                                    <img src={'https://via.placeholder.com/450'} className="img-fluid" alt="..." />
                                }
                            </div>
                        </div>
                        {/* </div>
                                <div className="col-6"> */}
                        <div className="card card-success card-outline">
                            <div className="card-header">
                                Geolocalización
                            </div>
                            <div className="card-body p-2 m-0">
                                {
                                    !loading && data?.geolocalizacion ?
                                        <MapView
                                            center={data?.geolocalizacion}
                                            user={{ nombres: data.nombres, geolocalizacion: data.geolocalizacion }}
                                            scroll={false}
                                            zoom={12}
                                        />
                                        :
                                        <em><small>Ubicación no registrada...</small></em>
                                }
                            </div>
                        </div>
                        {/* </div>
                            </div> */}
                    </div>
                </div>
                {/* </div>
            </section> */}
                <ClientImageModalCreate
                    id={data?.id}
                    open={open}
                    openModalImageCreate={openModalImageCreate}
                />
            </div>
            :
            <div>Cliente inexistente!</div>
    )
}
// https://tanstack.com/table/v8/docs/examples/react/pagination?from=reactTableV7&original=https://react-table-v7.tanstack.com/docs/examples/pagination