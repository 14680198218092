import axios from "axios";
import { toastToggleAction } from "../redux/action/toastAction";

export function requestAuth(
  method,
  url,
  data
) {
  return axios({
    method,
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    url,
    data,
  })
}

export const RequestAuthPaginated = async (
  method,
  url,
  setFullData,
  nameData,
  dispatch,
  params
) => {
  setFullData(a => ({ ...a, loading: true }))

  try {
    const response = await axios({
      method,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      url,
      params
    });

    setFullData(a => ({ ...a, [nameData]: response.data.data }));
    setFullData(a => ({ ...a, params: { ...a.params, totalItems: response.data.totalItems, totalPages: response.data.totalPages, currentPage: response.data.currentPage, pageSize: response.data.pageSize } }));
    setFullData(a => ({ ...a, loading: false }))
    setFullData(a => ({ ...a, error: false }))
  } catch (error) {
    setFullData(a => ({ ...a, loading: false }))
    setFullData(a => ({ ...a, error: true }))
    dispatch(
      toastToggleAction({
        show: true,
        bg: 'danger',
        title: 'Error!',
        message: 'Error al listar los registros!',
        variant: 'bold',
      })
    );
  }
};

export const DefaultRequestAuth = async (
  method,
  url,
  setFullData,
  nameData,
  dispatch,
) => {
  setFullData(a => ({ ...a, loading: true }))

  try {
    const response = await axios({
      method,
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      url
    });

    setFullData(a => ({ ...a, data: response.data }));
    setFullData(a => ({ ...a, loading: false }))
    setFullData(a => ({ ...a, error: false }))
    return response
  } catch (error) {
    setFullData(a => ({ ...a, loading: false }))
    setFullData(a => ({ ...a, error: true }))
    dispatch(
      toastToggleAction({
        show: true,
        bg: 'danger',
        title: 'ERROR!',
        message: 'Error al buscar los datos!',
        variant: 'bold',
      })
    );
  }
};