import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Td } from '../../../components/table/Td'
import { Tr } from '../../../components/table/Tr'
import { GetClientsByUserId } from '../../../http/Services/ClientApi'
import { VerificationImageExist } from '../../../utils/VerificationImage'
import Loading from '../../globalParams/Loading'
import { ThParam } from '../../globalParams/ThParam'
import Avatar from '../../../assets/profile.png'

export default function ModalListClient({
    show,
    setShow,
    user
}) {
    const [data, setData] = useState([])
    const [stateData, setStateData] = useState("loading...")
    const handleClose = () => {
        setShow(false)
    }

    useEffect(() => {
        setData([])
        setStateData("loading...")
        const getClientsByUserId = async () => {
            try {
                let res = await GetClientsByUserId(user.id);
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        setStateData("")
                    }
                    else {
                        setStateData("sin registros...")
                    }
                    setData(res.data)
                }
            } catch (error) {
                setStateData("Error al cargar lista")
            }
        }
        if (show) {
            getClientsByUserId()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])


    return (
        <Modal show={show} onHide={handleClose} className='elevation-1' size='md' scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title className='h6 fw-bold text-secondary'> {data.length} clientes de {user.nombre} {user.apellido_paterno} {user.apellido_materno}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-1'>
                <div className="content-basic"
                >
                    <table
                        style={{
                            width: '100%',
                            border: '4px solid #495057',
                            fontWeight: 'bold'
                        }}
                    >
                        <thead
                            style={{
                                position: 'relative',
                                top: 0
                            }}
                        >
                            <Tr>
                                <ThParam
                                    label='Código'
                                    width='70px'
                                />
                                <ThParam
                                    label='Nombre'
                                    width='100px'
                                />
                            </Tr>
                        </thead>
                        <tbody>
                            {
                                stateData !== "" ?
                                    <tr>
                                        <td colSpan={5}>
                                            {stateData === "loading..."
                                                ?
                                                <Loading />
                                                :
                                                <div
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        color: 'rgba(0,0,0,.4)',
                                                        padding: '5px'
                                                    }}
                                                >
                                                    {stateData}
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    :
                                    data?.map((data) => (
                                        <Tr
                                            key={data.id}
                                        // onClick={() => setItem(data)}
                                        // mark={item && item.id === data.id && true}
                                        >
                                            <Td
                                                width='70px'
                                            >
                                                <div className="row g-0">
                                                    <div className="col-12 d-flex"
                                                        style={{
                                                            alignItems: 'center',
                                                            overflow: 'hidden',
                                                            fontSize: '14px'
                                                        }}
                                                    >
                                                        <img
                                                            className="p-0"
                                                            src={data && data.avatar
                                                                ?
                                                                require(`../../../assets/${VerificationImageExist(data.avatar)}`) : Avatar}
                                                            alt="img"
                                                            style={{
                                                                width: '30px',
                                                                height: '30px',
                                                                alignItems: 'center',
                                                                textAlign: 'center',
                                                                verticalAlign: 'middle'
                                                            }}
                                                        />
                                                        <span className='pl-1'>
                                                            {data.codigo ? data.codigo : '---- ----'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </Td>
                                            <Td
                                                width='130px'
                                            >
                                                <div
                                                    style={{
                                                        fontSize: '14px'
                                                    }}
                                                >
                                                    {data.nombre}{' '}
                                                    {data.apellido_paterno}{' '}
                                                    {data.apellido_materno}
                                                </div>
                                            </Td>
                                        </Tr>
                                    ))
                            }
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Salir
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
