import { RECALL1, RECALL2 } from "../type";

export const initialState = {
    recall1: false,
    recall2: false
}

export function globalParamsReducer(state = initialState, action) {
    switch (action.type) {
        case RECALL1:
            return { ...state, recall1: !state.recall1 };
        case RECALL2:
            return { ...state, recall2: !state.recall2 };
        default:
            return state;
    }
}