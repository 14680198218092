import { Formik, Form } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ButtonSubmitSave from '../../../components/button/ButtonSubmitSave'
// import InputBeforeIcon from '../../../components/form/InputBeforeIcon'
import Label from '../../../components/label/Label'
import { GetProductsApi } from '../../../http/Services/ProductApi'
import { CreateWareHouseApi } from '../../../http/Services/WareHouseApi'
import { toastToggleAction } from '../../../redux/action/toastAction'

export default function ModalCreateWareHouse({ show, handleClose, setLoad, load }) {
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const dispatch = useDispatch()
    const [products, setProducts] = useState([])

    useEffect(() => {
        const getProducts = async () => {
            let res = await GetProductsApi();
            if (res.status === 200) {
                setProducts(res.data)
            }
            else {
                console.log('no hay productos')
            }
        }
        if (show) {
            getProducts()
        }
    }, [show])

    return (
        <>
            <Modal show={show} onHide={handleClose} className='elevation-1' size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h6 fw-bold text-secondary'>Registrar stock</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        producto_id: '',
                        estado: '',
                        cantidad_actual: 0
                    }}

                    onSubmit={(valores, { resetForm }) => {
                        setButtonDisabled(true)

                        const createHistoryNote = async () => {
                            try {
                                let res = await CreateWareHouseApi(valores)
                                if (res.status === 200) {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'success',
                                        title: 'Exito!',
                                        message: 'Creado correctamente!',
                                        variant: 'bold'
                                    }))
                                    resetForm()
                                    setLoad(!load)
                                    handleClose()
                                    setButtonDisabled(false)
                                }
                                else {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'warning',
                                        title: 'Alerta!',
                                        message: 'Los datos podrian no haberse cargado!',
                                        variant: 'bold'
                                    }))
                                }
                            } catch (error) {
                                // console.log(error)
                                if (error.response && error.response.data) {
                                    console.log(error.response.data)
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'warning',
                                        title: 'Alerta!',
                                        message: error.response.data,
                                        variant: 'bold'
                                    }))
                                }
                                else {
                                    // console.log(error.response.response.data)
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'danger',
                                        title: 'Error al registrar movimiento!',
                                        message: 'Llene los datos correctamente!',
                                        variant: 'bold'
                                    }))
                                }
                                setButtonDisabled(false)
                            }
                        }

                        createHistoryNote();
                    }}
                >
                    {({ values, handleChange, handleBlur }) => (
                        <React.Fragment>
                            <Form>
                                <Modal.Body>
                                    <div className="row">
                                        <div className="col-12 mb-2">
                                            <Label
                                                title='Producto'
                                            >
                                                <select
                                                    className="form-select"
                                                    name="producto_id"
                                                    value={values.producto_id}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    // style={{ boxShadow: 'none' }}
                                                    disabled={buttonDisabled}
                                                    required
                                                >
                                                    <option value='' label='...' required />
                                                    {
                                                        products.map(product => (
                                                            <option value={product.id} label={product.nombre + ', Bs. ' + product.precio + ', ' + product.descripcion} key={product.id} />

                                                        ))
                                                    }
                                                </select>
                                            </Label>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <Label
                                                title='Estado'
                                            >
                                                <select
                                                    className="form-select"
                                                    name="estado"
                                                    value={values.estado}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    // style={{ boxShadow: 'none' }}
                                                    disabled={buttonDisabled}
                                                >
                                                    <option value='' label={'...'} />
                                                    <option value='TCC' label={'TCC'} />
                                                    <option value='TV' label={'TV'} />
                                                    <option value='NUEVO' label={'NUEVO'} />
                                                    <option value='RANCIO' label={'RANCIO'} />
                                                    <option value='PAQUETE' label={'PAQUETE'} />
                                                    <option value='ROTO' label={'ROTO'} />
                                                    <option value='MARCADO' label={'MARCADO'} />
                                                    <option value='CLISADO' label={'CLISADO'} />
                                                </select>
                                            </Label>
                                        </div>
                                        {/* <div className="col-6 mb-2">
                                            <Label
                                                title='Estado'
                                            >
                                                <InputBeforeIcon
                                                    id='estado'
                                                    placeholder='Estado'
                                                    name='estado'
                                                    type='text'
                                                    onChange={handleChange}
                                                    value={values.estado}
                                                    disabled={buttonDisabled}
                                                    required={true}
                                                />
                                            </Label>
                                        </div> */}
                                        {/* <div className="col-6 mb-2">
                                            <Label
                                                title='Cantidad actual'
                                            >
                                                <InputBeforeIcon
                                                    id='cantidad_actual'
                                                    placeholder='Cantidad actual'
                                                    name='cantidad_actual'
                                                    type='number'
                                                    onChange={handleChange}
                                                    value={values.cantidad_actual}
                                                    disabled={buttonDisabled}
                                                    required={true}
                                                    min={1}
                                                />
                                            </Label>
                                        </div> */}
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancelar
                                    </Button>
                                    <ButtonSubmitSave
                                        text='Crear stock'
                                        // color='#28ceb8'
                                        disabled={buttonDisabled}
                                    />
                                </Modal.Footer>
                            </Form>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>
        </>
    )
}
