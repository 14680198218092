import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ButtonIcon from '../../../../components/button/ButtonIcon'
import { Table } from '../../../../components/table/Table'
import { Td } from '../../../../components/table/Td'
import { Tr } from '../../../../components/table/Tr'
import { GetMyNotesApi } from '../../../../http/Services/NoteApi'
import { GetGlobalDataApi } from '../../../../http/Services/UserApi'
import { getGlobalDataAction } from '../../../../redux/action/globalDataAction'
import { DateConvert } from '../../../../utils/dateConvert'
import Detail from '../../../globalParams/Detail'
import Info from '../../../globalParams/filter/Info'
import Paginator from '../../../globalParams/filter/Paginator'
import Searcher from '../../../globalParams/filter/Searcher'
import GeneralContainer from '../../../globalParams/GeneralContainer'
import { onChangeInputData, order, orderNum } from '../../../globalParams/GlobalDataFilter'
import Loading from '../../../globalParams/Loading'
import { ThParam } from '../../../globalParams/ThParam'

export default function Notes() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [stateData, setStateData] = useState("loading...")
    const [load, setLoad] = useState(false)
    const [data, setData] = useState([])
    const [back, setBack] = useState([])
    const [items, setItems] = useState([])
    const [item, setItem] = useState({})
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [total, setTotal] = useState(0)
    const [desc, setDesc] = useState(true)
    const [byQuery, setByQuery] = useState("")
    const [full, setFull] = useState(true)

    useEffect(() => {
        setStateData("loading...")
        setItem({})
        const getFullData = async () => {
            try {
                let res = await GetMyNotesApi();
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        setStateData("")
                        setByQuery("")
                    }
                    else {
                        setStateData("sin registros...")
                    }
                    setDesc(false)
                    setCurrentPage(1)
                    setData(res.data)

                    getGlobalData()
                }
            } catch (error) {
                setStateData("Error al cargar lista")
            }

        }
        getFullData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load])


    useEffect(() => {
        setTotal(back.length)
        let div = back.length / perPage
        if (div - parseInt(div) === 0) {
            setTotalPages(div)
        }
        else {
            setTotalPages(parseInt(div) + 1)
        }
    }, [back, perPage])

    useEffect(() => {
        setCurrentPage(1)
    }, [perPage])


    useEffect(() => {
        // no replicar a = b por que se toma como si fuera el mismo
        const dataBackup = Array.from(data)
        setItems([
            ...dataBackup
                .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery))
                .splice((currentPage * perPage - perPage), perPage)
        ])
        setBack([
            ...data
                .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery))
        ])

        let div = back.length / perPage
        if (div - parseInt(div) === 0) {
            setTotalPages(div)
        }
        else {
            setTotalPages(parseInt(div) + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, currentPage, perPage])

    const getGlobalData = async () => {
        try {
            let res = await GetGlobalDataApi();
            if (res.status === 200) {
                dispatch(getGlobalDataAction(res.data))
            }
        } catch (error) {
            console.log('error in reducers')
        }
    }

    const onChangeInput = (e) => {
        onChangeInputData(
            setByQuery,
            e,
            data,
            currentPage,
            setCurrentPage,
            setItems,
            perPage,
            setBack,
            setStateData
        )
    }

    const seeMore = (id) => {
        navigate("" + id)
    }

    return (
        <GeneralContainer>
            <div className="row gx-3">
                {/* <div className="col-12 mb-3">
                    <div className='row gx-1 p-1 d-flex justify-content-end'>
                        <div className="col-6 col-sm-3 col-md-2">
                            <ButtonTextIcon
                                icon={<i className="ion-person-add"></i>}
                                variant="danger"
                                title="Registrar"
                                onClick={() => handleShow()}
                            />
                        </div>
                    </div>
                </div> */}
                <div className={`col-12 col-md-${full ? '12' : '6'}`}>
                    {
                        <>
                            <div className="card card-danger card-outline">
                                <div className="p-1">
                                    <Searcher
                                        onChangeInput={onChangeInput}
                                        byQuery={byQuery}
                                    />
                                </div>
                                <div className="p-1">
                                    <Paginator
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        totalPages={totalPages}
                                        setPerPage={setPerPage}
                                        perPage={perPage}
                                        setLoad={setLoad}
                                        load={load}
                                        stateData={stateData}
                                        setFull={setFull}
                                        full={full}
                                    />
                                </div>
                                <div className=" p-1 table-warning rounded-top">
                                    <Info
                                        total={total}
                                        currentPage={currentPage}
                                        perPage={perPage}
                                        totalPages={totalPages}
                                    />
                                </div>
                                <div className="card-body p-1">
                                    <div className="content-basic">
                                        <Table>
                                            <thead>
                                                <Tr>
                                                    {/* <ThParam
                                    label='Id'
                                    onClick={() => orderNum('id')}
                                /> */}
                                                    <ThParam
                                                        label='Código'
                                                        onClick={() => orderNum('id', data, desc, setData, setDesc)}
                                                        width='100px'
                                                    />
                                                    <ThParam
                                                        label='Encargado'
                                                        onClick={() => order('u_nombre', data, desc, setData, setDesc)}
                                                        width='130px'
                                                    />
                                                    <ThParam
                                                        label='Departamento'
                                                        onClick={() => order('u_dpto', data, desc, setData, setDesc)}
                                                        width='120px'
                                                    />
                                                    <ThParam
                                                        label='Estado'
                                                        onClick={() => order('estado', data, desc, setData, setDesc)}
                                                        width='75px'
                                                    />
                                                    <ThParam
                                                        label='Revisado'
                                                        onClick={() => order('revisado', data, desc, setData, setDesc)}
                                                        width='85px'
                                                    />
                                                    <ThParam
                                                        label='Fecha de registro'
                                                        onClick={() => order('created_at', data, desc, setData, setDesc)}
                                                        width='170px'
                                                    />
                                                    <ThParam
                                                        label='Acciones'
                                                        // onClick={() => order('estado', data, desc, setData, setDesc)}
                                                        width='75px'
                                                    />
                                                </Tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    stateData !== "" ?
                                                        <tr>
                                                            <td colSpan={5}>
                                                                {stateData === "loading..."
                                                                    ?
                                                                    <Loading />
                                                                    :
                                                                    <div
                                                                        style={{
                                                                            fontSize: '14px',
                                                                            fontWeight: 'bold',
                                                                            color: 'rgba(0,0,0,.4)',
                                                                            padding: '5px'
                                                                        }}
                                                                    >
                                                                        {stateData}
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                        :
                                                        items?.map((data) => (
                                                            <Tr
                                                                key={data.id}
                                                                onClick={() => setItem(data)}
                                                                mark={item && item.id === data.id && true}
                                                            >
                                                                <Td
                                                                    width='100px'
                                                                >
                                                                    <div className="row">
                                                                        <div className="col d-flex"
                                                                            style={{
                                                                                alignItems: 'center'
                                                                            }}
                                                                        >
                                                                            <i className="bi bi-journal-text d-flex text-danger p-1"
                                                                                style={{
                                                                                    fontSize: '25px'
                                                                                }}
                                                                            />
                                                                            <span className='text-danger fw-bold'>
                                                                                {data.codigo}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Td>
                                                                <Td
                                                                    width='130px'
                                                                >
                                                                    {data.u_nombre}{' '}
                                                                    {data.apellido_paterno}{' '}
                                                                    {data.apellido_materno}
                                                                </Td>
                                                                <Td
                                                                    width='120px'
                                                                    center={true}
                                                                >
                                                                    <div className='badge-in-primary'>
                                                                        {data.u_dpto}
                                                                    </div>
                                                                </Td>
                                                                <Td
                                                                    width='75px'
                                                                >
                                                                    <div
                                                                        className='btn btn-link text-decoration-none p-0'
                                                                    >
                                                                        <small><b><i className={`text-${data.estado ? (data.estado === 'abierto' ? 'danger' : 'success') : 'secondary'}`}>{data.estado}</i></b></small>
                                                                    </div>
                                                                </Td>
                                                                <Td
                                                                    width='85px'
                                                                    center={true}
                                                                >
                                                                    {
                                                                        data.revisado === "si" ?
                                                                            <div className='badge-in-success'>
                                                                                {data.revisado}
                                                                            </div>
                                                                            :
                                                                            <div className='badge-in-danger'>
                                                                                no
                                                                            </div>
                                                                    }
                                                                </Td>
                                                                <Td
                                                                    width='170px'
                                                                >
                                                                    {data.created_at ? DateConvert(data.created_at) : ''}
                                                                </Td>
                                                                <Td
                                                                    width='75px'
                                                                    center={true}
                                                                >
                                                                    <ButtonIcon
                                                                        icon={"bi bi-eye-fill"}
                                                                        variant="info"
                                                                        title="Ver más"
                                                                        onClick={() => seeMore(data.id)}
                                                                        color='text-white'
                                                                    />
                                                                </Td>
                                                            </Tr>
                                                        ))
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                {
                                    total > perPage && stateData !== "loading..." &&
                                    <>
                                        <div className=" p-1 table-warning rounded-top">
                                            <Info
                                                total={total}
                                                currentPage={currentPage}
                                                perPage={perPage}
                                                totalPages={totalPages}
                                            />
                                        </div>
                                        <div className="p-1">
                                            <Paginator
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                totalPages={totalPages}
                                                setPerPage={setPerPage}
                                                perPage={perPage}
                                                setLoad={setLoad}
                                                load={load}
                                                stateData={stateData}
                                                setFull={setFull}
                                                full={full}
                                                hiddenpaginator={true}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
                {
                    item.id &&
                    <div className={`col-12 col-md-${full ? '12' : '6'}`}>
                        <div className="card">
                            <div className='card-header p-1'>
                                <div className='row'>
                                    <div className='col-8'>
                                        Detalles del cuaderno
                                    </div>
                                    <div className="col-4 d-flex justify-content-end">
                                        <div className='p-0'>
                                            <i className='bi bi-x-lg btn py-0 px-1' onClick={() => setItem({})} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                {/* <div className="d-flex justify-content-end p-2">

                                    <button
                                        className='bi bi-eye-fill btn btn-info text-white ml-2 elevation-2'
                                        onClick={() => seeMore(item.id)}
                                    // disabled={user && (user.departament.nombre === "Administración" || item.estado !== "Activo") ? false : true}
                                    />
                                </div> */}
                                <Detail
                                    detail2={[
                                    ]}
                                    detail={[
                                        { label: 'Número', value: item.codigo },
                                        { label: 'Encargado', value: item.u_nombre + ' ' + (item.apellido_paterno && item.apellido_paterno) + ' ' + (item.apellido_materno ? item.apellido_materno : "") },
                                        { label: 'Departamento', value: item.u_dpto },
                                        { label: 'Estado', value: item.estado ? item.estado : '', color: item.estado === 'cerrado' ? 'table-success' : (item.estado === 'abierto' ? 'table-danger' : 'table-secondary') },
                                        { label: 'Revisado', value: item.revisado === "si" ? item.revisado : 'no', color: item.revisado === 'si' ? 'table-success' : 'table-danger' },
                                        { label: 'Fecha de apertura', value: DateConvert(item.created_at) },
                                        { label: 'Total venta', value: item.total_venta + ' Bs.' },
                                        { label: 'Total deuda', value: item.total_deuda + ' Bs.' },
                                        { label: 'Total pagos por confirmar', value: item.total_pagos_confirmar + ' Bs.' },
                                        { label: 'Total cuarderno', value: item.total_cuaderno + ' Bs.' },
                                        { label: 'Total gasto', value: item.total_gasto + ' Bs.' },
                                        { label: 'Total garantia', value: item.total_garantia + ' Bs.' },
                                        { label: 'Total cobranza', value: item.total_cobranza + ' Bs.' },
                                        { label: 'Turriles recogidos', value: item.total_turril_recogido },
                                    ]}
                                    id={item.id}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </GeneralContainer>
    )
}