import { Formik, Form } from 'formik'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ButtonSubmitSave from '../../../../components/button/ButtonSubmitSave'
import InputTextTarea from '../../../../components/textField/InputTextTarea'
import { CreateExpenseNoteApi } from '../../../../http/Services/ExpenseNoteApi'
import { toastToggleAction } from '../../../../redux/action/toastAction'
import InputBeforeIcon from '../../../../components/form/InputBeforeIcon'
import Label from '../../../../components/label/Label'

export default function ModalCreateExpenseNote({
    show,
    handleClose,
    recallAction,
    cuadernoId
}) {
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const dispatch = useDispatch()

    return (
        <>
            <Modal show={show} onHide={handleClose} className='elevation-1' size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h6 fw-bold text-secondary'>Anotar gasto</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        cuaderno_id: cuadernoId,
                        monto: 0,
                        tipo: '',
                        descripcion: ''
                    }}

                    onSubmit={(valores, { resetForm }) => {
                        setButtonDisabled(true)
                        const CreateExpenseNote = async () => {
                            try {

                                let res = await CreateExpenseNoteApi(valores)
                                if (res.status === 200) {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'success',
                                        title: 'Exito!',
                                        message: 'Creado correctamente!',
                                        variant: 'bold'
                                    }))
                                    resetForm()
                                    recallAction()
                                    handleClose()
                                    setButtonDisabled(false)
                                }
                                else {
                                    setButtonDisabled(false)
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'warning',
                                        title: 'Alerta!',
                                        message: 'Los datos podrian no haberse cargado!',
                                        variant: 'bold'
                                    }))
                                    // setButtonDisabled(false)
                                }
                            } catch (error) {
                                setButtonDisabled(false)
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'danger',
                                    title: 'Error al registrar pago!',
                                    message: 'Llene los datos correctamente!',
                                    variant: 'bold'
                                }))
                                // setButtonDisabled(false)
                            }
                        }

                        CreateExpenseNote();
                    }}
                >
                    {({ values, handleChange }) => (
                        <React.Fragment>
                            <Form>
                                <Modal.Body>
                                    <div className="row  d-flex justify-content-center">
                                        <div className="col-12 col-lg-6 mb-2">
                                            <Label
                                                title='Monto'
                                            >
                                                <InputBeforeIcon
                                                    id='monto'
                                                    placeholder="Monto"
                                                    type='number'
                                                    onChange={handleChange}
                                                    name='monto'
                                                    value={values.monto}
                                                    disabled={buttonDisabled}
                                                    param='Bs.'
                                                    min={1}
                                                    step={0.1}
                                                />
                                            </Label>
                                        </div>
                                        <div className="col-12 col-md-6 mb-2">
                                            <Label
                                                title='Tipo de gasto'
                                            >
                                                <select
                                                    className="form-select fw-bold text-secondary"
                                                    name="tipo"
                                                    value={values.tipo}
                                                    onChange={handleChange}
                                                    // style={{ boxShadow: 'none' }}
                                                    disabled={buttonDisabled}
                                                    required
                                                >
                                                    <option value='' label='...' disabled />
                                                    {
                                                        window.sessionStorage.getItem('dp') === 'pvnt' ?
                                                            <>
                                                                <option value='Luz' label='Luz' />
                                                                <option value='Agua' label='Agua' />
                                                                <option value='Internet' label='Internet' />
                                                                <option value='Credito de celular' label='Credito de celular' />
                                                                <option value='Alquiler' label='Alquiler' />
                                                                <option value='Compra de bolsas' label='Compra de bolsas' />
                                                                <option value='Envase para agranel' label='Envase para agranel' />
                                                                <option value='Pago de taxis' label='Pago de taxis' />
                                                                <option value='Pago de seguridad' label='Pago de seguridad' />
                                                                <option value='Gastos varios' label='Gastos varios' />
                                                            </>
                                                            :
                                                            <>
                                                                <option value='Diesel' label='Diesel' />
                                                                <option value='Peaje' label='Peaje' />
                                                                <option value='Compra de llanta' label='Compra de llanta' />
                                                                <option value='Parchado de llanta y aire' label='Parchado de llanta y aire' />
                                                                <option value='Compra de aceite para vehiculo' label='Compra de aceite para vehiculo' />
                                                                <option value='Compra de filtro' label='Compra de filtro' />
                                                                <option value='Compra de carpa' label='Compra de carpa' />
                                                                <option value='Lavado' label='Lavado' />
                                                                <option value='Transito' label='Transito' />
                                                                {/* <option value='GPS' label='GPS' /> */}
                                                                <option value='Mecanico Distrib (c.v.)' label='Mecanico Distrib (c.v.)' />
                                                                <option value='Asistencia mecanica' label='Asistencia mecanica' />
                                                                <option value='Insumo para vehiculo' label='Insumo para vehiculo' />
                                                                <option value='Gastos varios' label='Gastos varios' />
                                                            </>
                                                    }
                                                </select>
                                            </Label>
                                        </div>
                                        <div className="col-md-12">
                                            <Label
                                                title='Descripción'
                                            >
                                                <InputTextTarea
                                                    rows={2}
                                                    onChange={handleChange}
                                                    placeholder='Descripción...'
                                                    value={values.descripcion}
                                                    name='descripcion'
                                                    disabled={buttonDisabled}
                                                    // required={true}
                                                />
                                            </Label>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancelar
                                    </Button>
                                    <ButtonSubmitSave
                                        text='Anotar gasto'
                                        color='#00bfa5'
                                        disabled={buttonDisabled}
                                    />
                                </Modal.Footer>
                            </Form>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>
        </>
    )
}
