import React from 'react'

export default function Label({ title, bold, children }) {
    return (
        <label
            style={{ display: "block", fontSize: '13px', fontWeight: bold ? bold : 'normal' }}
            className='mb-0'
        >
            <p className='mb-1 fw-bold text-secondary'>{title}</p>
            {children}
        </label>
    )
}
