import React from 'react'

export default function ButtonLinkIcon({
    title,
    onClick,
    iconB,
    bg,
    // color,
    // bold,
    btn,
    end
}) {
    return (
        <>
            <div
                className={`col-12 d-flex justify-content-${end ? 'end' : 'start'}`}
            >
                <div
                    onClick={onClick}
                >
                    <span
                        style={{
                            fontSize: '15px',
                            cursor: btn ? 'pointer' : '',
                        }}
                        className={`text-${bg}`}
                    >
                        {iconB}
                    </span>
                </div>
                <div
                    style={{
                        fontSize: '14px',
                        fontWeight: '600',
                        marginTop: '2px',
                        cursor: btn ? 'pointer' : '',
                        marginLeft: '3px'
                    }}
                    variant='link'
                    // className={`text-${color}`}
                    onClick={onClick}
                >
                    <em>{title}</em>
                </div>
            </div >
        </>
    )
}
