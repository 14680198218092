import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Table } from '../../../../components/table/Table'
import { Td } from '../../../../components/table/Td'
import { Tr } from '../../../../components/table/Tr'
import { GetGlobalDataApi } from '../../../../http/Services/UserApi'
import { getGlobalDataAction } from '../../../../redux/action/globalDataAction'
import { DateConvert } from '../../../../utils/dateConvert'
import Detail from '../../../globalParams/Detail'
import Info from '../../../globalParams/filter/Info'
import Paginator from '../../../globalParams/filter/Paginator'
import Searcher from '../../../globalParams/filter/Searcher'
import GeneralContainer from '../../../globalParams/GeneralContainer'
import { onChangeInputData, order, orderNum } from '../../../globalParams/GlobalDataFilter'
import Loading from '../../../globalParams/Loading'
import { ThParam } from '../../../globalParams/ThParam'
import { DeleteOrderApi, GetMyOrdersApi } from '../../../../http/Services/OrderApi'
import ButtonTextIcon from '../../../../components/button/ButtonTextIcon'
import ModalCreateOrder from './ModalCreateOrder'
import ModalConfirmation from '../../../../components/modal/ModalConfirmation'
import { toastToggleAction } from '../../../../redux/action/toastAction'
import ButtonIcon from '../../../../components/button/ButtonIcon'

export default function MyOrders() {

    const dispatch = useDispatch()

    const [stateData, setStateData] = useState("loading...")
    const [load, setLoad] = useState(false)
    const [data, setData] = useState([])
    const [back, setBack] = useState([])
    const [items, setItems] = useState([])
    const [item, setItem] = useState({})
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [total, setTotal] = useState(0)
    const [desc, setDesc] = useState(true)
    const [byQuery, setByQuery] = useState("")
    const [full, setFull] = useState(true)
    const [toggleModalCreate, setToggleModalCreate] = useState(false)
    const [modalConfirmation, setModalConfirmation] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)


    useEffect(() => {
        setStateData("loading...")
        setItem({})
        const getOrders = async () => {
            try {
                let res = await GetMyOrdersApi();
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        setStateData("")
                        setByQuery("")
                    }
                    else {
                        setStateData("sin registros...")
                    }
                    setDesc(false)
                    setCurrentPage(1)
                    setData(res.data)

                    getGlobalData()
                }
            } catch (error) {
                setStateData("Error al cargar lista")
            }

        }
        getOrders()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load])


    useEffect(() => {
        setTotal(back.length)
        let div = back.length / perPage
        if (div - parseInt(div) === 0) {
            setTotalPages(div)
        }
        else {
            setTotalPages(parseInt(div) + 1)
        }
    }, [back, perPage])

    useEffect(() => {
        setCurrentPage(1)
    }, [perPage])


    useEffect(() => {
        // no replicar a = b por que se toma como si fuera el mismo
        const dataBackup = Array.from(data)
        setItems([
            ...dataBackup
                // .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery) || a.telefono.toLowerCase().includes(byQuery))
                .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery))
                .splice((currentPage * perPage - perPage), perPage)
        ])
        setBack([
            ...data
                // .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery) || a.telefono.toLowerCase().includes(byQuery))
                .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery))
        ])

        let div = back.length / perPage
        if (div - parseInt(div) === 0) {
            setTotalPages(div)
        }
        else {
            setTotalPages(parseInt(div) + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, currentPage, perPage])

    const getGlobalData = async () => {
        try {
            let res = await GetGlobalDataApi();
            if (res.status === 200) {
                dispatch(getGlobalDataAction(res.data))
            }
        } catch (error) {
            console.log('error in reducers')
        }
    }

    const onChangeInput = (e) => {
        onChangeInputData(
            setByQuery,
            e,
            data,
            currentPage,
            setCurrentPage,
            setItems,
            perPage,
            setBack,
            setStateData
        )
    }

    const handleShow = () => {
        setToggleModalCreate(true)
    }
    const handleClose = () => {
        setToggleModalCreate(false)
    }
    const handleCloseConfirmation = () => setModalConfirmation(false)

    const handleShowConfirmation = () => {
        setModalConfirmation(true);
    }

    const deleteData = async () => {
        try {
            setButtonDisabled(true)
            let res = await DeleteOrderApi(item.id)
            if (res.status === 200) {
                handleCloseConfirmation()
                setLoad(!load)

                dispatch(toastToggleAction({
                    show: true,
                    bg: 'success',
                    title: 'Exito!',
                    message: 'Eliminado correctamente!',
                    variant: 'bold'
                }))
                setButtonDisabled(false)
            }
        } catch (error) {
            setButtonDisabled(false)
            // console.log(error.response)
            dispatch(toastToggleAction({
                show: true,
                bg: 'danger',
                title: 'Error!',
                message: error.response.data.message ? error.response.data.message : 'No se puede eliminar el cliente!',
                variant: 'bold'
            }))
        }
    }

    return (
        <GeneralContainer>
            <div className="row gx-3">
                <div className="col-12 mb-3">
                    <div className='row gx-1 p-1 d-flex justify-content-end'>
                        <div className="col-6 col-sm-3 col-md-2">
                            <ButtonTextIcon
                                icon={<i className="ion-android-cart"></i>}
                                variant="danger"
                                title="Registrar"
                                onClick={() => handleShow()}
                            />
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-${full ? '12' : '6'}`}>
                    {
                        <>
                            <div className="card card-danger card-outline">
                                <div className="p-1">
                                    <Searcher
                                        onChangeInput={onChangeInput}
                                        byQuery={byQuery}
                                    />
                                </div>
                                <div className="p-1">
                                    <Paginator
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        totalPages={totalPages}
                                        setPerPage={setPerPage}
                                        perPage={perPage}
                                        setLoad={setLoad}
                                        load={load}
                                        stateData={stateData}
                                        setFull={setFull}
                                        full={full}
                                    />
                                </div>
                                <div className=" p-1 table-warning rounded-top">
                                    <Info
                                        total={total}
                                        currentPage={currentPage}
                                        perPage={perPage}
                                        totalPages={totalPages}
                                    />
                                </div>
                                <div className="card-body p-1">
                                    <div className="content-basic">
                                        <Table>
                                            <thead>
                                                <Tr>
                                                    {/* <ThParam
                                    label='Id'
                                    onClick={() => orderNum('id')}
                                /> */}
                                                    <ThParam
                                                        label='Producto'
                                                        onClick={() => order('producto', data, desc, setData, setDesc)}
                                                        width='120px'
                                                    />
                                                    <ThParam
                                                        label='Cantidad'
                                                        onClick={() => orderNum('cantidad', data, desc, setData, setDesc)}
                                                        width='90px'
                                                    />
                                                    <ThParam
                                                        label='Total'
                                                        onClick={() => orderNum('total_precio', data, desc, setData, setDesc)}
                                                        width='90px'
                                                    />
                                                    <ThParam
                                                        label='Estado'
                                                        onClick={() => order('estado', data, desc, setData, setDesc)}
                                                        width='85px'
                                                    />
                                                    <ThParam
                                                        label='Fecha de registro'
                                                        onClick={() => orderNum('id', data, desc, setData, setDesc)}
                                                        width='175px'
                                                    />
                                                    <ThParam
                                                        label='Acciones'
                                                        // onClick={() => order('estado', data, desc, setData, setDesc)}
                                                        width='75px'
                                                    />
                                                </Tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    stateData !== "" ?
                                                        <tr>
                                                            <td colSpan={5}>
                                                                {stateData === "loading..."
                                                                    ?
                                                                    <Loading />
                                                                    :
                                                                    <div
                                                                        style={{
                                                                            fontSize: '14px',
                                                                            fontWeight: 'bold',
                                                                            color: 'rgba(0,0,0,.4)',
                                                                            padding: '5px'
                                                                        }}
                                                                    >
                                                                        {stateData}
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                        :
                                                        items?.map((data) => (
                                                            <Tr
                                                                key={data.id}
                                                                onClick={() => setItem(data)}
                                                                mark={item && item.id === data.id && true}
                                                            >
                                                                <Td
                                                                    width='120px'
                                                                >
                                                                    {data.producto}
                                                                </Td>
                                                                <Td
                                                                    width='90px'
                                                                >
                                                                    {data.cantidad}
                                                                </Td>
                                                                <Td
                                                                    width='90px'
                                                                >
                                                                    {data.total_precio} Bs
                                                                </Td>
                                                                <Td
                                                                    width='85px'
                                                                >
                                                                    {
                                                                        // user && user.departament.nombre === "Administración" &&
                                                                        <div
                                                                            className='btn btn-link text-decoration-none p-0'
                                                                        >
                                                                            <small><b><i className={`text-${data.estado ? (data.estado === 'pendiente' ? 'danger' : (data.estado === 'entregado' ? 'success' : 'secondary')) : 'secondary'}`}>{data.estado}</i></b></small>
                                                                        </div>
                                                                    }
                                                                </Td>
                                                                <Td
                                                                    width='175px'
                                                                >
                                                                    {data.created_at ? DateConvert(data.created_at) : ''}
                                                                </Td>
                                                                <Td
                                                                    width='75px'
                                                                    center={true}
                                                                >
                                                                    <ButtonIcon
                                                                        icon={"bi bi-trash-fill"}
                                                                        variant="danger"
                                                                        title="eliminar"
                                                                        onClick={() => handleShowConfirmation()}
                                                                        disabled={data.estado !== "pendiente" ? true : false}
                                                                    />

                                                                </Td>
                                                            </Tr>
                                                        ))
                                                }
                                            </tbody >
                                        </Table >
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
                {
                    item.id &&
                    <div className={`col-12 col-md-${full ? '12' : '6'}`}>
                        <div className="card">
                            <div className='card-header p-1'>
                                <div className='row'>
                                    <div className='col-8'>
                                        Detalles del pedido
                                    </div>
                                    <div className="col-4 d-flex justify-content-end">
                                        <div className='p-0'>
                                            <i className='bi bi-x-lg btn py-0 px-1' onClick={() => setItem({})} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="d-flex justify-content-end p-2">
                                    <button
                                        className='bi bi-trash-fill btn btn-danger ml-2 elevation-2'
                                        onClick={() => handleShowConfirmation()}
                                        disabled={item.estado !== "pendiente" ? true : false}
                                    />
                                </div>
                                <Detail
                                    detail={[
                                        // { label: 'Vendedor', value: item.nombre + ' ' + (item.apellido_paterno ? item.apellido_paterno : '') + ' ' + (item.apellido_materno ? item.apellido_materno : '') },
                                        { label: 'Producto', value: item.producto },
                                        { label: 'Descripción', value: item.descripcion },
                                        { label: 'Precio por unidad', value: item.precio > 0 ? item.precio + ' Bs.' : '' },
                                        { label: 'Cantidad', value: item.cantidad },
                                        { label: 'Precio total', value: item.total_precio > 0 ? item.total_precio + ' Bs.' : '' },
                                        { label: 'Estado del pedido', value: item.estado ? item.estado : '--', color: item.estado === "entregado" ? 'table-success' : (item.estado === "pendiente" ? 'table-danger' : 'table-secondary') },
                                        { label: 'Fecha de registro', value: DateConvert(item.created_at) }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <ModalCreateOrder
                show={toggleModalCreate}
                handleClose={handleClose}
                load={load}
                setLoad={setLoad}
            />
            <ModalConfirmation
                show={modalConfirmation}
                handleClose={handleCloseConfirmation}
                text='¿Seguro que desea eliminar este pedido?'
                buttonText='Eliminar'
                variant='danger'
                onClick={() => deleteData()}
                buttonDisabled={buttonDisabled}
            />
        </GeneralContainer>
    )
}