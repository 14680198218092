import { Field, Form, Formik } from 'formik'
import React from 'react'

export default function Searcher({ data, setData }) {
    const initialValues = {
        filterBy: data?.filters[0] || '',
        filterParam: data?.params[data?.filters[0]] || '',
    };
    const onSubmit = (values, { setSubmitting }) => {
        // setData((prevData) => ({
        //     ...prevData,
        //     params: {
        //         ...prevData.params,
        //         [values['filterBy']]: values['filterParam'],
        //     },
        // }));
        setData((prevData) => ({
            ...prevData,
            params: {
                ...prevData.params,
                filterBy: values['filterBy'],
                filterParam: values['filterParam'],
            },
        }));
        setSubmitting(false);
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form className="col-12 col-sm-10 col-md-8 d-flex justify-content-end gap-1 p-0 mt-1">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <Field
                                className="btn btn-outline-secondary dropdown-toggle"
                                as='select'
                                name='filterBy'
                                style={{ fontSize: '13px', fontWeight: 'bold' }}
                            >
                                {
                                    data?.filters?.map((opt, index) => (
                                        <option
                                            key={index}
                                            className="dropdown-item"
                                            value={opt}
                                        >
                                            {opt}
                                        </option>
                                    ))
                                }
                            </Field>
                        </div>
                        <Field type="text" name='filterParam' className="form-control border-2" style={{ fontSize: '15px', fontWeight: '600' }} />
                        <button type="submit" className="btn btn-primary text-white ion-search" disabled={isSubmitting} />
                    </div>
                    {/* </div> */}
                </Form>
            )}
        </Formik>
    )
}
