import axios from "axios";

export async function createHistoryAccountApi(data) {
    const response = axios({
        url: `/historyAccount`,
        method: 'post',
        data: data,
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}