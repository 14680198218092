import React, { useEffect, useState } from 'react'
import GeneralContainer from '../../globalParams/GeneralContainer'
import { RequestAuthPaginated } from '../../../http/httpRequest'
import { DefaultTable } from '../../../components/table/DefaultTable'
import { useDispatch, useSelector } from 'react-redux'
// import { Routes } from 'react-router-dom'
// import { Route } from 'react-router-dom'
// import { ClienteTab } from './ClienteTab'
// import { ModalForm } from '../../../components/modal/ModalForm'
// import { EditarClienteForm, EliminarClienteForm } from './ClienteTabFormDatas'
import { VentasPendientesGeneralButtons } from './VentasPendientesGeneralButtons'
import { ModalForm } from '../../../components/modal/ModalForm'
import { CompletarCuadernoValues } from './VentasPendientesFormDatas'

export const VentasPendientesBoard = () => {
    const dispatch = useDispatch();
    const globalParams = useSelector(state => state.globalParams)
    const [data, setData] = useState({
        data: [],
        recall: true,
        nav: false,
        navParam: '',
        navRef: 'id',
        loading: false,
        subId: 'cuaderno_id',
        params: {
            totalItems: 0,
            totalPages: 1,
            currentPage: 1,
            pageSize: 25,
            // nombres: ''
            filterBy: 'nombres',
            filterParam: ''
        },
        filters: ['nombres', 'codigo'],
        selected: null,
        modalCrearCliente: false,
        modalEditarCliente: false,
        modalEliminarCliente: false
    })
    const getClientes = async () => {
        await RequestAuthPaginated(
            'get',
            '/cuadernoHistorialPendientes/pag',
            setData,
            'data',
            dispatch,
            data.params
        )
    }

    useEffect(() => {
        getClientes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.recall, data?.params?.pageSize, data?.params?.currentPage, data.params?.nombres, data.params?.filterParam, data.params?.filterBy, globalParams.recall1])

    return (
        <GeneralContainer>
            <VentasPendientesGeneralButtons
                setData={setData}
                modals={{ modalCrearCliente: data.modalCrearCliente }}
            />
            <DefaultTable
                data={data}
                setData={setData}
                headers={[
                    {
                        label: 'Cliente',
                        value: ['user_nombres', 'user_apellido_paterno'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                    },
                    {
                        label: 'Producto',
                        value: ['producto_nombre'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                    },
                    {
                        label: 'Cantidad',
                        value: ['cantidad'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                        alignRight: true
                    },
                    {
                        label: 'Recogidos',
                        value: ['cantidad_retorno'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                        alignRight: true
                    },
                    {
                        label: 'Cobrado',
                        value: ['efectivo_total'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                        alignRight: true
                    },
                    {
                        label: 'Deuda',
                        value: ['deuda_total'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                        alignRight: true
                    },
                    {
                        label: 'Acciones',
                        type: 'acciones',
                        actions: [
                            {
                                adCNButton: 'warning',
                                adCNIcon: 'pen-to-square',
                                title: 'Editar',
                                func: () => setData(x => ({ ...x, modalEditarCliente: true })),
                                disabledFunc: (item) => {
                                    if (item.estado === 'inactivo')
                                        return true
                                    return false
                                }
                            },
                        ]
                    }
                ]}
            />
            {
                data.modalEditarCliente &&
                <ModalForm
                    show={data.modalEditarCliente}
                    onHide={() => setData(x => ({ ...x, modalEditarCliente: false }))}
                    urlApi={'/cuadernoHistorial/completar'}
                    method='put'
                    formData={CompletarCuadernoValues(data.selected)}
                />
            }
        </GeneralContainer>
    )
}
