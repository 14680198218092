import React from 'react'
import styled from 'styled-components'

const ThBasic = styled.th`
  border: 1px solid #495057;
  padding: 5px;
  background-color: #495057;
  color: white;
  overflow: hidden;
  clear: both;
  
`;

export const Th = ({ children, onClick }) => {
    return (
        <ThBasic
            onClick={onClick}
            style={{
                cursor: onClick ? 'pointer' : '',
            }}
        >
            {children}
        </ThBasic >
    )
}
