import React from 'react'
import { Link } from 'react-router-dom'

export default function Detail({
    id,
    detail
}) {
    return (
        <>
            <div className="row p-2 pt-0"
                style={{
                    fontSize: '14px'
                }}
            >
                {
                    detail &&
                    detail.map((data, index) => (
                        <React.Fragment key={index}>
                            <div className="col-12 d-flex gy-2 justify-content-center">
                                <div className="col-4 text-right">
                                    <p className="card-text mb-0">{data.label}:</p>
                                </div>
                                <div
                                    style={{ background: data.color ? '' : '#1f1f1f19' }}
                                    className={`col-8 rounded p-2 ${data.color && data.color}`}
                                >
                                    {
                                        data.img ?
                                            <img
                                                src={data.value}
                                                alt="..."
                                                style={{
                                                    width: '100%',
                                                    borderRadius: '7px',
                                                    color: 'rgba(0,0,0,.3)',
                                                    fontWeight: 'bold',
                                                }}
                                            />
                                            :
                                            <span className={`card-text fw-bold p-1 ${''}`} >{data.value}</span>

                                    }

                                </div>
                            </div>
                        </React.Fragment>

                    ))
                }
            </div>
            {
                id &&
                <div className="card-footer small-box m-0 p-0">
                    <Link to={`${id}`} className="small-box-footer text-dark">Ver más <i className="fas fa-arrow-circle-right" /></Link>
                </div>

            }
        </>
    )
}
