import {
    ORDER_SELLERS,
    SET_SELLERS
}
    from "../type";

export const initialState = {
    list: [],
    items: [],
    total: 0,
    desc: true,
    perPage: 10,
    currentPage: 1
}

export function userReducer(state = initialState, action) {
    switch (action.type) {

        case SET_SELLERS:
            return {
                ...state,
                list: action.payload,
                // items: action.payload.splice(0,state.perPage),
                total: action.payload.length
            }

        case ORDER_SELLERS:
            // console.log(state.list)
            let data = state.list
            let desc = state.desc

            // data.sort(b => b.nombre)
            data.sort((a, b) => a.nombre.localeCompare(b.nombre));

            if(desc)
            {
                data = data.reverse()                
            }

            // console.log(data)
            return {
                ...state,
                list: data,
                desc: !desc
            }

        // case ORDER_DESC_SELLERS:
        //     // console.log(state.list)
        //     let datas = state.list

        //     // datas.sort(b => b.nombre)
        //     datas.sort((a, b) => a.nombre.localeCompare(b.nombre));

        //     // console.log(datas)
        //     return {
        //         ...state,
        //         list: datas.reverse()
        //     }
        default:
            return state;
    }
}