import React from 'react'
import { SimpleTableContainer } from './TableContainer'
// import Searcher from '../../views/globalParams/filter/Searcher'
// import Paginator from '../../views/globalParams/filter/Paginator'

export const SimpleTable = ({ data, setData, headers, title, titleColor, loading }) => {
    // const [fullwidth, setFullWidth] = useState(false)
    return (
        <div className={`col-12 col-md-6`}>
            <div className='card'>
                <div className={`card-header bg-${titleColor} text-center fw-bold font-italic text-lg p-1`}>
                    {title}
                </div>
                <div className='card-body p-1'>
                    <div className="content-basic">
                    <SimpleTableContainer
                        headers={headers}
                        data={data}
                        setData={setData}
                        loading={loading}
                    />
                    </div>
                </div>
            </div>
        </div>
    )
}
