import React from 'react'
import ButtonTextIcon from '../../../components/button/ButtonTextIcon'
import { CrearClienteForm } from './ClienteTabFormDatas'
import { ModalForm } from '../../../components/modal/ModalForm'

export const ClienteTabGeneralButtons = ({ setDataClientes, modals: { modalCrearCliente } }) => {
    return (
        <div className='d-flex mb-1'>
            <ButtonTextIcon
                icon={<i className='ion-person-add'></i>}
                variant='danger'
                title='Registrar'
                onClick={() => setDataClientes(x => ({ ...x, modalCrearCliente: true }))}
            />
            {
                modalCrearCliente &&
                <ModalForm
                    show={modalCrearCliente}
                    onHide={() => setDataClientes(x => ({ ...x, modalCrearCliente: false }))}
                    urlApi={'/userCliente'}
                    method='post'
                    formData={CrearClienteForm()}
                />
            }
        </div>
    )
}

