import React from 'react'
import { Form } from 'react-bootstrap'

export default function InputTextTarea({
    rows,
    label,
    onChange,
    placeholder,
    value,
    name,
    disabled,
    required
}) {
    return (
        <Form.Control
            as="textarea"
            rows={rows}
            style={{
                // boxShadow: 'none',
                // fontSize: '13px'
            }}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            name={name}
            className='m-0'
            disabled={disabled}
            required={required}
        />
    )
}
