import React from 'react'
import { Button } from 'react-bootstrap'
// import ReactTooltip from 'react-tooltip'

export default function ButtonTextIcon({
    icon,
    variant,
    title,
    onClick,
    // setMessageTooltip
}) {
    return (

        <Button
            variant={variant}
            style={{
                // padding: '3px 10px',
                // fontSize: '22px',
                // width:'100%'
            }}
            className='elevation-2'
            // title={title}
            onClick={onClick}
            // data-tip data-for="registerTip"
        // onMouseOver={() => setMessageTooltip(title)}
        >
            {/* <div
                className="row d-flex"
                style={{
                    alignItems: 'center'
                }}
            > */}
                {/* <div className="col-2 bg-dark">{icon}</div> */}
                <div
                    // className="col-10"
                    style={{
                        fontSize: '14px',
                        fontWeight: '600'
                    }}
                >
                    {icon}{' '}
                    {title}
                </div>
            {/* </div> */}
        </Button>
    )
}
