import { Formik, Form } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ReactSelect from 'react-select'
import ButtonLinkIcon from '../../../../components/button/ButtonLinkIcon'
import ButtonSubmitSave from '../../../../components/button/ButtonSubmitSave'
import InputBeforeIcon from '../../../../components/form/InputBeforeIcon'
import Label from '../../../../components/label/Label'
import InputTextTarea from '../../../../components/textField/InputTextTarea'
import { CreateHistoryNoteApi } from '../../../../http/Services/HistoryNoteApi'
import { GetProductsApi } from '../../../../http/Services/ProductApi'
import { GetMyClientsApi } from '../../../../http/Services/UserApi'
import { toastToggleAction } from '../../../../redux/action/toastAction'
import ModalClientList from './ModalClientList'

export default function ModalCreateHistoryNote({ show, handleClose, recallAction, cuadernoId }) {
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const dispatch = useDispatch()
    const [products, setProducts] = useState([])
    const [producto, setProducto] = useState(null)
    const [modalConfirmation, setModalConfirmation] = useState(false)
    const [client, setClient] = useState(null)
    const [userOptions, setUserOptions] = useState([])
    const [user, setUser] = useState(null)
    const [retornable, setRetornable] = useState(false)

    const handleCloseConfirmation = () => setModalConfirmation(false)

    useEffect(() => {
        const getProducts = async () => {
            let res = await GetProductsApi();
            if (res.status === 200) {
                setProducts(res.data)
                // setRetornables(res.data.filter(r => r.retornable === 1))
            }
            else {
                console.log('no hay productos')
            }
        }
        getProducts()
    }, [])

    useEffect(() => {
        const getClients = async () => {
            try {
                let res = await GetMyClientsApi();
                let resList = []
                if (res.status === 200) {
                    res.data.map(data =>
                        // <option value={data.id} label={data.nombre + ' ' + (data.apellido_paterno ? data.apellido_paterno : '') + ' ' + (data.apellido_materno ? data.apellido_materno : '')} key={data.id} />
                        resList.push({ value: data.id, label: (data.nombre + ' ' + (data.apellido_paterno ? data.apellido_paterno : '') + ' ' + (data.apellido_materno ? data.apellido_materno : '')) })
                    )
                    setUserOptions(resList)
                }
            } catch (error) {

            }
        }
        getClients()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange2 = (e) => {
        // console.log(e)
        setUser(e)
        setClient(e)
    }

    const handleChange3 = (e) => {
        setProducto(products.find(p => p.id + "" === e.target.value))
        setRetornable(products.find(p => p.id + "" === e.target.value) && (products.find(p => p.id + "" === e.target.value).retornable === 1 || products.find(p => p.id + "" === e.target.value).retornable === "1") ? true : false)
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} className='elevation-1' size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h6 fw-bold text-secondary'>Registrar venta</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        producto_id: '',
                        cliente_id: 0,
                        cuaderno_id: cuadernoId,
                        cantidad: 1,
                        cantidad_vacio: 1,
                        metodo_pago: 'Efectivo',
                        metodo_vacio: 'normal',
                        total_cobrado: "",
                        estado_turril: 'Aceptable',
                        precio_unitario: "",
                        saldo_garantia: 0,
                        fecha_limite_pago: "",
                        fecha_limite_turril: "",
                        obs: ''
                    }}


                    onSubmit={(valores, { resetForm }) => {
                        // console.log(valores)
                        setButtonDisabled(true)
                        setButtonDisabled(true)
                        // setButtonSubmit(true)
                        // setButtonSubmit(false)
                        // confirmedAction()
                        const createHistoryNote = async () => {
                            try {
                                valores.cliente_id = client.value;
                                valores.producto_id = producto.id;
                                // console.log(valores)
                                let res = await CreateHistoryNoteApi(valores)
                                if (res.status === 200) {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'success',
                                        title: 'Exito!',
                                        message: 'Creado correctamente!',
                                        variant: 'bold'
                                    }))
                                    resetForm()
                                    recallAction()
                                    handleClose()
                                    setButtonDisabled(false)
                                    // confirmedAction()
                                }
                                else {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'warning',
                                        title: 'Alerta!',
                                        message: 'Los datos podrian no haberse cargado!',
                                        variant: 'bold'
                                    }))
                                    setButtonDisabled(false)
                                }
                            } catch (error) {
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'danger',
                                    title: 'Error al registrar venta!',
                                    message: 'Llene los datos correctamente!',
                                    variant: 'bold'
                                }))
                                setButtonDisabled(false)
                            }
                        }

                        createHistoryNote();
                    }}

                    handleChange={(valores) => {
                        console.log(valores)
                    }}
                >
                    {({ values, handleChange, handleBlur }) => (
                        <React.Fragment>
                            <Form>
                                <Modal.Body>
                                    <div className="row">
                                        <div className="col-sm-6 mb-2">
                                            <Label
                                                title='Cliente'
                                            >
                                                <ReactSelect
                                                    maxMenuHeight={250}
                                                    options={
                                                        userOptions
                                                    }
                                                    name="user_id"
                                                    value={user}
                                                    onChange={handleChange2}
                                                    isDisabled={buttonDisabled}
                                                    required={true}
                                                    isClearable={true}
                                                    className='fw-bold'
                                                />
                                            </Label>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <Label
                                                title='Producto'
                                            >
                                                <select
                                                    className="form-select fw-bold"
                                                    name="producto_id"
                                                    value={producto && producto.id ? producto.id : ""}
                                                    onChange={(e) => handleChange3(e)}
                                                    // onChange={(e) => handleChange22(e)}
                                                    // onChange={handleChange}
                                                    // style={{ boxShadow: 'none' }}
                                                    disabled={buttonDisabled}
                                                    required
                                                >
                                                    <option value='' label='...' required />
                                                    {
                                                        products.map(product => (
                                                            <option value={product.id} label={product.nombre + ', Bs. ' + product.precio} key={product.id} />

                                                        ))
                                                    }
                                                </select>
                                            </Label>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <Label
                                                title='Metodo de pago'
                                            >
                                                <select
                                                    className="form-select fw-bold"
                                                    name="metodo_pago"
                                                    value={values.metodo_pago}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    // style={{ boxShadow: 'none' }}
                                                    disabled={buttonDisabled}
                                                >
                                                    <option value='Efectivo' label={'Efectivo'} />
                                                    <option value='Transferencia' label={'Transferencia'} />
                                                </select>
                                            </Label>
                                        </div>
                                        <div
                                            className="col-md-6 mb-2"
                                            style={{
                                                display: retornable ? "" : "none"
                                            }}
                                        >
                                            <Label
                                                title='Estado del contenedor recibido'
                                            >
                                                <select
                                                    className="form-select fw-bold"
                                                    name="estado_turril"
                                                    value={values.estado_turril}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    // style={{ boxShadow: 'none' }}
                                                    disabled={buttonDisabled}
                                                >
                                                    <option value='Aceptable' label={'Aceptable'} />
                                                    <option value='Nuevo' label={'Nuevo'} />
                                                    <option value='Roto' label={'Roto'} />
                                                </select>
                                            </Label>
                                        </div>
                                        <div className="col-6 col-md-2 mb-2">
                                            <Label
                                                title='Cantidad'
                                            >
                                                <InputBeforeIcon
                                                    id='cantidad'
                                                    placeholder='Cantidad'
                                                    name='cantidad'
                                                    type='number'
                                                    onChange={handleChange}
                                                    value={values.cantidad}
                                                    disabled={buttonDisabled}
                                                    // required={true}
                                                    min={1}
                                                />
                                            </Label>
                                        </div>
                                        {
                                            //  && .retornable === 1 &&
                                            <>
                                                <div
                                                    className="col-6 col-md-2 mb-2"
                                                    style={{
                                                        display: retornable ? "" : "none"
                                                    }}
                                                >
                                                    <Label
                                                        title='Cantidad vacios'
                                                    >
                                                        <InputBeforeIcon
                                                            id='cantidad_vacio'
                                                            placeholder='Cantidad vacios'
                                                            name='cantidad_vacio'
                                                            type='number'
                                                            onChange={handleChange}
                                                            value={values.cantidad_vacio}
                                                            disabled={buttonDisabled}
                                                            required={retornable ? true : false}
                                                            min={0}
                                                            max={values.cantidad ? values.cantidad : 1}
                                                        />
                                                    </Label>
                                                </div>
                                                <div
                                                    className="col col-md-4 mb-2"
                                                    style={{
                                                        display: retornable ? "" : "none"
                                                    }}
                                                >
                                                    <Label
                                                        title='Tipo de intercambio de turril'
                                                    >
                                                        <select
                                                            className="form-select fw-bold"
                                                            name="metodo_vacio"
                                                            value={values.metodo_vacio}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            // style={{ boxShadow: 'none' }}
                                                            disabled={buttonDisabled}
                                                            required={retornable ? true : false}
                                                        >
                                                            {/* <option value='' label='...' disabled /> */}
                                                            <option value='normal' label='Normal' disabled={values.cantidad - values.cantidad_vacio <= 0 ? false : true} />
                                                            <option value='garantia' label='Garantia' disabled={values.cantidad - values.cantidad_vacio > 0 ? false : true} />
                                                            <option value='prestamo' label='Prestamo' disabled={values.cantidad - values.cantidad_vacio > 0 ? false : true} />
                                                        </select>
                                                    </Label>
                                                </div>
                                                <div className="col-6 col-md-4 mb-2"
                                                    style={{
                                                        display: values.metodo_vacio === "garantia" ? "" : "none"
                                                    }}
                                                >
                                                    <Label
                                                        title='Monto garantia'
                                                    >
                                                        <InputBeforeIcon
                                                            id='saldo_garantia'
                                                            placeholder='Monto garantia'
                                                            name='saldo_garantia'
                                                            type='number'
                                                            onChange={handleChange}
                                                            value={values.saldo_garantia}
                                                            disabled={buttonDisabled}
                                                            param='Bs.'
                                                            required={values.metodo_vacio === "garantia" && retornable ? true : false}
                                                            step={values.metodo_vacio === "garantia" ? 0.1 : null}
                                                            min={values.metodo_vacio === "garantia" ? 1 : 0}
                                                        />
                                                    </Label>
                                                </div>
                                                <div className="col-6 col-md-4 mb-2"
                                                    style={{
                                                        display: values.metodo_vacio === "prestamo" ? "" : "none"
                                                    }}
                                                >
                                                    <Label
                                                        title='Fecha límite de entrega turril'
                                                    >
                                                        <InputBeforeIcon
                                                            id='fecha_limite_turril'
                                                            placeholder='Fecha limite de entrega de turril'
                                                            name='fecha_limite_turril'
                                                            type='date'
                                                            onChange={handleChange}
                                                            value={values.fecha_limite_turril}
                                                            disabled={buttonDisabled}
                                                            required={values.metodo_vacio === "prestamo" && retornable ? true : false}
                                                        />
                                                    </Label>
                                                </div>
                                            </>
                                        }
                                        <div className="col-12"></div>
                                        <div className="col-6 col-md-4 mb-2">
                                            <Label
                                                title='Precio unitario'
                                            >
                                                <InputBeforeIcon
                                                    id='precio_unitario'
                                                    placeholder='Precio por unidad'
                                                    name='precio_unitario'
                                                    type='number'
                                                    onChange={handleChange}
                                                    value={values.precio_unitario}
                                                    disabled={buttonDisabled}
                                                    param='Bs.'
                                                    required={true}
                                                    min={1}
                                                    step={0.1}
                                                />
                                            </Label>
                                        </div>
                                        <div className="col-6 col-md-4 mb-2">
                                            <Label
                                                title='Cobrado'
                                            >
                                                <InputBeforeIcon
                                                    id='total_cobrado'
                                                    placeholder='Total cobrado'
                                                    name='total_cobrado'
                                                    type='number'
                                                    onChange={handleChange}
                                                    value={values.total_cobrado}
                                                    disabled={buttonDisabled}
                                                    param='Bs.'
                                                    required={true}
                                                    min={0}
                                                    step={0.1}
                                                    max={values.precio_unitario * values.cantidad}
                                                />
                                            </Label>
                                        </div>
                                        <div className="col-6 col-md-4 mb-2"
                                            style={{
                                                display: (values.precio_unitario * values.cantidad) - values.total_cobrado > 0 ? "" : "none"
                                            }}
                                        >
                                            <Label
                                                title='Fecha límite de pago'
                                            >
                                                <InputBeforeIcon
                                                    id='fecha_limite_pago'
                                                    placeholder='Fecha limite de pago'
                                                    name='fecha_limite_pago'
                                                    type='date'
                                                    onChange={handleChange}
                                                    value={values.fecha_limite_pago}
                                                    disabled={buttonDisabled}
                                                    required={(values.precio_unitario * values.cantidad) - values.total_cobrado > 0 ? true : false}
                                                />
                                            </Label>
                                        </div>
                                        <div className="col-md-12">
                                            <Label
                                                title='Observaciones'
                                            >
                                                <InputTextTarea
                                                    rows={2}
                                                    onChange={handleChange}
                                                    placeholder='Observaciones...'
                                                    value={values.obs}
                                                    name='obs'
                                                    disabled={buttonDisabled}
                                                />
                                            </Label>
                                        </div>
                                        <div className=" col-12 mt-3">
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12 col-lg-6 row d-flex justify-content-center">
                                                    <div className="row table-primary">
                                                        <div className="col-6"><small>Total a pagar</small></div>
                                                        <div className="col-3  text-primary overflow-hidden">
                                                            <strong>{values.precio_unitario * values.cantidad}</strong>
                                                        </div>
                                                        <div className="col-3"><small>Bs.</small></div>
                                                    </div>
                                                    <div className="row table-success py-1">
                                                        <div className="col-6"><small>Total cobrado</small></div>
                                                        <div className="col-3  text-success overflow-hidden">
                                                            <strong>{values.total_cobrado}</strong>
                                                        </div>
                                                        <div className="col-3"><small>Bs.</small></div>
                                                    </div>
                                                    <div className="row table-danger py-1">
                                                        <div className="col-6"><small>Total deuda</small></div>
                                                        <div className="col-3  text-danger overflow-hidden">
                                                            <strong>{(values.precio_unitario * values.cantidad) - values.total_cobrado}</strong>
                                                        </div>
                                                        <div className="col-3"><small>Bs.</small></div>
                                                    </div>
                                                    <div className="row table-warning py-1"
                                                        style={{
                                                            display: retornable ? "" : "none"
                                                        }}
                                                    >
                                                        <div className="col-6"><small>Turriles a cuenta</small></div>
                                                        <div className="col-3  text-secondary overflow-hidden">
                                                            <strong>{values.cantidad - values.cantidad_vacio}</strong>
                                                        </div>
                                                        <div className="col-3"><small>{values.metodo_vacio}</small></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mb-0">
                                            {
                                                values.cantidad - values.cantidad_vacio > 0 && values.metodo_vacio === 'normal' && retornable &&
                                                <ButtonLinkIcon
                                                    title='Seleccione el tipo de intercambio de turril Prestamo o Garantia!'
                                                    iconB={<i className="bi bi-exclamation-triangle-fill" />}
                                                    bg={'warning'}
                                                    // color={'secondary'}
                                                    // bold={false}
                                                    btn={false}
                                                />
                                            }
                                        </div>
                                        <div className="col-12 mb-0">
                                            {
                                                values.cantidad - values.cantidad_vacio === 0 && values.metodo_vacio !== 'normal' && retornable &&
                                                <ButtonLinkIcon
                                                    title='Seleccione el tipo de intercambio de turril Normal!'
                                                    iconB={<i className="bi bi-exclamation-triangle-fill" />}
                                                    bg={'warning'}
                                                    // color={'secondary'}
                                                    // bold={false}
                                                    btn={false}
                                                />
                                            }
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancelar
                                    </Button>
                                    {/* <ButtonSubmitSave
                                        text='Registrar venta'
                                        color='#00bfa5'
                                        disabled={!buttonSubmit && values.precio_unitario !== 0 && client && values.producto_id !== "0" && values.producto_id !== 0 && ((values.cantidad - values.cantidad_vacio <= 0 && values.metodo_vacio === 'normal') || (values.cantidad - values.cantidad_vacio > 0 && values.metodo_vacio !== 'normal')) ? confirmed : true}
                                    /> */}
                                    <ButtonSubmitSave
                                        text='Registrar venta'
                                        color='#00bfa5'
                                        // disabled={values.cantidad - values.cantidad_vacio > 0 && values.metodo_vacio === 'normal' ? true : (client ? buttonDisabled : true)}
                                        disabled={values.cantidad - values.cantidad_vacio > 0 && values.metodo_vacio === 'normal' && retornable ? true : buttonDisabled}
                                    />
                                    {/* <input type="submit" value="asd" /> */}
                                </Modal.Footer>
                            </Form>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>
            {
                modalConfirmation &&
                <ModalClientList
                    show={modalConfirmation}
                    handleClose={handleCloseConfirmation}
                    title='Seleccionar cliente'
                    buttonText='Seleccionar'
                    variant='success'
                    setClient={setClient}
                    client={client}
                />
            }
        </>
    )
}
