import React from 'react'

export default function CheckBoxBasic({ id, name, label, value, onChange, disabled, onClick }) {
    return (
        <div className="icheck-info">
            <input
                type="checkbox"
                id={id}
                name={name}
                defaultChecked={value}
                onChange={onChange}
                onClick={onClick}
                style={{ color: 'red' }}
                disabled={disabled}
            />
            {/* <Field
                    type="checkbox"
                    name={name}
                    value={value}
                /> */}
            <label htmlFor={id} style={{ fontSize: '13px', fontWeight: 'bold' }}>
                {label}
            </label>
        </div>
    )
}
