import axios from "axios";

export async function CreateExpenseNoteApi(data) {
    const response = axios({
        url: `/expenseNote`,
        method: 'post',
        data:data,
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}
