import React from 'react'
import { Link } from 'react-router-dom'

export default function CardBasicMin({ title, description, icon, to, color, sup }) {
    return (
        <
            div className={"small-box " + color} 
            style={{overflow:'hidden'}}
        >
            <div className="inner">
                <h6 className='animation__shake fw-bold'>{title} <small >{sup}</small></h6>
                <p>{description}</p>
            </div>
            <div className="icon pb-2">
                {icon}
            </div>
            {
                to &&
                <Link to={to} className="small-box-footer">Ver más <i className="fas fa-arrow-circle-right" /></Link>
            }
        </div>
    )
}
