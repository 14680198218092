import React, { useEffect, useState } from 'react'
import ModalConfirmation from '../../../../components/modal/ModalConfirmation'
import { useDispatch } from 'react-redux'
import { toastToggleAction } from '../../../../redux/action/toastAction'
import { useParams } from 'react-router-dom'
import { GetNoteAllDataApi, GetNoteByIdApi, VerificationCurrentNoteApi } from '../../../../http/Services/NoteApi'
import ModalCreateHistoryNote from './ModalCreateHistoryNote'
import { DeleteHistoryNoteApi } from '../../../../http/Services/HistoryNoteApi'
import ButtonBar from '../../../../components/button/ButtonBar'
import ModalCreatePayment from '../payment/ModalCreatePayment'
import { DateConvert } from '../../../../utils/dateConvert'
import ModalCreateExpenseNote from '../expense/ModalCreateExpenseNote'
import ModalCreateHistoryBarrelAccount from '../historyBarrelAccount/ModalCreateHistoryBarrelAccount'
import ModalUpdateNoteCash from './ModalUpdateNoteCash'
import { GetGlobalDataApi } from '../../../../http/Services/UserApi'
import { getGlobalDataAction } from '../../../../redux/action/globalDataAction'
import GeneralContainer from '../../../globalParams/GeneralContainer'
import { ThParam } from '../../../globalParams/ThParam'
import { Tr } from '../../../../components/table/Tr'
import { TableMin } from '../../../../components/table/TableMin'
import Loading from '../../../globalParams/Loading'
import { Td } from '../../../../components/table/Td'
import ButtonIcon from '../../../../components/button/ButtonIcon'
import ModalDetailHistoryNote from './ModalDetailHistoryNote'
import ModalDetailPayment from './ModalDetailPayment'
import ModalDetailExpense from './ModalDetailExpense'
import ModalDetailBarrelAccount from './ModalDetailBarrelAccount'
import ButtonBarFlex from '../../../../components/button/ButtonBarFlex'

export default function Note() {
    const [historyNotes, setHistoryNotes] = useState([])
    const [note, setNote] = useState({})
    const [recall, setRecall] = useState(false)
    const [allRecall, setAllRecall] = useState(false)
    const [toggleModalCreate, setToggleModalCreate] = useState(false)
    const [modalConfirmation, setModalConfirmation] = useState(false)
    const [data, setData] = useState({})

    const [noteData, setNoteData] = useState({})
    // payment
    const [toggleModalCreatePayment, setToggleModalCreatePayment] = useState(false)
    const [payments, setPayments] = useState(null)
    // expense
    const [toggleModalCreateExpenseNote, setToggleModalCreateExpenseNote] = useState(false)
    const [expenses, setExpenses] = useState(null)
    // barrel collect
    const [toggleModalCreateBarrelAccount, setToggleModalCreateBarrelAccount] = useState(false)
    const [barrelCollects, setBarrelCollects] = useState(null)
    const [stateData, setStateData] = useState("loading...")

    // update note cash
    const [noteCash, setNoteCash] = useState(false)

    const [showHistoryNote, setShowHistoryNote] = useState(false)
    const [showPayment, setShowPayment] = useState(false)
    const [showExpense, setShowExpense] = useState(false)
    const [showBarrelAccount, setShowBarrelAccount] = useState(false)


    const param = useParams()
    const dispatch = useDispatch()

    let num = 0
    let numPayment = 0
    let numExpense = 0
    let numBarrelCollect = 0

    //modalconfirmation
    const handleCloseConfirmation = () => setModalConfirmation(false)

    useEffect(() => {
        if (window.sessionStorage.getItem('dp') !== 'adm' && window.sessionStorage.getItem('dp') !== 'vnt' && window.sessionStorage.getItem('dp') !== 'pvnt') {
            window.location.replace('/dashboard/home')
        }
        const verificationCurrentNote = async () => {
            try {
                let res = await VerificationCurrentNoteApi(param.noteId);
                if (res.status === 200) {
                    console.log('authorized')
                }
            } catch (error) {
                window.location = '/dashboard/home'
            }
        }
        // if (window.localStorage.getItem('dp') !== 'adm') {
        if (window.sessionStorage.getItem('dp') !== 'adm') {
            verificationCurrentNote()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recall, allRecall])

    useEffect(() => {
        const geNoteAllData = async () => {
            try {
                let res = await GetNoteAllDataApi(param.noteId);
                if (res.status === 200) {
                    setHistoryNotes(res.data.historyNotes)
                    setPayments(res.data.payments)
                    setExpenses(res.data.expenses)
                    setBarrelCollects(res.data.barrel_accounts)
                    setStateData("")
                }
            } catch (error) {

            }
        }

        geNoteAllData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recall, allRecall])

    useEffect(() => {
        const getNoteById = async () => {
            try {
                let res = await GetNoteByIdApi(param.noteId);
                if (res.status === 200) {
                    // console.log(res.data)
                    setNoteData(res.data)
                }
            } catch (error) {
                dispatch(toastToggleAction({
                    show: true,
                    bg: 'danger',
                    title: 'Error!',
                    message: 'Error al cargar los datos del cuaderno!',
                    variant: 'bold'
                }))
            }
        }

        getNoteById()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recall])

    useEffect(() => {
        const getGlobalData = async () => {
            try {
                let res = await GetGlobalDataApi();
                if (res.status === 200) {
                    dispatch(getGlobalDataAction(res.data))
                }
            } catch (error) {
                console.log('error in reducers')
            }
        }

        getGlobalData()


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recall])

    //recall
    const recallAction = () => {
        setStateData("loading...")
        setNoteData({})
        setHistoryNotes([])
        setRecall(!recall)
        setNote({})
        setPayments([])
        setExpenses([])
        setBarrelCollects([])
        setAllRecall(!allRecall)
    }

    //show modal create
    const handleShow = () => {
        setToggleModalCreate(true)
    }
    const handleClose = () => {
        setToggleModalCreate(false)
    }
    //modal create payment
    const handleShowCreatePayment = () => {
        setToggleModalCreatePayment(true)
    }
    const handleCloseCreatePayment = () => {
        setToggleModalCreatePayment(false)
    }
    //modal create expense
    const handleShowCreateExpenseNote = () => {
        setToggleModalCreateExpenseNote(true)
    }
    const handleCloseCreateExpenseNote = () => {
        setToggleModalCreateExpenseNote(false)
    }
    //modal create history barrel account
    const handleShowCreateHistoryBarrelAccount = () => {
        setToggleModalCreateBarrelAccount(true)
    }
    const handleCloseCreateHistoryBarrelAccount = () => {
        setToggleModalCreateBarrelAccount(false)
    }

    const handleShowUpdateNoteCash = () => {
        setNoteCash(true)
    }
    const handleCloseUpdateNoteCash = () => {
        setNoteCash(false)
    }

    // delete client
    const deleteData = async () => {
        try {
            let res = await DeleteHistoryNoteApi(note.id)
            if (res.status === 200) {
                handleCloseConfirmation()
                recallAction()

                dispatch(toastToggleAction({
                    show: true,
                    bg: 'success',
                    title: 'Exito!',
                    message: 'Eliminado correctamente!',
                    variant: 'bold'
                }))
            }
        } catch (error) {
            dispatch(toastToggleAction({
                show: true,
                bg: 'danger',
                title: 'Error!',
                message: 'No se puede eliminar la venta!',
                variant: 'bold'
            }))
        }
    }

    const setDataFunc = (data) => {
        setData(data)
        setShowHistoryNote(true)
    }
    const setDataFuncPayment = (data) => {
        setData(data)
        setShowPayment(true)
    }
    const setDataFuncExpense = (data) => {
        setData(data)
        setShowExpense(true)
    }
    const setDataFuncBarrelAccount = (data) => {
        setData(data)
        setShowBarrelAccount(true)
    }

    return (
        <>
            <GeneralContainer>
                <div className="row gx-3">
                    <div className='col-12'>
                        <div className="card">
                            <div className="card-body p-2 overflow-auto" style={{ fontSize: '14px' }}>
                                <div className="row gy-2 gx-2 d-flex justify-content-center p-1">
                                    <div
                                        className="col-12 col-md-4"
                                    >
                                        <div className="row gy-0 gx-1">
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Número: </span>
                                                <span className="card-text fw-bold text-danger h3" >{noteData.codigo}</span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Estado: </span>
                                                <span className={`card-text fw-bold text-${noteData.estado === 'abierto' ? 'primary' : (noteData.estado === 'cerrado' ? 'success' : 'secondary')}`} >{noteData.estado}</span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Vendedor: </span>
                                                <span className="card-text fw-bold text-primary" >{(noteData.u_nombre ? noteData.u_nombre : '') + ' ' + (noteData.u_apellido_paterno ? noteData.u_apellido_paterno : '') + ' ' + (noteData.u_apellido_materno ? noteData.u_apellido_materno : '')}</span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Fecha de apertura:</span>
                                                <span className="card-text fw-bold text-primary" > {DateConvert(noteData.created_at)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3">
                                        <div className="row gy-0 gx-1">
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Venta: </span>
                                                <span className="card-text fw-bold text-danger" >{noteData.total_venta}<small> Bs</small></span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Deuda: </span>
                                                <span className="card-text fw-bold text-danger" >{noteData.total_deuda}<small> Bs</small></span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Confirmar: </span>
                                                <span className="card-text fw-bold text-danger" >{noteData.total_pagos_confirmar}<small> Bs</small></span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Efectivo: </span>
                                                <span className="card-text fw-bold text-danger" >{noteData.total_cuaderno}<small> Bs</small></span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Gasto: </span>
                                                <span className="card-text fw-bold text-danger" >{noteData.total_gasto}<small> Bs</small></span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Garantía: </span>
                                                <span className="card-text fw-bold text-danger" >{noteData.total_garantia}<small> Bs</small></span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Cobranza: </span>
                                                <span className="card-text fw-bold text-danger" >{noteData.total_cobranza}<small> Bs</small></span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Turriles: </span>
                                                <span className="card-text fw-bold text-danger" >{noteData.total_turril_recogido}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-3">
                                        <div className="row gy-0 gx-1">
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Moneda: </span>
                                                <span className="card-text fw-bold"
                                                    style={{ color: '#bba90a' }}
                                                >{noteData.moneda} <small> Bs</small></span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Billete: </span>
                                                <span className="card-text fw-bold"
                                                    style={{ color: '#bba90a' }}
                                                >{noteData.billete} <small> Bs</small></span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Dolar: </span>
                                                <span className="card-text fw-bold"
                                                    style={{ color: '#bba90a' }}
                                                >{noteData.dolar} <small> $us</small></span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Faltate: </span>
                                                <span className="card-text fw-bold"
                                                    style={{ color: '#bba90a' }}
                                                >{noteData.faltante} <small> Bs</small></span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Tipo de cambio: </span>
                                                <span className="card-text fw-bold"
                                                    style={{ color: '#bba90a' }}
                                                >{noteData.tipo_cambio} <small> Bs</small></span>
                                            </div>
                                            <div className="col-12 p-0">
                                                <span className="card-text"
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >Total dinero: </span>
                                                <span className="card-text fw-bold"
                                                    style={{ color: '#bba90a' }}
                                                >{noteData.total_dinero} <small> Bs</small></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer p-1">
                                <div className="d-flex justify-content-between">
                                    <ButtonBarFlex
                                        text="Recargar"
                                        // color='purple'
                                        onClick={() => recallAction()}
                                        fs='small'
                                        icon='ion-loop'
                                    />
                                    {(window.sessionStorage.getItem('dp') === 'adm' || window.sessionStorage.getItem('dp') === 'pvnt') && noteData.id &&
                                        handleShowUpdateNoteCash &&
                                        // <div className='col-6 col-sm-2'>
                                        <ButtonBarFlex
                                            text="Registrar efectivo"
                                            disabled={noteData.estado === 'abierto' ? false : true}
                                            // color='warning'
                                            onClick={() => handleShowUpdateNoteCash()}
                                            fs='small'
                                        />
                                        // </div>
                                    }
                                    {/* <div className='col-3 col-md-2'>
                                        <ButtonBar
                                            text="Recargar"
                                            color='purple'
                                            onClick={() => recallAction()}
                                            fs='small'
                                        />
                                    </div> */}
                                </div>
                            </div>
                            <div className={`d-${stateData === "loading..." ? 'flex' : 'none'}`}
                                style={{
                                    position: 'absolute',
                                    width: '100%',
                                    height: '100%',
                                    background: 'rgba(255,255,255,.8)',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <span className="loader"></span>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        {
                            (window.sessionStorage.getItem('dp') === 'vnt' || window.sessionStorage.getItem('dp') === 'pvnt') &&
                            <div className='card'>
                                <div className='card-header p-1'>
                                    <div className='row g-1'>
                                        <div className='col-3'>
                                            <ButtonBar
                                                text="Registrar venta"
                                                disabled={false}
                                                color='danger'
                                                onClick={() => handleShow()}
                                                fs='small'
                                            />
                                        </div>
                                        <div className='col-3'>
                                            <ButtonBar
                                                text="Cobrar deuda"
                                                disabled={false}
                                                onClick={() => handleShowCreatePayment()}
                                                color='primary'
                                                fs='small'
                                            />
                                        </div>
                                        <div className='col-3'>
                                            <ButtonBar
                                                text="Anotar gasto"
                                                disabled={false}
                                                color='warning'
                                                onClick={() => handleShowCreateExpenseNote()}
                                                fs='small'
                                            />
                                        </div>
                                        <div className='col-3'>
                                            <ButtonBar
                                                text="Recoger turril"
                                                disabled={false}
                                                color='success'
                                                onClick={() => handleShowCreateHistoryBarrelAccount()}
                                                fs='small'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={`col-12 col-md-6`}>
                        <div className="card card-danger">
                            <div className='card-header p-1 bg-danger'
                                style={{
                                    textAlign: 'center',
                                    fontSize: '20px'
                                }}
                            >
                                <strong><em>Ventas</em></strong>
                            </div>
                            <div className="card-body p-1">
                                <div className="content-basic">
                                    {
                                        stateData !== ""
                                            ?
                                            stateData === "loading..."
                                                ?
                                                <Loading
                                                    center
                                                />
                                                :
                                                <div
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        color: 'rgba(0,0,0,.4)',
                                                        padding: '5px'
                                                    }}
                                                >
                                                    {stateData}
                                                </div>
                                            :
                                            historyNotes.length === 0 ?
                                                <div
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        color: 'rgba(0,0,0,.4)',
                                                        padding: '5px',
                                                        margin: 'auto'
                                                    }}
                                                >
                                                    sin registros...
                                                </div>
                                                :
                                                <TableMin>
                                                    <thead>
                                                        <Tr>
                                                            <ThParam
                                                                label=''
                                                                width='50px'
                                                            />
                                                            <ThParam
                                                                label='Cliente'
                                                                width='100px'
                                                            />
                                                            <ThParam
                                                                label='Producto'
                                                                width='100px'
                                                            />
                                                            <ThParam
                                                                label='Cantidad'
                                                                width='80px'
                                                            />
                                                            <ThParam
                                                                label='Recogido'
                                                                width='80px'
                                                            />
                                                            <ThParam
                                                                label='Cobrado'
                                                                width='80px'
                                                            />
                                                            <ThParam
                                                                label='Deuda'
                                                                width='80px'
                                                            />
                                                            <ThParam
                                                                label='Acciones'
                                                                // onClick={() => order('estado', data, desc, setData, setDesc)}
                                                                width='75px'
                                                            />
                                                        </Tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            historyNotes?.map((data) => (
                                                                <Tr
                                                                    key={data.id}
                                                                >
                                                                    <Td
                                                                        size='14px'
                                                                        center
                                                                    >
                                                                        {historyNotes.length - num++}
                                                                    </Td>
                                                                    <Td
                                                                        size='14px'
                                                                    >
                                                                        {data.cliente}{' '}
                                                                        {data.apellido_paterno}{' '}
                                                                        {data.apellido_materno}{' '}
                                                                    </Td>
                                                                    <Td
                                                                        size='14px'
                                                                        center
                                                                    >
                                                                        {data.producto}
                                                                    </Td>
                                                                    <Td
                                                                        size='14px'
                                                                        center
                                                                    >
                                                                        {data.cantidad}
                                                                    </Td>
                                                                    <Td
                                                                        size='14px'
                                                                        center
                                                                    >
                                                                        {data.retornable === 1 || data.retornable === "1" ? data.cantidad_vacio : "-"}
                                                                    </Td>
                                                                    <Td
                                                                        size='14px'
                                                                        center
                                                                    >
                                                                        {data.total_cobrado}{' '}<small>Bs</small>
                                                                    </Td>
                                                                    <Td
                                                                        size='14px'
                                                                        center
                                                                    >
                                                                        {data.total_deuda}{' '}<small>Bs</small>
                                                                    </Td>
                                                                    <Td
                                                                        center={true}
                                                                    >
                                                                        <ButtonIcon
                                                                            icon={"bi bi-eye-fill"}
                                                                            variant="danger"
                                                                            title="Ver más"
                                                                            onClick={() => setDataFunc(data)}
                                                                            color='text-white'
                                                                        />
                                                                    </Td>
                                                                </Tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </TableMin>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-md-6`}>
                        <div className="card card-primary">
                            <div className='card-header p-1 bg-primary'
                                style={{
                                    textAlign: 'center',
                                    fontSize: '20px'
                                }}
                            >
                                <strong><em>Cobros</em></strong>
                            </div>
                            <div className="card-body p-1">
                                <div className="content-basic">
                                    {
                                        stateData !== ""
                                            ?
                                            stateData === "loading..."
                                                ?
                                                <Loading
                                                    center
                                                />
                                                :
                                                <div
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        color: 'rgba(0,0,0,.4)',
                                                        padding: '5px'
                                                    }}
                                                >
                                                    {stateData}
                                                </div>
                                            :
                                            payments.length === 0 ?
                                                <div
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        color: 'rgba(0,0,0,.4)',
                                                        padding: '5px',
                                                        margin: 'auto'
                                                    }}
                                                >
                                                    sin registros...
                                                </div>
                                                :
                                                <TableMin>
                                                    <thead>
                                                        <Tr>
                                                            <ThParam
                                                                label=''
                                                                width='50px'
                                                            />
                                                            <ThParam
                                                                label='Cliente'
                                                                width='100px'
                                                            />
                                                            <ThParam
                                                                label='Producto'
                                                                width='100px'
                                                            />
                                                            <ThParam
                                                                label='Cobrado'
                                                                width='80px'
                                                            />
                                                            <ThParam
                                                                label='Acciones'
                                                                width='75px'
                                                            />
                                                        </Tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            payments?.map((data) => (
                                                                <Tr
                                                                    key={data.id}
                                                                >
                                                                    <Td
                                                                        size='14px'
                                                                        center
                                                                    >
                                                                        {payments.length - numPayment++}
                                                                    </Td>
                                                                    <Td
                                                                        size='14px'
                                                                    >
                                                                        {data.cliente}{' '}
                                                                        {data.apellido_paterno}{' '}
                                                                        {data.apellido_materno}{' '}
                                                                    </Td>
                                                                    <Td
                                                                        size='14px'
                                                                        center
                                                                    >
                                                                        {data.producto}
                                                                    </Td>
                                                                    <Td
                                                                        size='14px'
                                                                        center
                                                                    >
                                                                        {data.monto}{' '}<small>Bs</small>
                                                                    </Td>
                                                                    <Td
                                                                        center={true}
                                                                    >
                                                                        <ButtonIcon
                                                                            icon={"bi bi-eye-fill"}
                                                                            variant="primary"
                                                                            title="Ver más"
                                                                            onClick={() => setDataFuncPayment(data)}
                                                                            color='text-white'
                                                                        />
                                                                    </Td>
                                                                </Tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </TableMin>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-md-6`}>
                        <div className="card card-warning">
                            <div className='card-header p-1 bg-warning'
                                style={{
                                    textAlign: 'center',
                                    fontSize: '20px'
                                }}
                            >
                                <strong><em>Gastos</em></strong>
                            </div>
                            <div className="card-body p-1">
                                <div className="content-basic">
                                    {
                                        stateData !== ""
                                            ?
                                            stateData === "loading..."
                                                ?
                                                <Loading
                                                    center
                                                />
                                                :
                                                <div
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        color: 'rgba(0,0,0,.4)',
                                                        padding: '5px'
                                                    }}
                                                >
                                                    {stateData}
                                                </div>
                                            :
                                            expenses.length === 0 ?
                                                <div
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        color: 'rgba(0,0,0,.4)',
                                                        padding: '5px',
                                                        margin: 'auto'
                                                    }}
                                                >
                                                    sin registros...
                                                </div>
                                                :
                                                <TableMin>
                                                    <thead>
                                                        <Tr>
                                                            <ThParam
                                                                label=''
                                                                width='50px'
                                                            />
                                                            <ThParam
                                                                label='Monto'
                                                                width='70px'
                                                            />
                                                            <ThParam
                                                                label='Tipo'
                                                                width='80px'
                                                            />
                                                            <ThParam
                                                                label='Descripcion'
                                                                width='130px'
                                                            />
                                                            <ThParam
                                                                label='Acciones'
                                                                width='75px'
                                                            />
                                                        </Tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            expenses?.map((data) => (
                                                                <Tr
                                                                    key={data.id}
                                                                >
                                                                    <Td
                                                                        size='14px'
                                                                        center
                                                                    >
                                                                        {expenses.length - numExpense++}
                                                                    </Td>
                                                                    <Td
                                                                        size='14px'
                                                                    >
                                                                        {data.monto}{' '}<small>Bs</small>

                                                                    </Td>
                                                                    <Td
                                                                        size='14px'
                                                                        center
                                                                    >
                                                                        {data.tipo}
                                                                    </Td>
                                                                    <Td
                                                                        size='14px'
                                                                        center
                                                                    >
                                                                        {data.descripcion}
                                                                    </Td>
                                                                    <Td
                                                                        center={true}
                                                                    >
                                                                        <ButtonIcon
                                                                            icon={"bi bi-eye-fill"}
                                                                            variant="warning"
                                                                            title="Ver más"
                                                                            onClick={() => setDataFuncExpense(data)}
                                                                            color='text-white'
                                                                        />
                                                                    </Td>
                                                                </Tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </TableMin>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`col-12 col-md-6`}>
                        <div className="card card-success">
                            <div className='card-header p-1 bg-success'
                                style={{
                                    textAlign: 'center',
                                    fontSize: '20px'
                                }}
                            >
                                <strong><em>Turriles recogidos</em></strong>
                            </div>
                            <div className="card-body p-1">
                                <div className="content-basic">
                                    {
                                        stateData !== ""
                                            ?
                                            stateData === "loading..."
                                                ?
                                                <Loading
                                                    center
                                                />
                                                :
                                                <div
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        color: 'rgba(0,0,0,.4)',
                                                        padding: '5px'
                                                    }}
                                                >
                                                    {stateData}
                                                </div>
                                            :
                                            barrelCollects.length === 0 ?
                                                <div
                                                    style={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        color: 'rgba(0,0,0,.4)',
                                                        padding: '5px',
                                                        margin: 'auto'
                                                    }}
                                                >
                                                    sin registros...
                                                </div>
                                                :
                                                <TableMin>
                                                    <thead>
                                                        <Tr>
                                                            <ThParam
                                                                label=''
                                                                width='100px'
                                                            />
                                                            <ThParam
                                                                label='Cliente'
                                                                width='100px'
                                                            />
                                                            <ThParam
                                                                label='Producto'
                                                                width='100px'
                                                            />
                                                            <ThParam
                                                                label='Cantidad'
                                                                width='70px'
                                                            />
                                                            <ThParam
                                                                label='Acciones'
                                                                // onClick={() => order('estado', data, desc, setData, setDesc)}
                                                                width='75px'
                                                            />
                                                        </Tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            barrelCollects?.map((data) => (
                                                                <Tr
                                                                    key={data.id}
                                                                >
                                                                    <Td
                                                                        size='14px'
                                                                        center
                                                                    >
                                                                        {barrelCollects.length - numBarrelCollect++}
                                                                    </Td>
                                                                    <Td
                                                                        size='14px'
                                                                    >
                                                                        {data.cliente}{' '}
                                                                        {data.apellido_paterno}{' '}
                                                                        {data.apellido_materno}{' '}
                                                                    </Td>
                                                                    <Td
                                                                        size='14px'
                                                                        center
                                                                    >
                                                                        {data.producto}
                                                                    </Td>
                                                                    <Td
                                                                        size='14px'
                                                                        center
                                                                    >
                                                                        {data.cantidad_turril}
                                                                    </Td>
                                                                    <Td
                                                                        center={true}
                                                                    >
                                                                        <ButtonIcon
                                                                            icon={"bi bi-eye-fill"}
                                                                            variant="success"
                                                                            title="Ver más"
                                                                            onClick={() => setDataFuncBarrelAccount(data)}
                                                                            color='text-white'
                                                                        />
                                                                    </Td>
                                                                </Tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </TableMin>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    toggleModalCreate &&
                    <ModalCreateHistoryNote
                        show={toggleModalCreate}
                        handleClose={handleClose}
                        recallAction={recallAction}
                        cuadernoId={param.noteId}
                    />
                }
                {/* ************** */}
                {
                    toggleModalCreatePayment &&
                    <ModalCreatePayment
                        show={toggleModalCreatePayment}
                        handleClose={handleCloseCreatePayment}
                        recallAction={recallAction}
                        cuadernoId={param.noteId}
                    />
                }
                {/* ********** */}
                {toggleModalCreateExpenseNote &&
                    <ModalCreateExpenseNote
                        show={toggleModalCreateExpenseNote}
                        handleClose={handleCloseCreateExpenseNote}
                        recallAction={recallAction}
                        cuadernoId={param.noteId}
                    />
                }
                {toggleModalCreateBarrelAccount &&
                    <ModalCreateHistoryBarrelAccount
                        show={toggleModalCreateBarrelAccount}
                        handleClose={handleCloseCreateHistoryBarrelAccount}
                        recallAction={recallAction}
                        cuadernoId={param.noteId}
                    />
                }
                {
                    modalConfirmation &&
                    <ModalConfirmation
                        show={modalConfirmation}
                        handleClose={handleCloseConfirmation}
                        text='¿Seguro que desea eliminar este cliente?'
                        buttonText='Eliminar'
                        variant='danger'
                        onClick={() => deleteData()}
                        rolId={note.id}
                    />
                }
                <ModalUpdateNoteCash
                    show={noteCash}
                    handleClose={handleCloseUpdateNoteCash}
                    recallAction={recallAction}
                    data={noteData}
                />
                <ModalDetailHistoryNote
                    show={showHistoryNote}
                    handleClose={() => setShowHistoryNote(false)}
                    data={data}
                />
                <ModalDetailPayment
                    show={showPayment}
                    handleClose={() => setShowPayment(false)}
                    data={data}
                />
                <ModalDetailExpense
                    show={showExpense}
                    handleClose={() => setShowExpense(false)}
                    data={data}
                />
                <ModalDetailBarrelAccount
                    show={showBarrelAccount}
                    handleClose={() => setShowBarrelAccount(false)}
                    data={data}
                />
            </GeneralContainer>
        </>
    )
}