// import { formatInputDate, formatInputLocalDate } from "../../../utils/dateConvert";
import { ToTwoDecimal } from "../../../utils/utilities";
import moment from 'moment-timezone';

export const CompletarCuadernoValues = (currentData) => {
    // const formatInputDate = (date) => {
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const day = String(date.getDate()).padStart(2, '0');
    //     return `${year}-${month}-${day}`;
    // };

    // // Obtener la fecha estimada de devolución formateada para el input de tipo "date"
    // const fechaEstimadaDevolucion = currentData?.fecha_estimada_devolucion ?
    //     formatInputDate(new Date(currentData.fecha_estimada_devolucion)) :
    //     '';
    // console.log(fechaEstimadaDevolucion)
    const fechaEstimadaDevolucion = currentData?.fecha_estimada_devolucion ?
        moment.tz(currentData.fecha_estimada_devolucion, 'America/La_Paz').format('YYYY-MM-DD') :
        '';
    return {
        initialValues: {
            cuaderno_historial_id: currentData.id,
            cantidad: currentData?.cantidad ?? 0,
            cantidad_retorno: currentData?.cantidad_retorno ?? 0,
            cantidad_deuda: currentData?.cantidad_deuda ?? 0,
            metodo_intercambio: currentData?.metodo_intercambio ?? 'normal',
            monto_garantia: currentData?.monto_garantia ?? 0,
            // fecha_estimada_devolucion: (currentData?.fecha_estimada_devolucion && formatInputLocalDate(new Date(currentData?.fecha_estimada_devolucion))) ?? '',
            fecha_estimada_devolucion: fechaEstimadaDevolucion,
            obs: currentData?.obs ?? '',
            // 
            // encargado_id: '',
            completo: true,
        },
        validators: {},
        fields: [
            {
                label: 'Cantidad',
                name: 'cantidad',
                type: 'numberCalculator',
                subData: null,
                required: true,
                disabled: false,
                locked: false,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad = e.target.valueAsNumber || 0;

                    const cantidad_retorno = values.cantidad_retorno || 0
                    const cantidad_deuda = cantidad - cantidad_retorno
                    setValues('cantidad_deuda', cantidad_deuda)

                    const cantidad_total = values.cantidad_total || 0
                    const total = ToTwoDecimal(cantidad_total - cantidad)
                    setValues('cantidad_total_restante', total < 0 ? 0 : total)
                }
            },
            {
                label: 'Cantidad recojidos',
                name: 'cantidad_retorno',
                type: 'numberCalculator',
                subData: null,
                required: true,
                disabled: false,
                ChangeCalculator: (values, setValues, e) => {
                    const cantidad_retorno = e.target.valueAsNumber || 0;
                    const cantidad = values.cantidad || 0
                    const cantidad_deuda = cantidad - cantidad_retorno
                    setValues('cantidad_deuda', cantidad_deuda)
                },
            },
            {
                label: 'Cantidad deuda',
                name: 'cantidad_deuda',
                type: 'number',
                subData: null,
                required: true,
                disabled: true,
                highlight: 'bg-yellow-600 dark:bg-yellow-500 bg-opacity-25 dark:bg-opacity-20',
                locked: true,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    const cantidad_retorno = values.cantidad_retorno || 0
                    const cantidad = values.cantidad || 0
                    const cantidad_deuda = cantidad - cantidad_retorno
                    setValues('cantidad_deuda', cantidad_deuda)
                },
                fieldDependency: 'cantidad',
            },
            {
                label: 'Garantía / Prestamo',
                name: 'metodo_intercambio',
                type: 'selectCondition',
                required: true,
                disabled: false,
                options: [
                    {
                        label: 'Normal',
                        value: 'normal',
                        disabled: (values) => {
                            if (values.cantidad_deuda > 0)
                                return true
                        }
                    },
                    {
                        label: 'Garantía',
                        value: 'garantía',
                        disabled: (values) => {
                            if (values.cantidad_deuda <= 0)
                                return true
                        }
                    },
                    {
                        label: 'Prestamo',
                        value: 'préstamo',
                        disabled: (values) => {
                            if (values.cantidad_deuda <= 0)
                                return true
                        }
                    },
                ],
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.cantidad_deuda > 0 && values.metodo_intercambio === 'normal')
                        setValues('metodo_intercambio', 'garantía')
                    if (values.cantidad_deuda <= 0 && values.metodo_intercambio !== 'normal')
                        setValues('metodo_intercambio', 'normal')

                    // console.log(values.metodo_intercambio)
                    if (values.cantidad_deuda <= 0 && values.metodo_intercambio === 'normal')
                        setAditionalClass('d-none')
                    else
                        setAditionalClass('')
                },
                fieldDependency: 'cantidad_deuda',
                fieldDependencyExtra: 'metodo_intercambio',
            },
            {
                label: 'Monto de garantía',
                name: 'monto_garantia',
                type: 'groupnumber',
                subData: 'Bs.',
                required: false,
                disabled: false,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_intercambio === 'garantía')
                        setAditionalClass('')
                    else
                        setAditionalClass('d-none')
                },
                fieldDependency: 'metodo_intercambio',
                highlight: 'bg-indigo-600 dark:bg-indigo-500 bg-opacity-25 dark:bg-opacity-20'
            },
            {
                label: 'Fecha estimada de devolución',
                name: 'fecha_estimada_devolucion',
                type: 'date',
                disabled: false,
                ChangeCondition: (values, setValues, setAditionalClass) => {
                    if (values.metodo_intercambio === 'préstamo')
                        setAditionalClass('')
                    else
                        setAditionalClass('d-none')
                },
                fieldDependency: 'metodo_intercambio',
                highlight: 'bg-cyan-600 dark:bg-cyan-500 bg-opacity-25 dark:bg-opacity-20'
            },
            // {
            //     type: 'divider',
            // },
            // {
            //     label: 'Encargado de entrega',
            //     name: 'encargado_id',
            //     type: 'selectSearch',
            //     urlApi: `/vendedores`,
            //     required: false,
            //     labelDescription: 'nombre',
            //     placeholder: 'Encargado...',
            //     autoFocus: false,
            //     // defaultValue: { value: '2', label: 'Clientes' }
            // },
            // {
            //     label: 'Completado',
            //     name: 'completo',
            //     type: 'checkbox',
            // },
            {
                type: 'divider'
            },
            {
                label: 'Observaciones',
                name: 'obs',
                type: 'textArea',
                required: false,
                placeholder: 'Observaciones...',
                autoFocus: false
            },
        ]
    }
}