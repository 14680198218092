import { Form, Formik } from 'formik'
import React from 'react'

export default function ButtonIconSearch({
    placeholder,
    variant,
    setForQuery,
    setTypeQuery
}) {

    // useEffect(() => {
    //   resetForm()


    // }, [])

    return (
        <Formik
            initialValues={{
                query: ''
            }}

            onSubmit={(valores, { resetForm }) => {
                // console.log(valores)
                setForQuery(valores.query)
                // resetForm()
            }}

        >
            {({ values, handleChange, resetForm, setFieldValue }) => (
                <Form>
                    <div className="input-group">
                        <button
                            type='submit'
                            className={`btn btn-outline-secondary bg-${variant}`}
                            style={{
                                border:'1px solid transparent'
                            }}
                        >
                            <i className="ion-search"></i>
                        </button>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={placeholder}
                            style={{ boxShadow: 'none', fontWeight:'600' }}
                            name={'query'}
                            id='query'
                            value={values.query}
                            onChange={handleChange}
                        />
                        <button
                            type='submit'
                            className='btn btn-outline-secondary'
                            // onClick={() => resetForm()}
                            onClick={() => setFieldValue("query", "")}

                            style={{
                                borderTop: "1px solid silver",
                                borderBottom: "1px solid silver",
                                borderRight: "1px solid silver",
                                borderLeft: "none",
                                background: 'white',
                                color: 'black'
                            }}
                        >
                            <i className="ion-close-round"></i>
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
