import React from 'react'

export default function Tdbody({ position, children, fsSmall, width = '' }) {
    return (
        <td
            // className='table-danger'
            style={{
                textAlign: position,
                padding: '5px',
                fontSize: fsSmall,
                // maxWidth:'110px',
                overflow: 'hidden',
                border: '1px solid rgb(200,200,200)',
                verticalAlign: 'middle',
                // whiteSpace:'nowrap'
                // width:'40px',
                width: width,
                maxWidth: width,
                minWidth: width

            }}>{children}</td>
    )
}
