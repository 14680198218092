import React from 'react'
import { Pagination } from 'react-bootstrap'
import Select from '../form/Select'

export default function CardPagination({
  current = 1,
  last = 0,
  setCurrentPage,
  per_page,
  setPerPage
}) {

  const handleChange = (e) => {
    setPerPage(e.target.value)
  }

  return (
    <div className="row px-2">
      <div className="col col-5 d-flex justify-content-start">
        <Select
          value={10}
          title={'cantidad de registros por página'}
          onChange={handleChange}
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </Select>
      </div>
      <div className="col col-7 d-flex justify-content-end">
        <Pagination size='md' className='p-0 m-0 fw-bold'>
          <Pagination.First style={{ fontSize: '40px' }} onClick={() => setCurrentPage(1)} disabled={current <= 1 && true} />
          <Pagination.Prev onClick={() => setCurrentPage(current - 1)} disabled={current <= 1 && true} />
          <Pagination.Item active>{current}</Pagination.Item>
          <Pagination.Next onClick={() => setCurrentPage(current + 1)} disabled={last <= current && true} />
          <Pagination.Last onClick={() => setCurrentPage(last)} disabled={last <= current && true} />
        </Pagination>
      </div>
    </div>
  )
}
