import React from 'react'
import { LayerGroup, LayersControl, Marker, Popup, Tooltip } from 'react-leaflet'
import { VenueIconSuccess } from "./VenueIconSuccess";
import { VenueIconDanger } from "./VenueIconDanger";
import { VenueIconInfo } from "./VenueIconInfo";

export default function Markers({ clients }) {
    // const locations = useSelector(state => state.locationClient.db)
    function openPopup(marker) {
        if (marker && marker.leafletElement) {
            window.setTimeout(() => {
                marker.leafletElement.openPopup()
            })
        }
    }
    return (
        <>
            <LayersControl position="topright">
                <LayersControl.Overlay checked  name="Marker with popup">
                <LayerGroup>
                    {
                        clients.map((client) => (
                            client.geolocalizacion &&
                            (client.geolocalizacion.split(',').length === 2) &&
                            !isNaN(client.geolocalizacion.split(',')[0]) &&
                            !isNaN(client.geolocalizacion.split(',')[1]) &&
                            < Marker
                                position={{ lat: client.geolocalizacion.split(',')[0], lng: client.geolocalizacion.split(',')[1] }}
                                icon={
                                    client.estado === 'Nuevo' ?
                                        VenueIconDanger
                                        :
                                        (client.order ?
                                            VenueIconSuccess
                                            :
                                            VenueIconInfo
                                        )

                                }
                                key={client.id}
                                ref={openPopup}
                            >
                                {
                                    client.estado === 'Nuevo' ?
                                        <>
                                            <Popup className='fw-bold' closeOnEscapeKey keepInView >
                                                <span className='text-danger'><i className='ion-person-add h6' /> nuevo cliente</span>
                                                <br />
                                                {' ' + client.nombre + ' ' + (client.apellido_paterno ? client.apellido_paterno : '')}
                                                <br />
                                                <small>{client.telefono}</small>
                                                <br />
                                                <small>{client.tipo_negocio}</small>
                                            </Popup>
                                            <Tooltip className='fw-bold border ' direction="top" offset={[0, -7]} opacity={1}>
                                                <span className='text-danger'><i className='ion-person-add h6' /> nuevo cliente</span>
                                                <br />
                                                {' ' + client.nombre + ' ' + (client.apellido_paterno ? client.apellido_paterno : '')}
                                                {/* <br />
                                        <small>{client.tipo_negocio}</small> */}
                                            </Tooltip>
                                        </>
                                        :
                                        (client.order ?
                                            <>
                                                <Popup className='fw-bold' closeOnEscapeKey keepInView >
                                                    <span
                                                        style={{
                                                            color: 'rgb(11, 141, 135)'
                                                        }}
                                                    ><i className='ion-android-cart h6' /> pedidos pendientes</span>
                                                    <br />
                                                    {' ' + client.nombre + ' ' + (client.apellido_paterno ? client.apellido_paterno : '')}
                                                    <br />
                                                    <small>{client.telefono}</small>
                                                    <br />
                                                    <small>{client.tipo_negocio}</small>
                                                </Popup>
                                                <Tooltip className='fw-bold border ' direction="top" offset={[0, -7]} opacity={1}>
                                                    <span
                                                        style={{
                                                            color: 'rgb(11, 141, 135)'
                                                        }}
                                                    ><i className='ion-android-cart h6' /> pedidos pendientes</span>
                                                    <br />
                                                    {' ' + client.nombre + ' ' + (client.apellido_paterno ? client.apellido_paterno : '')}
                                                    {/* <br />
                                            <small>{client.tipo_negocio}</small> */}
                                                </Tooltip>
                                            </>
                                            :
                                            <>
                                                <Popup className='fw-bold' closeOnEscapeKey keepInView >
                                                    <span
                                                        style={{
                                                            color: 'rgb(29, 160, 216)'
                                                        }}
                                                    ><i className='ion-person h6' /> cliente</span>
                                                    <br />
                                                    {' ' + client.nombre + ' ' + (client.apellido_paterno ? client.apellido_paterno : '')}
                                                    <br />
                                                    <small>{client.telefono}</small>
                                                    <br />
                                                    <small>{client.tipo_negocio}</small>
                                                </Popup>
                                                <Tooltip className='fw-bold border border-light' direction="top" offset={[0, -7]} opacity={1}>
                                                    <span
                                                        style={{
                                                            color: 'rgb(29, 160, 216)'
                                                        }}
                                                    ><i className='ion-person h6' /> cliente</span>
                                                    <br />
                                                    {' ' + client.nombre + ' ' + (client.apellido_paterno ? client.apellido_paterno : '')}
                                                    {/* <br />
                                            <small>{client.tipo_negocio}</small> */}
                                                </Tooltip>
                                            </>
                                        )
                                }
                            </Marker>
                        ))
                    }
                    </LayerGroup>
                </LayersControl.Overlay>
            </LayersControl>
        </>
    )
}