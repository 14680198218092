import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export default function ModalConfirmation(
    { 
        show,
        handleClose,
        onClick,
        text,
        buttonText,
        variant,
        buttonDisabled = false    
    }
) {
    return (
        <Modal show={show} onHide={handleClose} animation={true} centered>
            {/* <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header> */}
            <Modal.Body style={{ textAlign: 'center' }}>{text}</Modal.Body>
            <div className='d-flex justify-content-center'>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button
                    variant={variant}
                    onClick={onClick}
                    disabled={buttonDisabled}
                    >
                        {buttonText}
                    </Button>
                </Modal.Footer>
            </div>
        </Modal>
    )
}
