import { Formik, Form } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ButtonSubmitSave from '../../../../components/button/ButtonSubmitSave'
import InputBeforeIcon from '../../../../components/form/InputBeforeIcon'
import Label from '../../../../components/label/Label'
import { CreateOrderApi } from '../../../../http/Services/OrderApi'
import { GetProductsApi } from '../../../../http/Services/ProductApi'
import { toastToggleAction } from '../../../../redux/action/toastAction'


export default function ModalCreateOrder({ show, handleClose, load, setLoad }) {
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const dispatch = useDispatch()
    const [products, setProducts] = useState([])

    useEffect(() => {
        const getProducts = async () => {
            let res = await GetProductsApi();
            if (res.status === 200) {
                setProducts(res.data)
            }
            else {
                console.log('no hay productos')
            }
        }
        getProducts()
    }, [])

    return (
        <Modal show={show} onHide={handleClose} className='elevation-1' size='md'>
            <Modal.Header closeButton>
                <Modal.Title className='h6 fw-bold text-secondary'>Realizar pedido</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{
                    cantidad: 1,
                    producto_id: '',
                }}

                onSubmit={(valores, { resetForm }) => {
                    setButtonDisabled(true)

                    const createOrder = async () => {
                        try {
                            let res = await CreateOrderApi(valores)
                            if (res.status === 200) {
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'success',
                                    title: 'Exito!',
                                    message: 'Creado correctamente!',
                                    variant: 'bold'
                                }))

                                resetForm()
                                setLoad(!load)
                                handleClose()
                                setButtonDisabled(false)
                            }
                            else {
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'warning',
                                    title: 'Alerta!',
                                    message: 'Los datos podrian no haberse cargado!',
                                    variant: 'bold'
                                }))
                                setButtonDisabled(false)
                            }
                        } catch (error) {
                            console.log(error)
                            dispatch(toastToggleAction({
                                show: true,
                                bg: 'danger',
                                title: 'Error!',
                                message: error.response.data.message,
                                variant: 'bold'
                            }))
                            setButtonDisabled(false)
                        }
                    }

                    createOrder()
                }}
            >
                {({ values, handleChange }) => (
                    <React.Fragment>
                        <Form>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-md-6 mb-2">
                                        <Label
                                            title='Producto'
                                        >
                                            <select
                                                className="form-select"
                                                name="producto_id"
                                                value={values.producto_id}
                                                onChange={handleChange}
                                                // style={{ boxShadow: 'none' }}
                                                disabled={buttonDisabled}
                                                required
                                            >
                                                <option value='' label='...' required />
                                                {
                                                    products.map(product => (
                                                        <option value={product.id} label={product.nombre + ', Bs. ' + product.precio} key={product.id} />

                                                    ))
                                                }
                                            </select>
                                        </Label>
                                    </div>
                                    <div className="col-md-6">
                                        <Label
                                            title='Cantidad de productos'
                                        >
                                            <InputBeforeIcon
                                                id='cantidad'
                                                placeholder='Cantidad'
                                                name='cantidad'
                                                type='number'
                                                onChange={handleChange}
                                                value={values.cantidad}
                                                disabled={buttonDisabled}
                                                required={true}
                                                min={1}
                                            />
                                        </Label>
                                    </div>
                                    <div className=" col-12 mt-3">
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-12 row d-flex justify-content-center">
                                                <div className="row table-info">
                                                    <div className="col-6"><small>Precio por unidad</small></div>
                                                    <div className="col-3  text-primary overflow-hidden">
                                                        <strong>{products.find(p => p.id + '' === values.producto_id) != null ? (products.find(p => p.id + '' === values.producto_id)).precio : 0}</strong>
                                                    </div>
                                                    <div className="col-3"><small>Bs.</small></div>
                                                </div>
                                                <div
                                                    className="row table-success py-1 d-flex"
                                                    style={{
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <div className="col-6"><small>Cantidad</small></div>
                                                    <div className="col-3  text-success overflow-hidden">
                                                        <strong>{values.cantidad}</strong>
                                                    </div>
                                                </div>
                                                <div className="row table-danger">
                                                    <div className="col-6"><small>Total</small></div>
                                                    <div className="col-3  text-dark overflow-hidden">
                                                        <strong>{(products.find(p => p.id + '' === values.producto_id) != null ? (products.find(p => p.id + '' === values.producto_id)).precio : 0) * values.cantidad}</strong>
                                                    </div>
                                                    <div className="col-3"><small>Bs.</small></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancelar
                                </Button>
                                <ButtonSubmitSave
                                    text='Guardar'
                                    color='#00bfa5'
                                    disabled={buttonDisabled}
                                />
                            </Modal.Footer>
                        </Form>
                    </React.Fragment>
                )}
            </Formik>
        </Modal >
    )
}
