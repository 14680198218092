import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../assets/sarisoya-logo.png'
import NavItem from '../../components/navBar/NavItem'

export default function Aside() {
    const user = useSelector(state => state.auth)
    const globalData = useSelector(state => state.globalData)
    const location = useLocation()
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="home" className="brand-link d-flex justify-content-start" style={{ textDecoration: 'none' }} data-widget={window.screen.width <= 991 && 'pushmenu'} >
                {/* <img src={logo} alt="logo" className="brand-image" style={{ opacity: '.8', width:'100px', height:'100px' }} /> */}
                <img src={logo} alt="logo" className="" style={{ width: '57px', height: '57px', borderRadius: '18px' }} />
                <span className="brand-image font-weight text-light mt-4">SOYA SARI</span>
            </Link>
            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    {/* <Link
                        to="profile"
                        data-widget={window.screen.width <= 991 && 'pushmenu'}
                    >
                        <div className="image">
                            <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
                            <i className="fa fa-user-circle img-circle elevation-1" style={{ fontSize: '30px' }} />
                        </div>
                    </Link>
                    <div className="info"
                        data-widget={window.screen.width <= 991 && 'pushmenu'}
                    >
                        <Link
                            style={{ textDecoration: 'none', flexWrap: true, color: location.pathname === '/dashboard/profile' && 'rgba(255,255,255,1)' }}
                            to="profile"
                            className={`text-${location.pathname === '/dashboard/profile' && 'warning'}`}
                        >
                            {user.nombre && user.nombre.toUpperCase()}
                        </Link>
                    </div> */}
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <NavItem
                            to='profile'
                            icon='fa fa-user-circle'
                            title={`${user.nombres ? user.nombres.toUpperCase() : ''}`}
                            dataWidget={window.screen.width <= 991 && 'pushmenu'}
                            bg={location.pathname === '/dashboard/profile' && true}
                        // subtitle={true}
                        />
                    </ul>
                </div>
                <nav className="mt-2 mb-5">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {
                            user?.rols.find(r => r.nombre === 'administrador') &&
                            <>
                                <NavItem
                                    to='clients'
                                    icon='ion-android-people'
                                    title='Clientes'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname.includes('/dashboard/clients') && true}
                                    badge={true}
                                    data={globalData.newClients}
                                />
                                <NavItem
                                    to='cuadernos'
                                    icon='ion-social-buffer'
                                    title='Cuadernos'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname.includes('/dashboard/cuadernos') && true}
                                    badge={true}
                                    data={globalData.newClients}
                                />
                                <NavItem
                                    to='ventas-pendientes'
                                    icon='ion-note'
                                    title='Ventas pendientes'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname.includes('/dashboard/ventas-pendientes') && true}
                                />
                                {/* <NavItem
                                    to='sellers'
                                    icon='ion-ios-people'
                                    title='Vendedores'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname === '/dashboard/sellers' && true}
                                />
                                <NavItem
                                    to='points-of-sale'
                                    icon='ion-ios-people'
                                    title='Puntos de venta'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname === '/dashboard/points-of-sale' && true}
                                />
                                <NavItem
                                    to='orders'
                                    // icon='bi bi-bag-plus-fill'
                                    // icon='ion-bag'
                                    icon='ion-android-cart'
                                    title='Pedidos'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname === '/dashboard/orders' && true}
                                    badge={true}
                                    data={globalData.orders}
                                />
                                <NavItem
                                    to='accounts'
                                    // icon='bi bi-bag-plus-fill'
                                    // icon='ion-bag'
                                    icon='ion-pricetags'
                                    title='Deudas'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname === '/dashboard/accounts' && true}
                                    badge={true}
                                    data={globalData.accounts}
                                />
                                <NavItem
                                    to='barrel-accounts'
                                    // icon='bi bi-bag-plus-fill'
                                    // icon='ion-bag'
                                    icon='ion-soup-can'
                                    title='Deuda turriles'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname === '/dashboard/barrel-accounts' && true}
                                    badge={true}
                                    data={globalData.barrelAccounts}
                                />
                                <NavItem
                                    to='notes'
                                    icon='ion-social-buffer'
                                    title='Cuadernos'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname.includes('/dashboard/notes') && true}
                                    badge={true}
                                    data={globalData.openNotes}
                                />
                                <NavItem
                                    to='warehouses'
                                    icon='ion-android-clipboard'
                                    title='Almacenes'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname.includes('/dashboard/warehouses') && true}
                                /> */}
                            </>
                        }
                        {
                            (user?.rols.find(r => r.nombre === 'ventas') || user?.rols.find(r => r.nombre === 'punto-de-ventas')) &&
                            <>
                                <NavItem
                                    to='mis-clients'
                                    icon='ion-android-people'
                                    title='Mis clientes'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname.includes('/dashboard/mis-clients') && true}
                                />
                                <NavItem
                                    to='ventas-pendientes'
                                    icon='ion-note'
                                    title='Ventas pendientes'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname.includes('/dashboard/ventas-pendientes') && true}
                                />
                            </>
                        }

                        {/* {
                            (user.departament.nombre === "Ventas" || user.departament.nombre === "Punto de Venta") &&
                            <>
                                <NavItem
                                    to='clients'
                                    icon='ion-android-people'
                                    title='Clientes'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname.includes('/dashboard/clients') && true}
                                />
                                <NavItem
                                    to='debtors'
                                    icon='bi bi-person-video2'
                                    title='Deudores'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname === '/dashboard/debtors' && true}
                                    badge={true}
                                    data={globalData.accounts}
                                />
                                <NavItem
                                    to='barrel-debtors'
                                    icon='ion-soup-can'
                                    title='Deuda turriles'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname === '/dashboard/barrel-debtors' && true}
                                    badge={true}
                                    data={globalData.barrelAccounts}
                                />
                                {
                                    user.noteId ?
                                        <NavItem
                                            to={`notes/${user.noteId}`}
                                            icon='ion-android-clipboard'
                                            title='Cuaderno diario'
                                            dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                            bg={location.pathname.includes('/dashboard/notes') && true}
                                        />
                                        :
                                        <NavItem
                                            to='home'
                                            icon='ion-android-clipboard'
                                            title='Aperturar cuaderno'
                                            dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                            bg={location.pathname === '/dashboard/home' && true}
                                        />
                                }
                                <NavItem
                                    to='warehouse'
                                    icon='bi-clipboard-check-fill'
                                    title='Almacén'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname.includes('/dashboard/warehouse') && true}
                                />
                            </>
                        }
                        {
                            // (user.departament.nombre === "Punto de Venta") &&
                            // <>
                            //     <NavItem
                            //         to='warehouse'
                            //         icon='bi-clipboard-check-fill'
                            //         title='Almacén'
                            //         dataWidget={window.screen.width <= 991 && 'pushmenu'}
                            //         bg={location.pathname.includes('/dashboard/warehouse') && true}
                            //     />
                            // </>
                        }
                        {
                            user.departament.nombre === "Clientes" &&
                            <>
                                <NavItem
                                    to='my-debts'
                                    // icon='bi bi-bag-plus-fill'
                                    // icon='ion-bag'
                                    icon='ion-pricetags'
                                    title='Mis deudas'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname === '/dashboard/my-debts' && true}
                                />
                                <NavItem
                                    to='my-barrel-debts'
                                    // icon='bi bi-bag-plus-fill'
                                    // icon='ion-bag'
                                    icon='ion-soup-can'
                                    title='Turriles a cuenta'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname === '/dashboard/my-barrel-debts' && true}
                                />
                                <NavItem
                                    to='my-orders'
                                    // icon='bi bi-bag-plus-fill'
                                    // icon='ion-bag'
                                    icon='ion-android-cart'
                                    title='Mis pedidos'
                                    dataWidget={window.screen.width <= 991 && 'pushmenu'}
                                    bg={location.pathname === '/dashboard/my-orders' && true}
                                />
                            </>
                        } */}
                    </ul>
                </nav>
            </div>
        </aside>

    )
}
