import axios from '../httpConfig'

export async function GetClientsApi() {
    const response = axios({
        url: `/users_cli/pag`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function CreateClientApi(data) {
    const response = axios({
        url: `/client`,
        method: 'post',
        data: data,
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function EditClientApi(data) {
    const response = axios({
        url: `/client`,
        method: 'put',
        data: data,
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function DeleteClientsApi(id) {
    const response = axios({
        url: `/client/${id}`,
        method: 'delete',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetClientByIdApi(id) {
    const response = axios({
        url: `/client/${id}`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetClientLocationsApi() {
    const response = axios({
        url: `/client/geolocations/list`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function ClientUploadImageApi(data) {
    const response = axios({
        url: `/userCliente/params`,
        method: 'put',
        data: data,
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function UpdateGeoClientApi(data) {
    const response = axios({
        url: `/client/geo`,
        method: 'put',
        data: data,
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}


export async function GetMyDataApi() {
    const response = axios({
        url: `/client/data/params`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetMyDebtsApi() {
    const response = axios({
        url: `/client/debt/list`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetMyBarrelDebtsApi() {
    const response = axios({
        url: `/client/barrelDebt/list`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetClientsDebtorsApi(page = 1, perPage = 10, forQuery = '', typeQuery, orderBy = "id", orderByDesc = "DESC") {
    const response = axios({
        url: `/client/debtor/list?current_page=${page}&per_page=${perPage}&typeQuery=${typeQuery}&forQuery=${forQuery}&orderBy=${orderBy}&order=${orderByDesc}`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetClientsBarrelDebtorsApi(page = 1, perPage = 10, forQuery = '', typeQuery, orderBy = "id", orderByDesc = "DESC") {
    const response = axios({
        url: `/client/barrelDebtor/list?current_page=${page}&per_page=${perPage}&typeQuery=${typeQuery}&forQuery=${forQuery}&orderBy=${orderBy}&order=${orderByDesc}`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function ChangeClientStatusApi(id, status) {
    const response = axios({
        url: `/client/${id}/status/${status}`,
        method: 'put',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetClientsByUserId(id) {
    const response = axios({
        url: `/client/user/${id}`,
        method: 'get',
        headers: {
            // 'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}