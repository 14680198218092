import React, { useEffect, useState } from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import ButtonIconSearch from '../../../../components/button/ButtonIconSearch'
import CardFooter from '../../../../components/card/CardFooter'
import Tdbody from '../../../../components/table/Tdbody'
import Thead from '../../../../components/table/Thead'
import { GetMyClientsWithOpenAccountApi } from '../../../../http/Services/Account'

export default function ModalClientAccountList({
    show,
    handleClose,
    title,
    buttonText,
    variant,
    setClient,
    client
}) {
    const [clients, setClients] = useState([])
    const [typeQuery, setTypeQuery] = useState('nombre')
    const [forQuery, setForQuery] = useState('')

    let num = 1

    useEffect(() => {
        const getClients = async () => {
            try {
                let res = await GetMyClientsWithOpenAccountApi(1, 200, forQuery, typeQuery, 'id', 'ASC');
                if (res.status === 200) {
                    setClients(res.data)
                }
            } catch (error) {

            }
        }
        getClients()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forQuery])

    const selected = (data) => {
        setClient(data)
    }
    const unSelected = () => {
        setClient(null)
        handleClose()

    }
    return (
        <Modal show={show} onHide={handleClose} animation={true} >
            <Modal.Header closeButton>
                <Modal.Title className='h6 fw-bold text-secondary'>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center' }}>
                <div className="card">
                    <div className='col-12 mb-2 pt-2'>
                        <ButtonIconSearch
                            variant="danger"
                            placeholder="buscar cliente..."
                            setTypeQuery={setTypeQuery}
                            setForQuery={setForQuery}
                            reset={true}
                        />
                    </div>
                    <Table hover size="sm" className='mb-0' striped responsive style={{ border: '4px solid #495057' }}>
                        <Thead
                            columns={[
                                ["", "30px"],
                                ["Nombre", "140px"],
                                ["Saldo", "80px"],
                                ["Limite", "80px"],
                                ["Apertura", "80px"],
                            ]}
                        />
                        <tbody>
                            {
                                clients.data &&
                                clients.data.map((data) => (
                                    <tr key={data.id} style={{ cursor: 'pointer', fontWeight: '600' }}
                                        onClick={() => selected(data)}
                                        className={client && client.id === data.id ? 'table-success fw-bold' : ''}
                                    >
                                        <Tdbody
                                            position='right'
                                            fsSmall='14px'
                                        >
                                            {clients.current_page === 1 ? num++ : (clients.current_page * 5 - 5 + num++)}

                                        </Tdbody>
                                        <Tdbody
                                            position='left'
                                            fsSmall='14px'
                                        >
                                            {data.nombre}{' '}
                                            {data.apellido_paterno}{' '}
                                            {data.apellido_materno}{' '}
                                        </Tdbody>
                                        <Tdbody
                                            position='left'
                                            fsSmall='14px'
                                        >
                                            {data.saldo}{' '} <small>Bs.</small>
                                        </Tdbody>
                                        <Tdbody
                                            position='left'
                                            fsSmall='14px'
                                        >
                                            {data.fecha_limite}
                                        </Tdbody>
                                        <Tdbody
                                            position='left'
                                            fsSmall='14px'
                                        >
                                            {data.created_at}
                                        </Tdbody>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                    <div className="card-footer p-0 d-flex justify-content-end">
                        {/* <div className="col-3">
                            <CardFooter
                                total={'Páginas: ' + (clients.last_page ? clients.last_page : 0)}
                            />
                        </div> */}
                        <div className="col-3">
                            <CardFooter
                                total={'Total: ' + (clients.total ? clients.total : 0)}
                            />
                        </div>

                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button variant="secondary" onClick={() => unSelected()} >
                    Cancelar
                </Button> */}
                {client &&
                    <Button variant="warning" onClick={() => unSelected()} >
                        Quitar
                    </Button>
                }
                <Button variant={variant} style={{ background: '#00bfa5', border: 'none' }} onClick={handleClose} disabled={client ? false : true}>
                    {buttonText}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
