import React from 'react'
import CardBasic from '../../../components/card/CardBasic'
import CardBasicMin from '../../../components/card/CardBasicMin'
import CardButton from '../../../components/card/CardButton'
import { CardDeudas } from './CardDeudas'
import { formatDefaultDate } from '../../../utils/utilities'

export default function CardSeller({ note, cardState, handleShowConfirmation }) {

    return (
        <div className="row gap-0">
            {/* {
                cardState !== 'button' && cardState !== 'show' &&
                <div className='col-12 text-center py-5'>
                    <div className="loader-lg">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            } */}
            {
                cardState === 'button' &&
                <div className="col-12 col-lg-6">
                    <CardButton
                        title='Aperturar cuaderno diario'
                        sup=''
                        description='Cuadernos diarios'
                        icon={<i className="ion ion-ios-bookmarks" />}
                        color='warning'
                        onClick={handleShowConfirmation}
                    />
                </div>
            }
            {
                // cardState === 'show' &&
                <>
                    <div className="col-12 col-md-6">
                        <CardBasic
                            h3={note?.codigo}
                            sup=''
                            description='Número de cuaderno'
                            icon={<i className="ion ion-ios-bookmarks" />}
                            // icon={<i className="ion ion-clipboard" />}
                            to={`../cuadernos/${note?.id}`}
                            color='bg-warning'
                        />
                    </div>
                    <CardDeudas
                        title='Deudas'
                        color='danger'
                        urlApi='/deudas/pag'
                        headers={[
                            {
                                label: 'Deudor',
                                value: ['deudorCodigo', 'deudorDetalles'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Detalles',
                                value: ['tipo'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Cuaderno',
                                value: ['cuaderno'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Monto inicial Bs.',
                                value: ['saldo_inicial'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Saldo Bs.',
                                value: ['saldo'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            // {
                            //     label: 'Estado',
                            //     value: ['estado'],
                            //     type: 'default',
                            //     multiLine: true,
                            //     lineBold: [0],
                            //     alignRight: true
                            // },
                            {
                                label: 'Fecha de Prestamo',
                                value: ['createdAt'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true,
                                transform: true,
                                func: formatDefaultDate
                            },
                            {
                                label: 'Fecha límite',
                                value: ['fecha_limite'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true,
                                transform: true,
                                func: formatDefaultDate
                            },
                        ]}
                    />
                    <CardDeudas
                        title='Préstamos'
                        color='primary'
                        urlApi='/deudaEnvases/préstamos/pag'
                        headers={[
                            {
                                label: 'Deudor',
                                value: ['deudorCodigo', 'deudorDetalles'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Detalles',
                                value: ['tipo'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Cuaderno',
                                value: ['cuaderno'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Monto inicial Bs.',
                                value: ['saldo_inicial'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Saldo Bs.',
                                value: ['saldo'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            // {
                            //     label: 'Estado',
                            //     value: ['estado'],
                            //     type: 'default',
                            //     multiLine: true,
                            //     lineBold: [0],
                            //     alignRight: true
                            // },
                            {
                                label: 'Fecha de Prestamo',
                                value: ['createdAt'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true,
                                transform: true,
                                func: formatDefaultDate
                            },
                            {
                                label: 'Fecha límite',
                                value: ['fecha_limite'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true,
                                transform: true,
                                func: formatDefaultDate
                            },
                        ]}
                    />
                    
                    <CardDeudas
                        title='Garantías'
                        color='yellow'
                        urlApi='/deudaEnvases/garantías/pag'
                        headers={[
                            {
                                label: 'Deudor',
                                value: ['deudorCodigo', 'deudorDetalles'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Detalles',
                                value: ['tipo'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Cuaderno',
                                value: ['cuaderno'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Monto inicial Bs.',
                                value: ['saldo_inicial'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Saldo Bs.',
                                value: ['saldo'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            // {
                            //     label: 'Estado',
                            //     value: ['estado'],
                            //     type: 'default',
                            //     multiLine: true,
                            //     lineBold: [0],
                            //     alignRight: true
                            // },
                            {
                                label: 'Fecha de Prestamo',
                                value: ['createdAt'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true,
                                transform: true,
                                func: formatDefaultDate
                            },
                            {
                                label: 'Fecha límite',
                                value: ['fecha_limite'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true,
                                transform: true,
                                func: formatDefaultDate
                            },
                        ]}
                    />

                    <div className="col-12"></div>
                    {/* <div className="col-lg-3 col-6">
                        <CardBasic
                            h3='10'
                            sup='%'
                            description='Pedidos completados'
                            icon={<i className="ion ion-bag" />}
                            // icon={<i className="ion ion-stats-bars" />}
                            to='orders'
                            color='success'
                        />
                    </div> */}
                    {/* <div className="col-lg-3 col-4">
                        <CardBasic
                            h3={note?.total_venta}
                            sup=''
                            description='Clientes registros hoy'
                            icon={<i className="ion ion-person-add" />}
                            // to='clients'
                            color='table-success'
                        />
                    </div> */}
                    <div className="col-4">
                        <CardBasicMin
                            title={note?.total_venta}
                            sup='Bs.'
                            description='Total venta'
                            icon={<i className="ion ion-android-cart" />}
                            // to='clients'
                            color='table-info'
                        />
                    </div>
                    <div className="col-4">
                        <CardBasicMin
                            title={note?.total_pagos_confirmar}
                            sup='Bs.'
                            description='Total venta (Cheque, Tranferencia)'
                            icon={<i className="ion ion-android-cart" />}
                            // to='clients'
                            color='table-secondary'
                        />
                    </div>
                    <div className="col-4">
                        <CardBasicMin
                            title={note?.total_cuaderno}
                            sup='Bs.'
                            description='Total venta (Efectivo)'
                            icon={<i className="ion ion-checkmark-circled" />}
                            // to='clients'
                            color='table-success'
                        />
                    </div>
                    <div className="col-4">
                        <CardBasicMin
                            title={note?.total_garantia}
                            sup='Bs.'
                            description='Total garantía'
                            icon={<i className="ion ion-soup-can" />}
                            // to='clients'
                            color='table-warning'
                        />
                    </div>
                    <div className="col-4">
                        <CardBasicMin
                            title={note?.total_cobranza}
                            sup='Bs.'
                            description='Total cobrado'
                            icon={<i className="ion ion-pie-graph" />}
                            // to='clients'
                            color='table-primary'
                        />
                    </div>
                    <div className="col-4">
                        <CardBasicMin
                            title={note?.total_deuda}
                            sup='Bs.'
                            description='Total deuda'
                            icon={<i className="ion ion-alert-circled" />}
                            // to='clients'
                            color='table-danger'
                        />
                    </div>
                    <div className="col-4">
                        <CardBasicMin
                            title={note?.total_gasto}
                            sup='Bs.'
                            description='Total gastos'
                            icon={<i className="ion ion-medkit" />}
                            // to='clients'
                            color='table-secondary'
                        />
                    </div>
                    {/* <div className="col-4">
                        <CardBasicMin
                            title={note?.total_turril_recogido}
                            sup=''
                            description='Total turriles recogidos'
                            icon={<i className="ion ion-soup-can" />}
                            // to='clients'
                            color='table-success'
                        />
                    </div> */}
                </>
            }
        </div>

    )
}
// https://ionic.io/ionicons/v2