// import { createStore } from "redux"
import reducer from "../reducer";
import { legacy_createStore as createStore } from 'redux'


const store = createStore(
    reducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(() => store)

export default store