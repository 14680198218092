import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ButtonIcon from '../../../../components/button/ButtonIcon'
import { Table } from '../../../../components/table/Table'
import { Td } from '../../../../components/table/Td'
import { Tr } from '../../../../components/table/Tr'
import { GetPointsOfSaleApi } from '../../../../http/Services/UserApi'
import { DateConvert } from '../../../../utils/dateConvert'
import { VerificationImageExist } from '../../../../utils/VerificationImage'
import Avatar from '../../../../assets/profile.png'
import Detail from '../../../globalParams/Detail'
import Info from '../../../globalParams/filter/Info'
import Paginator from '../../../globalParams/filter/Paginator'
import Searcher from '../../../globalParams/filter/Searcher'
import GeneralContainer from '../../../globalParams/GeneralContainer'
import { onChangeInputData, order, orderNum } from '../../../globalParams/GlobalDataFilter'
import Loading from '../../../globalParams/Loading'
import { ThParam } from '../../../globalParams/ThParam'
import ModalChangePass from '../ModalChangePass'
import ModalListClient from '../ModalListClient'


export default function Sellers() {

  const user = useSelector(state => state.auth)

  const [stateData, setStateData] = useState("loading...")
  const [load, setLoad] = useState(false)
  const [data, setData] = useState([])
  const [back, setBack] = useState([])
  const [items, setItems] = useState([])
  const [item, setItem] = useState({})
  const [perPage, setPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [total, setTotal] = useState(0)
  const [desc, setDesc] = useState(false)
  const [byQuery, setByQuery] = useState("")
  const [full, setFull] = useState(true)
  const [toggleModalPass, setToggleModalPass] = useState(false)
  const [show, setShow] = useState(false)

  useEffect(() => {
    setStateData("loading...")
    setItem({})
    const getSellers = async () => {
      try {
        let res = await GetPointsOfSaleApi();
        if (res.status === 200) {
          if (res.data.length > 0) {
            setStateData("")
            setByQuery("")
          }
          else {
            setStateData("sin registros...")
          }
          setDesc(false)
          setCurrentPage(1)
          setData(res.data)
          // setPerPage(10)
        }
      } catch (error) {
        setStateData("Error al cargar lista")
      }

    }
    getSellers()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load])


  useEffect(() => {
    setTotal(back.length)
    let div = back.length / perPage
    if (div - parseInt(div) === 0) {
      setTotalPages(div)
    }
    else {
      setTotalPages(parseInt(div) + 1)
    }
  }, [back, perPage])

  useEffect(() => {
    setCurrentPage(1)
  }, [perPage])


  useEffect(() => {
    // no replicar a = b por que se toma como si fuera el mismo
    const dataBackup = Array.from(data)
    setItems([
      ...dataBackup
        .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery))
        .splice((currentPage * perPage - perPage), perPage)
    ])
    setBack([
      ...data
        .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery))
    ])

    let div = back.length / perPage
    if (div - parseInt(div) === 0) {
      setTotalPages(div)
    }
    else {
      setTotalPages(parseInt(div) + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, currentPage, perPage])


  const onChangeInput = (e) => {
    onChangeInputData(
      setByQuery,
      e,
      data,
      currentPage,
      setCurrentPage,
      setItems,
      perPage,
      setBack,
      setStateData
    )

  }

  return (
    <GeneralContainer>
      <div className="row gx-2">
        <div className={`col-12 col-md-${full ? '12' : '6'}`}>
          {
            <>
              <div className="card card-danger card-outline">
                <div className="p-1">
                  <Searcher
                    onChangeInput={onChangeInput}
                    byQuery={byQuery}
                  />
                </div>
                <div className="p-1">
                  <Paginator
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                    setPerPage={setPerPage}
                    perPage={perPage}
                    setLoad={setLoad}
                    load={load}
                    stateData={stateData}
                    setFull={setFull}
                    full={full}
                  />
                </div>
                <div className=" p-1 table-warning rounded-top">
                  <Info
                    total={total}
                    currentPage={currentPage}
                    perPage={perPage}
                    totalPages={totalPages}
                  />
                </div>
                <div className="card-body p-1">
                  <div className="content-basic">
                    <Table>
                      <thead>
                        <Tr>
                          {/* <ThParam
                                    label='Id'
                                    onClick={() => orderNum('id')}
                                /> */}
                          <ThParam
                            label='Código'
                            onClick={() => order('email', data, desc, setData, setDesc)}
                            width='100px'
                          />
                          <ThParam
                            label='Nombre'
                            onClick={() => order('nombre', data, desc, setData, setDesc)}
                            width='120px'
                          />
                          <ThParam
                            label='Celular'
                            onClick={() => order('telefono', data, desc, setData, setDesc)}
                            width='100px'
                          />
                          {/* <ThParam
                                                        label='Departamento'
                                                        onClick={() => order('dpto', data, desc, setData, setDesc)}
                                                        width='120px'
                                                    /> */}
                          <ThParam
                            label='Estado'
                            onClick={() => order('estado', data, desc, setData, setDesc)}
                            width='65px'
                          />
                          <ThParam
                            label='Fecha de registro'
                            onClick={() => orderNum('id', data, desc, setData, setDesc)}
                            width='170px'
                          />
                          {
                            user && user.departament.nombre === "Administración" &&
                            <ThParam
                              label='Acciones'
                              // onClick={() => order('estado', data, desc, setData, setDesc)}
                              width='75px'
                            />
                          }
                        </Tr>
                      </thead>
                      <tbody>
                        {
                          stateData !== "" ?
                            <tr>
                              <td colSpan={5}>
                                {stateData === "loading..."
                                  ?
                                  <Loading />
                                  :
                                  <div
                                    style={{
                                      fontSize: '14px',
                                      fontWeight: 'bold',
                                      color: 'rgba(0,0,0,.4)',
                                      padding: '5px'
                                    }}
                                  >
                                    {stateData}
                                  </div>
                                }
                              </td>
                            </tr>
                            :
                            items?.map((data) => (
                              <Tr
                                key={data.id}
                                onClick={() => setItem(data)}
                                mark={item && item.id === data.id && true}
                              >
                                <Td
                                  width='100px'
                                >
                                  <div className="row g-0">
                                    <div className="col-12 d-flex"
                                      style={{
                                        alignItems: 'center',
                                        overflow: 'hidden'
                                      }}
                                    >
                                      <img
                                        className="p-0"
                                        src={data && data.avatar
                                          ?
                                          require(`../../../../assets/${VerificationImageExist(data.avatar)}`) : Avatar}
                                        alt="img"
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                          alignItems: 'center',
                                          textAlign: 'center',
                                          verticalAlign: 'middle'
                                        }}
                                      />
                                      <span className='pl-1'>
                                        {data.email}
                                      </span>
                                    </div>
                                  </div>
                                </Td>
                                <Td
                                  width='120px'
                                >
                                  {data.nombre}{' '}
                                  {data.apellido_paterno}{' '}
                                  {data.apellido_materno}
                                </Td>
                                <Td
                                  width='100px'
                                >
                                  {data.telefono}
                                </Td>
                                {/* <Td
                                                                    width='120px'
                                                                >
                                                                    {data.dpto}
                                                                </Td> */}
                                <Td
                                  width='65px'
                                >
                                  <div style={{
                                    fontWeight: 'bold',
                                    fontSize: '12.5px',
                                    textAlign: 'center'
                                  }}>
                                    <i className={`text-${data.estado ? (data.estado === 'Nuevo' ? 'danger' : (data.estado === 'Activo' ? 'success' : 'secondary')) : 'secondary'}`}>
                                      {data.estado}
                                    </i>
                                  </div>
                                </Td>
                                <Td
                                  width='170px'
                                >
                                  {data.created_at ? DateConvert(data.created_at) : ''}
                                </Td>
                                {
                                  user && user.departament.nombre === "Administración" &&
                                  <Td
                                    width='75px'
                                    center={true}
                                  >
                                    <ButtonIcon
                                      icon={"bi bi-people-fill"}
                                      variant="warning"
                                      title="ver lista de clientes"
                                      onClick={() => setShow(true)}
                                    />{' '}
                                    <ButtonIcon
                                      icon={"bi bi-lock-fill"}
                                      variant="secondary"
                                      title="cambiar contraseña"
                                      onClick={() => setToggleModalPass(true)}
                                    />

                                  </Td>
                                }
                              </Tr>
                            ))
                        }
                      </tbody >
                    </Table>
                  </div>
                </div>
                {
                  total > perPage && stateData !== "loading..." &&
                  <>
                    <div className=" p-1 table-warning rounded-top">
                      <Info
                        total={total}
                        currentPage={currentPage}
                        perPage={perPage}
                        totalPages={totalPages}
                      />
                    </div>
                    <div className="p-1">
                      <Paginator
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages}
                        setPerPage={setPerPage}
                        perPage={perPage}
                        setLoad={setLoad}
                        load={load}
                        stateData={stateData}
                        setFull={setFull}
                        full={full}
                        hiddenpaginator={true}
                      />
                    </div>
                  </>
                }
              </div>
            </>
          }
        </div>
        {
          item.id &&
          <div className={`col-12 col-md-${full ? '12' : '6'}`}>
            <div className="card">
              <div className='card-header p-1'>
                <div className='row'>
                  <div className='col-8'>
                    Detalles del punto de ventas
                  </div>
                  <div className="col-4 d-flex justify-content-end">
                    <div className='p-0'>
                      <i className='bi bi-x-lg btn py-0 px-1' onClick={() => setItem({})} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0">
                <div className="d-flex justify-content-end p-2">
                  {
                    user && user.departament.nombre === "Administración" &&
                    <i
                      className='bi bi-people-fill btn btn-warning ml-2 elevation-2'
                      onClick={() => setShow(true)}
                    />
                  }
                  {
                    user && user.departament.nombre === "Administración" &&
                    <i
                      className='bi bi-lock-fill btn btn-secondary ml-2 elevation-2'
                      onClick={() => setToggleModalPass(true)}
                    />
                  }
                </div>
                <Detail
                  detail={[
                    { label: 'Código', value: item.email },
                    { label: 'Nombre', value: item.nombre },
                    { label: 'Apellido paterno', value: item.apellido_paterno },
                    { label: 'Apellido materno', value: item.apellido_materno },
                    { label: 'Carnet', value: item.carnet },
                    { label: 'Telefono', value: item.telefono },
                    { label: 'Direccion', value: item.direccion ? item.direccion : '--' },
                    { label: 'Estado', value: item.estado ? item.estado : '--', color: item.estado === "Activo" ? 'table-success' : 'table-secondary' }
                  ]}
                />
              </div>
            </div>
          </div>
        }
      </div>
      <ModalChangePass
        show={toggleModalPass}
        setToggleModalPass={setToggleModalPass}
        seller={item}
      />
      <ModalListClient
        show={show}
        setShow={setShow}
        user={item}
      />
    </GeneralContainer>
  )
}