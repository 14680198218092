import React from 'react'
import { Marker, Popup, Tooltip } from 'react-leaflet'
// import { useSelector } from 'react-redux';
import { VenueLocationIcon } from "./VenueIconLocation";

export default function MarkerItem({ user }) {

    return (
        <>
            {
                user.geolocalizacion &&
                (user.geolocalizacion.split(',').length === 2) &&
                !isNaN(user.geolocalizacion.split(',')[0]) &&
                !isNaN(user.geolocalizacion.split(',')[1]) &&
                < Marker position={{ lat: user.geolocalizacion.split(',')[0], lng: user.geolocalizacion.split(',')[1] }} icon={VenueLocationIcon} key={user.id}>
                    <Popup className='fw-bold'>
                        {user.nombres + ' ' + (user.apellido_paterno ?? '') + (user.apellido_materno ?? '')}<br />
                    </Popup>
                    <Tooltip className='fw-bold'>
                        {user.nombres + ' ' + (user.apellido_paterno ?? '') + (user.apellido_materno ?? '')}<br />
                    </Tooltip>
                </Marker>
            }
        </>
    )
}