import React from 'react'

export default function ButtonSubmitSave({ text, disabled, color = '#dc3545' }) {
    return (
        <button
            type="submit"
            className="btn"
            // style={{ background: '#00bfa5', color: 'white' }}
            style={{
                background: disabled ? '#84d8cd' :color, color: 'rgb(250,250,250)',
                
            }}
            disabled={disabled}
        >
            {text}
        </button>
    )
}
