import { Formik, Form } from 'formik'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ButtonSubmitSave from '../../../components/button/ButtonSubmitSave'
import InputBeforeIcon from '../../../components/form/InputBeforeIcon'
import Label from '../../../components/label/Label'
import InputTextTarea from '../../../components/textField/InputTextTarea'
import { CreateWareHouseHistorialApi } from '../../../http/Services/HistoryWarehouseApi'
import { toastToggleAction } from '../../../redux/action/toastAction'


export default function ModalCreateHistorialWareHouseInput({ show, handleClose, load, setLoad, wareHouse }) {
    const [buttonDisabled, setButtonDisabled] = useState(false)

    const dispatch = useDispatch()

    return (
        <>
            <Modal show={show} onHide={handleClose} className='elevation-1' size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h6 fw-bold text-secondary'>Ingreso de Vacios</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        // user_id: user.value,
                        tipo: 'Ingreso',
                        cantidad: 0,
                        // codigo: '',
                        almacen_id: wareHouse.id,
                        obs: ''
                    }}

                    onSubmit={(valores, { resetForm }) => {
                        setButtonDisabled(true)
                        const createHistoryWareHouse = async () => {
                            try {
                                // valores.user_id = user.value;
                                // valores.almacen_general_id = user.value;

                                // setUser(null)
                                let res = await CreateWareHouseHistorialApi(valores)
                                if (res.status === 200) {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'success',
                                        title: 'Exito!',
                                        message: 'Creado correctamente!',
                                        variant: 'bold'
                                    }))
                                    setLoad(!load)
                                    handleClose()
                                    setButtonDisabled(false)
                                    resetForm()
                                    // confirmedAction()
                                }
                                else {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'warning',
                                        title: 'Alerta!',
                                        message: 'Los datos podrian no haberse cargado!',
                                        variant: 'bold'
                                    }))
                                    // setButtonDisabled(false)
                                }
                            } catch (error) {
                                console.log(error)
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'danger',
                                    title: 'Error al registrar venta!',
                                    message: 'Llene los datos correctamente!',
                                    variant: 'bold'
                                }))
                                setButtonDisabled(false)
                            }
                        }

                        createHistoryWareHouse();
                    }}
                >
                    {({ values, handleChange }) => (
                        <React.Fragment>
                            <Form>
                                <Modal.Body>
                                    <div className="row">
                                        {
                                            show && wareHouse.cantidad_actual <= 0 && values.tipo !== "Ingreso" &&
                                            <div className="col-12 text-center text-danger">
                                                <strong>El almacen esta vacio!</strong>
                                            </div>
                                        }
                                        <div className="col-6 mb-2">
                                            <Label
                                                title='Cantidad'
                                            >
                                                <InputBeforeIcon
                                                    id='cantidad'
                                                    placeholder='Cantidad'
                                                    name='cantidad'
                                                    type='number'
                                                    onChange={handleChange}
                                                    value={values.cantidad}
                                                    disabled={buttonDisabled}
                                                    required={true}
                                                    min={1}
                                                    max={values.tipo !== "Ingreso" ? wareHouse.cantidad_actual : ''}
                                                />
                                            </Label>
                                        </div>
                                        <div className="col-md-6">
                                            <Label
                                                title='Observaciones'
                                            >
                                                <InputTextTarea
                                                    rows={2}
                                                    onChange={handleChange}
                                                    placeholder='Observaciones...'
                                                    value={values.obs}
                                                    name='obs'
                                                    disabled={buttonDisabled}
                                                />
                                            </Label>
                                        </div>
                                        <div className=" col-12 mt-3">
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12 col-lg-6 row d-flex justify-content-center">
                                                    <div className="row table-primary">
                                                        <div className="col-8"><small>Cantidad actual</small></div>
                                                        <div className="col-4  text-primary overflow-hidden">
                                                            <strong>{wareHouse.cantidad_actual}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="row table-success py-1">
                                                        <div className="col-8"><small>Movimiento</small></div>
                                                        <div className="col-4  text-success overflow-hidden">
                                                            <strong>{values.cantidad}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="row table-danger py-1">
                                                        <div className="col-8"><small>Stock restante</small></div>
                                                        <div className="col-4  text-danger overflow-hidden">
                                                            <strong>{values.tipo === "Ingreso" ? (Number(wareHouse.cantidad_actual) + Number(values.cantidad)) : (Number(wareHouse.cantidad_actual) - Number(values.cantidad))}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancelar
                                    </Button>
                                    {/* <ButtonSubmitSave
                                        text='Registrar venta'
                                        color='#00bfa5'
                                        disabled={!buttonSubmit && values.precio_unitario !== 0 && client && values.producto_id !== "0" && values.producto_id !== 0 && ((values.cantidad - values.cantidad_vacio <= 0 && values.metodo_vacio === 'normal') || (values.cantidad - values.cantidad_vacio > 0 && values.metodo_vacio !== 'normal')) ? confirmed : true}
                                    /> */}                                    
                                    <ButtonSubmitSave
                                        text='Registrar venta'
                                         color='#00bfa5'
                                        disabled={buttonDisabled ? buttonDisabled : (values.tipo === "Ingreso" ? false : (wareHouse.cantidad_actual > 0 ? false : true))}
                                    // disabled={buttonDisabled}
                                    />
                                    {/* <input type="submit" value="asd" /> */}
                                </Modal.Footer>
                            </Form>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>
        </>
    )
}
