import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'

export default function InputBeforeIcon({
    id,
    placeholder,
    type,
    onChange,
    name,
    disabled,
    value,
    param,
    required = false,
    min,
    max,
    step
}) {
    return (
        <InputGroup>
            {param &&
                <InputGroup.Text><em><small>{param}</small></em></InputGroup.Text>
            }
            <Form.Control
                // style={{ boxShadow: 'none' }}
                id={id}
                placeholder={placeholder}
                type={type}
                onChange={onChange}
                name={name}
                value={value}
                disabled={disabled}
                required={required}
                min={min}
                max={max}
                step={step}
                className='fw-bold'
            />
        </InputGroup>
    )
}
