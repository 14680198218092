import { GET_NOTE_CURRENT } from "../type";

export const initialState = {}

export function noteReducer(state = initialState, action) {
    switch (action.type) {
        case GET_NOTE_CURRENT:
            return state = action.payload.data
        default:
            return state;
    }
}