import { Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ButtonSubmitSave from '../../../components/button/ButtonSubmitSave'
import Label from '../../../components/label/Label'
import { UpdateStateOrderApi } from '../../../http/Services/OrderApi'
import { toastToggleAction } from '../../../redux/action/toastAction'
import { recall } from '../../globalParams/GlobalDataFilter'

export default function ModalChangeOrderState(
    {
        show,
        handleClose,
        buttonDisabled = false,
        setButtonDisabled,
        order,
        load,
        setLoad
    }
) {

    const dispatch = useDispatch()
    return (
        <Modal show={show} onHide={handleClose} animation={true} centered>
            <Formik
                initialValues={{
                    estado: order.estado,
                    id: order.id
                }}

                onSubmit={(valores, { resetForm }) => {
                    setButtonDisabled(true)

                    // console.log(valores)

                    const updateOrderState = async () => {
                        try {
                            let res = await UpdateStateOrderApi(valores)
                            if (res.status === 200) {
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'success',
                                    title: 'Exito!',
                                    message: 'Creado correctamente!',
                                    variant: 'bold'
                                }))

                                recall(setLoad, load)
                                resetForm()
                                handleClose()
                                setButtonDisabled(false)
                            }
                            else {
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'warning',
                                    title: 'Alerta!',
                                    message: 'Los datos podrian no haberse cargado!',
                                    variant: 'bold'
                                }))
                                setButtonDisabled(false)
                            }
                        } catch (error) {
                            console.log(error)
                            dispatch(toastToggleAction({
                                show: true,
                                bg: 'danger',
                                title: 'Error!',
                                message: error.response.data.message,
                                variant: 'bold'
                            }))
                            setButtonDisabled(false)
                        }
                    }

                    updateOrderState()
                }}
            >
                {({ values, handleChange }) => (
                    <>
                        <Form>
                            <Modal.Body style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                                <div className="col-md-6 mb-2">
                                    <Label
                                        title='Estado del pedido'
                                    >
                                        <select
                                            className="form-select mt-3"
                                            name="estado"
                                            value={values.estado}
                                            onChange={handleChange}
                                            style={{ boxShadow: 'none' }}
                                            disabled={buttonDisabled}
                                        >
                                            <option value="pendiente" className='text-danger' >Pendiente</option>
                                            <option value="visto" className='text-primary' >Visto</option>
                                            <option value="entregado" className='text-success' >Entregado</option>
                                        </select>
                                    </Label>
                                </div>
                            </Modal.Body>
                            <div className='d-flex justify-content-center'>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancelar
                                    </Button>
                                    <ButtonSubmitSave
                                        text='Guardar'
                                        color='#00bfa5'
                                        disabled={buttonDisabled}
                                    />
                                </Modal.Footer>
                            </div>
                        </Form>
                    </>
                )}
            </Formik>
        </Modal>
    )
}
