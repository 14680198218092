const images = [
    "Abarrotes.png",
    "Restaurante.png",
    "Carniceria.png",
    "Tienda.png",
    "Puesto movil.png",
    "Kiosco.png",
    "Feria.png",
    "Empresa.png",
    "Mayorista.png",
    "Fabrica.png",
]

export function VerificationImageExist(nameImage) {
    if (images.includes(nameImage)) {
        return (nameImage)
    }
    else {
        return 'profile.png';
    }
}