import { TOAST_ICON_DISABLED, TOAST_ICON_TOGGLE } from "../type";

const initialState = {
    img: null,
    show: false,
    bg: '',
    message: "",
    title: "",
    subtitle: "",
    variant: "normal"
};

export function toastIconReducer(state = initialState, action) {
    switch (action.type) {
        case TOAST_ICON_TOGGLE:
            const { show, bg, message, title, subtitle, variant } = action.payload;
            return {
                ...state,
                show,
                bg,
                message,
                title,
                subtitle,
                variant
            }
        case TOAST_ICON_DISABLED:

            return {
                ...state,
                show: false
            }

        default:
            return state;
    }
}