export const EditarClienteForm = (prevData) => {
    let vendedores = []
    prevData?.vendedores?.forEach(u => {
        vendedores.push(u.id + '')
    });
    return (
        {
            initialValues: {
                user_id: prevData.id ?? null,
                nombres: prevData.nombres ?? '',
                apellido_paterno: prevData.apellido_paterno ?? '',
                apellido_materno: prevData.apellido_materno ?? '',
                telefono: prevData.telefono ?? '',
                zona: prevData.zona ?? '',
                ci: prevData.ci ?? '',
                tiempo_de_consumo: prevData.tiempo_de_consumo ?? 0,
                tipo_negocio: prevData.tipo_negocio ?? '',
                geolocalizacion: prevData.geolocalizacion ?? '',
                geocurrent: prevData.geocurrent ?? '',
                obs: prevData.obs ?? '',
                avatarImg: prevData.avatarImg ?? '',
                vendedores: vendedores ?? []
            },
            validators: {},
            fields: [
                {
                    label: 'Nombres',
                    name: 'nombres',
                    type: 'text',
                    required: true,
                    placeholder: 'Nombre...',
                    autoFocus: true
                },
                {
                    label: 'Apellido paterno',
                    name: 'apellido_paterno',
                    type: 'text',
                    placeholder: 'Apellido paterno...',
                    required: false
                },
                {
                    label: 'Apellido materno',
                    name: 'apellido_materno',
                    type: 'text',
                    placeholder: 'Apellido materno...',
                    required: false
                },
                {
                    label: 'Telefono',
                    name: 'telefono',
                    type: 'number',
                    placeholder: '12345678',
                    required: false
                },
                {
                    label: 'Carnet',
                    name: 'ci',
                    type: 'text',
                    placeholder: '123456lp...',
                    required: false
                },
                {
                    label: 'Zona',
                    name: 'zona',
                    type: 'text',
                    placeholder: '##',
                    required: false
                },
                {
                    label: 'Tiempo de consumo por turril',
                    name: 'tiempo_de_consumo',
                    type: 'text',
                    placeholder: '##',
                    required: false
                },
                {
                    label: 'Tipo de negocio',
                    name: 'tipo_negocio',
                    type: 'select',
                    required: false,
                    options: [
                        {
                            value: 'Abarrotes',
                            label: 'Abarrotes'
                        },
                        {
                            value: 'Restaurante',
                            label: 'Restaurante'
                        },
                        {
                            value: 'Carniceria',
                            label: 'Carniceria'
                        },
                        {
                            value: 'Tienda',
                            label: 'Tienda'
                        },
                        {
                            value: 'Puesto',
                            label: 'Puesto'
                        },
                        {
                            value: 'Kiosco',
                            label: 'Kiosco'
                        },
                        {
                            value: 'Feria',
                            label: 'Feria'
                        },
                        {
                            value: 'Empresa',
                            label: 'Empresa'
                        },
                        {
                            value: 'Mayorista',
                            label: 'Mayorista'
                        },
                        {
                            value: 'Fabrica',
                            label: 'Fabrica'
                        },
                    ],
                    default: true
                },
                {
                    label: 'Estado',
                    name: 'estado',
                    type: 'select',
                    required: false,
                    options: [
                        {
                            value: 'Nuevo',
                            label: 'Nuevo'
                        },
                        {
                            value: 'Activo',
                            label: 'Activo'
                        },
                        {
                            value: 'Inactivo',
                            label: 'Inactivo'
                        },
                    ],
                    default: false
                },
                // {
                //     label: 'Información adicional',
                //     subLabel: '(opcional)',
                //     type: 'title'
                // },
                // {
                //     label: 'Contraseña',
                //     name: 'password',
                //     type: 'password',
                //     required: true,
                //     placeholder: '******',
                // },
                // {
                //     label: 'Dirección',
                //     name: 'direccion',
                //     type: 'text',
                //     placeholder: '#',
                //     required: false
                // },
                // {
                //     label: 'Foto negocio',
                //     name: 'foto_negocio',
                //     type: 'text',
                //     placeholder: '##',
                //     required: false
                // },
                // {
                //     label: 'Telefono 2',
                //     name: 'telefono2',
                //     type: 'text',
                //     placeholder: '12345678',
                //     required: false
                // },
                // {
                //     label: 'Telefono 3',
                //     name: 'telefono3',
                //     type: 'text',
                //     placeholder: '12345678',
                //     required: false
                // },
                {
                    label: 'Asignar Vendedor',
                    name: 'vendedores',
                    type: 'checkboxes',
                    required: false,
                    urlApi: '/vendedores',
                    indexLabel: 'nombre',
                    title: 'Asignación de vendedores'
                },
                {
                    label: 'Geolocalización',
                    name: 'geolocalizacion',
                    type: 'geo',
                    placeholder: 'Geolocalización',
                    required: false
                },
            ],
        }
    )
}

export const EditarGeoClienteForm = (prevData) => {
    return (
        {
            initialValues: {
                user_id: prevData.id ?? null,
                geolocalizacion: prevData.geolocalizacion ?? '',
            },
            validators: {},
            fields: [
                {
                    label: 'Geolocalización',
                    name: 'geolocalizacion',
                    type: 'geo',
                    placeholder: 'Geolocalización',
                    required: false
                },
            ],
        }
    )
}

export const EditarPassClienteForm = (prevData) => {
    return (
        {
            initialValues: {
                user_id: prevData.id ?? null,
                password: '',
            },
            validators: {},
            fields: [
                {
                    label: 'Contraseña',
                    name: 'password',
                    type: 'password',
                    required: true,
                    placeholder: '******',
                },
            ],
        }
    )
}

export const EliminarClienteForm = (prevData) => {
    return (
        {
            initialValues: {
                user_id: prevData.id ?? null,
            },
            validators: {},
            fields: [
                {
                    label: '¿Eliminar cliente?',
                    // subLabel: '(opcional)',
                    type: 'title'
                },
            ],
        }
    )
}

export const CrearClienteForm = () => {
    let vendedores = []
    return (
        {
            initialValues: {
                user_id: null,
                nombres: '',
                apellido_paterno: '',
                apellido_materno: '',
                telefono: '',
                zona: '',
                ci: '',
                tiempo_de_consumo: 0,
                tipo_negocio: '',
                geolocalizacion: '',
                geocurrent: '',
                obs: '',
                avatarImg: '',
                vendedores: vendedores ?? []
            },
            validators: {},
            fields: [
                {
                    label: 'Nombres',
                    name: 'nombres',
                    type: 'text',
                    required: true,
                    placeholder: 'Nombre...',
                    autoFocus: true
                },
                {
                    label: 'Apellido paterno',
                    name: 'apellido_paterno',
                    type: 'text',
                    placeholder: 'Apellido paterno...',
                    required: false
                },
                {
                    label: 'Apellido materno',
                    name: 'apellido_materno',
                    type: 'text',
                    placeholder: 'Apellido materno...',
                    required: false
                },
                {
                    label: 'Telefono',
                    name: 'telefono',
                    type: 'number',
                    placeholder: '12345678',
                    required: false
                },
                {
                    label: 'Carnet',
                    name: 'ci',
                    type: 'text',
                    placeholder: '123456lp...',
                    required: false
                },
                {
                    label: 'Zona',
                    name: 'zona',
                    type: 'text',
                    placeholder: '##',
                    required: false
                },
                {
                    label: 'Tiempo de consumo por turril',
                    name: 'tiempo_de_consumo',
                    type: 'text',
                    placeholder: '##',
                    required: false
                },
                {
                    label: 'Tipo de negocio',
                    name: 'tipo_negocio',
                    type: 'select',
                    required: false,
                    options: [
                        {
                            value: 'Abarrotes',
                            label: 'Abarrotes'
                        },
                        {
                            value: 'Restaurante',
                            label: 'Restaurante'
                        },
                        {
                            value: 'Carniceria',
                            label: 'Carniceria'
                        },
                        {
                            value: 'Tienda',
                            label: 'Tienda'
                        },
                        {
                            value: 'Puesto',
                            label: 'Puesto'
                        },
                        {
                            value: 'Kiosco',
                            label: 'Kiosco'
                        },
                        {
                            value: 'Feria',
                            label: 'Feria'
                        },
                        {
                            value: 'Empresa',
                            label: 'Empresa'
                        },
                        {
                            value: 'Mayorista',
                            label: 'Mayorista'
                        },
                        {
                            value: 'Fabrica',
                            label: 'Fabrica'
                        },
                    ],
                    default: true
                },
                {
                    label: 'Estado',
                    name: 'estado',
                    type: 'select',
                    required: false,
                    options: [
                        {
                            value: 'Nuevo',
                            label: 'Nuevo'
                        },
                        {
                            value: 'Activo',
                            label: 'Activo'
                        },
                        {
                            value: 'Inactivo',
                            label: 'Inactivo'
                        },
                    ],
                    default: false
                },
                // {
                //     label: 'Información adicional',
                //     subLabel: '(opcional)',
                //     type: 'title'
                // },
                // {
                //     label: 'Contraseña',
                //     name: 'password',
                //     type: 'password',
                //     required: true,
                //     placeholder: '******',
                // },
                // {
                //     label: 'Dirección',
                //     name: 'direccion',
                //     type: 'text',
                //     placeholder: '#',
                //     required: false
                // },
                // {
                //     label: 'Foto negocio',
                //     name: 'foto_negocio',
                //     type: 'text',
                //     placeholder: '##',
                //     required: false
                // },
                // {
                //     label: 'Telefono 2',
                //     name: 'telefono2',
                //     type: 'text',
                //     placeholder: '12345678',
                //     required: false
                // },
                // {
                //     label: 'Telefono 3',
                //     name: 'telefono3',
                //     type: 'text',
                //     placeholder: '12345678',
                //     required: false
                // },
                {
                    label: 'Asignar Vendedor',
                    name: 'vendedores',
                    type: 'checkboxes',
                    required: false,
                    urlApi: '/vendedores',
                    indexLabel: 'nombre',
                    title: 'Asignación de vendedores'
                },
                {
                    label: 'Geolocalización',
                    name: 'geolocalizacion',
                    type: 'geo',
                    placeholder: 'Geolocalización',
                    required: false
                },
            ],
        }
    )
}