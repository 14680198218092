import React from 'react'

export default function Info({
    // total,
    // currentPage,
    // perPage,
    // totalPages
    params
}) {
    const { totalItems, totalPages, currentPage, pageSize } = params
    return (
        <div
            className={`p-1 table-warning`}
            style={{
                fontWeight: '600',
                fontSize: '12.5px',
                border:'1px solid gray'
            }}
        >
            <span style={{color:'rgba(0,0,0,.7)'}}>Total registros: </span><span className='text-dark fw-bold'>{totalItems}</span>{', '}
            <span style={{color:'rgba(0,0,0,.7)'}}>Cantidad por página: </span><span className='text-dark fw-bold'>{pageSize}</span>{', '}
            <span style={{color:'rgba(0,0,0,.7)'}}>Total páginas: </span><span className='text-dark fw-bold'>{totalPages}</span>{', '}
            <span style={{color:'rgba(0,0,0,.7)'}}>Página actual: </span><span className='text-dark fw-bold'>{currentPage}</span>{' '}
        </div>
    )
}
