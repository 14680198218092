import React from "react";
import { Th } from "../../components/table/Th";

export const ThParam = ({ label, onClick, width }) => {
  return (
    <Th
      onClick={onClick}
    >
      <div className='d-flex' style={{ fontWeight: '600' }}
      >
        <div className=""
        >
          {label}
        </div>
        <div
          className="px-1 ml-auto align-self-center"
          style={{
            fontSize: '8px',
            textDecoration: 'none',
            color: '#929191',
            // display: onClick ? '' : 'none'
          }}
        >
          <div className='bi bi-caret-up-fill d-flex' />
          <div className='bi bi-caret-down-fill d-flex' />
        </div>
      </div>
    </Th>
  )
}

export const SimpleThParam = ({ label, onClick, width }) => {
  return (
    <Th
      onClick={onClick}
    >
      <div className='d-flex' style={{ fontWeight: '600' }}
      >
        <div className=""
        >
          {label}
        </div>
        <div
          className="px-1 ml-auto align-self-center"
          style={{
            fontSize: '8px',
            textDecoration: 'none',
            color: '#929191',
            // display: onClick ? '' : 'none'
          }}
        >
        </div>
      </div>
    </Th>
  )
}
