import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import InputBasic from '../../../../components/form/InputBasic'
import Label from '../../../../components/label/Label'
import { DateConvert } from '../../../../utils/dateConvert'

export default function ModalDetailBarrelAccount({ show, handleClose, data }) {

    return (
        <>
            <Modal show={show} onHide={handleClose} className='elevation-1' size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h6 fw-bold text-secondary'>Detalles de los turriles recogidos</Modal.Title>
                </Modal.Header>
                <form>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <Label
                                    title='Cliente'
                                >
                                    <InputBasic
                                        type='text'
                                        value={data.cliente + ' ' + (data.apellido_paterno ? data.apellido_paterno + ' ' : '') + (data.apellido_materno ? data.apellido_materno + ' ' : '')}
                                        disabled={true}
                                    />
                                </Label>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Label
                                    title='Producto'
                                >
                                    <InputBasic
                                        type='text'
                                        value={data.producto}
                                        disabled={true}
                                    />
                                </Label>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Label
                                    title='Tipo de intercambio'
                                >
                                    <InputBasic
                                        type='text'
                                        value={data.metodo_vacio}
                                        disabled={true}
                                    />
                                </Label>
                            </div>
                            {
                                data.fecha_limite &&
                                <div className="col-md-6 mb-2">
                                    <Label
                                        title='Fecha límite'
                                    >
                                        <InputBasic
                                            type='text'
                                            value={data.fecha_limite ? DateConvert(data.fecha_limite) : ""}
                                            disabled={true}
                                        />
                                    </Label>
                                </div>
                            }
                            <div className="col-md-6 mb-2">
                                <Label
                                    title='Cantidad'
                                >
                                    <InputBasic
                                        type='number'
                                        value={data.cantidad_turril}
                                        disabled={true}
                                    />
                                </Label>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Label
                                    title='Fecha de venta'
                                >
                                    <InputBasic
                                        type='text'
                                        value={DateConvert(data.created_at)}
                                        disabled={true}
                                    />
                                </Label>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Salir
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}
