import React, { useEffect } from 'react'
import './styles/App.css'
import { Routes, Route, useNavigate } from 'react-router-dom';
import Dashboard from './views/dashboard/Dashboard'
import Home from './views/dashboard/content/Home'
import PageNotFound from './views/dashboard/PageNotFound';
import Login from './views/Auth/Login';
import Preload from './views/dashboard/Preload.js'
import Profile from './views/dashboard/profile/Profile';
import ToastBasic from './components/toast/ToastBasic';
import { ProtectedAuth, ProtectedDashboard } from './router/Protected';
import ToastIcon from './components/toast/ToastIcon';
// import ClientProfile from './views/dashboard/client/ClientProfile';
import Notes from './views/dashboard/sales/note/Notes';
import Note from './views/dashboard/sales/note/Note';
import Orders from './views/dashboard/orders/Orders';
import Debtors from './views/dashboard/sales/debtor/Debtor';
import MyDebts from './views/dashboard/clientAccount/MyDebts';
import MyBarrelDebts from './views/dashboard/clientAccount/MyBarrelDebts';
import MyOrders from './views/dashboard/clientAccount/myOrders/MyOrders';

import BarrelDebtors from './views/dashboard/sales/debtor/BarrelDebtor';
import Accounts from './views/dashboard/adminAccount/Accounts';
import BarrelAccounts from './views/dashboard/adminAccount/BarrelAccount';
import { ScrollButton } from './views/extra/ScrollButton';
import Sellers from './views/dashboard/adminAccount/Sellers';
import PointsOfSale from './views/dashboard/adminAccount/pointOfSale/PointsOfSale';
import WareHouseList from './views/dashboard/adminAccount/wareHouses/WareHouses';
import WareHouses from './views/dashboard/pointOfSaleAccount/WareHouses';
import HistoryWareHouse from './views/dashboard/pointOfSaleAccount/HistoryWareHouse';
import { useDispatch } from 'react-redux';
import { GetUserByTokenApi } from './http/Services/AuthApi.js';
import { getDataAction, logoutAction } from './redux/action/authAction.js';
import { ClientesBoard } from './views/dashboard/client/ClientesBoard.jsx';
import { MisCuadernosBoard } from './views/dashboard/misCuadernos/MisCuadernosBoard.jsx';
import { MiCuadernoBoard } from './views/dashboard/miCuaderno/MiCuadernoBoard.jsx';
import { MisClientes } from './views/dashboard/misClientes/ClientesBoard.jsx';
import { VentasPendientesBoard } from './views/dashboard/ventasPendientes/VentasPendientesBoard.jsx';

export default function App() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    // if (localStorage.getItem('token')) {
    if (sessionStorage.getItem('token')) {
      const getUserByToken = async () => {
        try {
          let res = await GetUserByTokenApi();
          if (res.status === 200) {
            dispatch(getDataAction(res))
            // dispatch(dpAction(res.data.departament.nombre))
            // window.sessionStorage.setItem('dp',)
          }
        } catch (error) {
          // console.log('demdemode')
          dispatch(logoutAction())
          navigate('/')
          // window.location = '/'
        }
      }
      getUserByToken()
    }
    else {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   // if (localStorage.getItem('token')) {
  //   if (sessionStorage.getItem('token')) {
  //     const getUserByToken = async () => {
  //       try {

  //         let res = await GetGlobalDataApi();
  //         if (res.status === 200) {
  //           dispatch(getGlobalDataAction(res.data))
  //         }
  //       } catch (error) {
  //         dispatch(logoutAction())
  //         // window.location = '/'
  //         navigate('/')
  //       }
  //     }
  //     getUserByToken()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  return (
    <>
      <Routes>
        <Route index path='/' element={<ProtectedAuth
          redirectTo='/dashboard/home'
          // isAllowed={localStorage.getItem('token')}
          isAllowed={sessionStorage.getItem('token')}
        >
          <Login />
        </ProtectedAuth>
        }
        />
        {/* <Route element={<ProtectedDashboard isAllowed={localStorage.getItem('token')} />}> */}
        <Route element={<ProtectedDashboard isAllowed={sessionStorage.getItem('token')} />}>
          <Route path='/dashboard/*' element={<Dashboard />} >
            <Route path='home' index element={<Home />} />
            <Route path='profile' element={<Profile />} />
            <Route path='clients/*' element={<ClientesBoard />} />
            <Route path='mis-clients/*' element={<MisClientes />} />
            {/* <Route path='cliente/:clientId/*' element={<ClientProfile />} />
            </Route> */}
            <Route path='cuadernos' element={<MisCuadernosBoard />} />
            <Route path='cuadernos/:cuaderno_id' element={<MiCuadernoBoard />} />
            <Route path='ventas-pendientes' element={<VentasPendientesBoard />} />
            {/* <Route path='mis-cuadernos/:cuaderno_id' element={<MiCuadernoBoard />} /> */}
            <Route path='debtors' element={<Debtors />} />
            <Route path='barrel-debtors' element={<BarrelDebtors />} />
            {/* <Route path='clients/:clientId' element={<ClientProfile />} /> */}
            <Route path='sellers' element={<Sellers />} />
            <Route path='points-of-sale' element={<PointsOfSale />} />
            <Route path='orders' element={<Orders />} />
            <Route path='accounts' element={<Accounts />} />
            <Route path='barrel-accounts' element={<BarrelAccounts />} />
            <Route path='notes' element={<Notes />} />
            <Route path='notes/:noteId' element={<Note />} />
            <Route path='my-debts' element={<MyDebts />} />
            <Route path='my-barrel-debts' element={<MyBarrelDebts />} />
            <Route path='my-orders' element={<MyOrders />} />
            <Route path='warehouse' element={<WareHouses />} />
            <Route path='warehouses' element={<WareHouseList />} />
            {/* <Route path='warehouse/:wareHouseId' element={<WareHouse />} /> */}
            <Route path='warehouse/:wareHouseId' element={<HistoryWareHouse />} />
            <Route path='*' element={<PageNotFound />} />
          </Route>
        </Route>
      </Routes>
      <ToastBasic />
      <ToastIcon />
      <Preload />
      <ScrollButton />
    </>
  )
}
