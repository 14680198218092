import React from 'react'
import Aside from './Aside'
import Content from './content/Content'
// import Footer from './Footer'
import Header from './Header'

export default function Dashboard() {
    return (
        <div>
            <Header />
            <Aside />
            <Content />
            {/* <Footer /> */}
        </div>
    )
}
