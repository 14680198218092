import React, { useState } from 'react'
import { TableContainer } from './TableContainer'
import Searcher from '../../views/globalParams/filter/Searcher'
import Paginator from '../../views/globalParams/filter/Paginator'

export const DefaultTable = ({ data, setData, headers, fw, showSearch = true }) => {
    const [fullwidth, setFullWidth] = useState(fw ?? false)
    return (
        <div className={`col-12 col-md-${fullwidth ? '12' : '6'} mb-1`}>
            {
                showSearch &&
                < Searcher
                    data={{ filters: data?.filters, params: data?.params }}
                    setData={setData}
                />
            }
            <Paginator
                params={{ totalPages: data?.params?.totalPages, currentPage: data?.params?.currentPage, pageSize: data?.params?.pageSize, }}
                setData={setData}
                setFullWidth={setFullWidth}
                fullwidth={fullwidth}
            />
            <TableContainer
                headers={headers}
                data={data}
                setData={setData}
            />
        </div>
    )
}
