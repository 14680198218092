export const LOGIN = "LOGIN"
export const SET_DEP = "SET_DEP"
export const LOGOUT = "LOGOUT"
export const CREATE_LOCATION_CLIENT = "CREATE_LOCATION_CLIENT"
export const TOAST_TOGGLE = "TOAST_TOGGLE"
export const TOAST_DISABLED = "TOAST_DISABLED"
export const GET_DATA = "GET_DATA"
export const TOAST_ICON_TOGGLE = "TOAST_ICON_TOGGLE"
export const TOAST_ICON_DISABLED = "TOAST_ICON_DISABLED"
export const GET_NOTE_CURRENT = "GET_NOTE_CURRENT"
export const NOTE_ASSIGNED = "NOTE_ASSIGNED"
export const GET_GLOBAL_DATA = "GET_GLOBAL_DATA"
export const RECALL_GLOBAL_DATA = "RECALL_GLOBAL_DATA"

//user
export const SET_SELLERS = "SET_SELLERS"
export const ORDER_SELLERS = "ORDER_SELLERS"
export const ORDER_DESC_SELLERS = "ORDER_DESC_SELLERS"

//general recall
export const RECALL1 = "RECALL1"
export const RECALL2 = "RECALL2"
