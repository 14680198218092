import React from 'react'
import styled from 'styled-components';

const TableBasic = styled.table`
    border-collapse: collapse;
    width: 100%;
    border: 4px solid #495057;
    min-width: 600px;
    font-weight: 600;
    padding:0px,
    /* :nth-child(even){
        background: rgba(0,0,0,1);
    } */
    margin:3px 0px;
    `;

export const Table = ({ children }) => {
    return (
        <TableBasic>
            {children}
        </TableBasic>
    )
}
