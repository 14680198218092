import React, { useEffect, useState } from 'react'
import CardBasic from '../../../components/card/CardBasic'
import { GetMyDataApi } from '../../../http/Services/ClientApi'

export default function CardClient() {

    const [param, setParam] = useState(
        {
            total_deuda: 0,
            barrel_borrowed: 0,
            barrel_warranty: 0,
            orders: '(sin pedidos)'
        }
    )
    useEffect(() => {
        const getMyData = async () => {
            try {
                let res = await GetMyDataApi()
                if (res.status === 200) {
                    setParam(res.data)
                }
            } catch (error) {
                console.log("error al listar parametros de cliente")
            }
        }
        getMyData()
    }, [])

    return (
        <div className="row gx-3">

            <div className="col-md-6">
                <CardBasic
                    h3={param.total_deuda}
                    sup='Bs.'
                    description='Total deuda pendiente'
                    icon={<i className="ion ion-pricetags" />}
                    // icon={<i className="ion ion-clipboard" />}
                    to={`my-debts`}
                    color='bg-info'
                />
            </div>
            <div className="col-md-6">
                <CardBasic
                    h3={param.barrel_borrowed}
                    sup=''
                    description='Turriles prestados'
                    icon={<i className="ion ion-soup-can" />}
                    // icon={<i className="ion ion-clipboard" />}
                    to={`my-debts`}
                    color='bg-danger'
                />
            </div>
            <div className="col-md-6">
                <CardBasic
                    h3={param.barrel_warranty}
                    sup=''
                    description='Turriles en garantía'
                    icon={<i className="ion ion-soup-can" />}
                    // icon={<i className="ion ion-clipboard" />}
                    to={`my-debts`}
                    color='bg-success'
                />
            </div>
            {/* <div className="col-md-6">
                <CardBasic
                    h3={param.orders}
                    sup=''
                    description='Estado de último pedido'
                    icon={<i className="ion ion-bag" />}
                    // icon={<i className="ion ion-clipboard" />}
                    to={`my-orders`}
                    color='bg-warning'
                />
            </div> */}
        </div>
    )
}
// https://ionic.io/ionicons/v2
// https://ionic.io/ionicons/v2
// https://ionic.io/ionicons/v2
// https://ionic.io/ionicons/v2
// https://ionic.io/ionicons/v2
// https://ionic.io/ionicons/v2
// https://ionic.io/ionicons/v2
// https://ionic.io/ionicons/v2
// https://ionic.io/ionicons/v2
// https://ionic.io/ionicons/v2
// https://ionic.io/ionicons/v2