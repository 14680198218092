import { Formik, Form } from 'formik'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ButtonSubmitSave from '../../../components/button/ButtonSubmitSave'
import CheckBoxBasic from '../../../components/checkBox/CheckBoxBasic'
import Label from '../../../components/label/Label'
import InputTextBasic from '../../../components/textField/InputTextBasic'
import { ChangeUserPassApi } from '../../../http/Services/AuthApi'
import { toastToggleAction } from '../../../redux/action/toastAction'

export default function ModalChangePass({
    show,
    setToggleModalPass,
    seller
}) {
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const dispatch = useDispatch()
    const [showPass, setShowPass] = useState(false)
    const handleShowPass = () => {
        setShowPass(!showPass)
    }
    const handleClose = () => {
        setToggleModalPass(false)
    }

    return (
        <Modal show={show} onHide={handleClose} className='elevation-1' size='md'>
            <Modal.Header closeButton>
                <Modal.Title className='h6 fw-bold text-secondary'>Cambiar contraseña</Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-center'>
                <b>{seller.nombre + ' ' + seller.apellido_paterno + ' ' + (seller.apellido_materno ? seller.apellido_materno : '')}</b>
            </Modal.Body>
            <Formik
                initialValues={{
                    id: seller.id,
                    password: ''
                }}

                onSubmit={(valores, { resetForm }) => {
                    setButtonDisabled(true)
                    // console.log(valores)
                    const createClient = async () => {
                        try {
                            let res = await ChangeUserPassApi(valores)
                            if (res.status === 200) {
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'success',
                                    title: 'Exito!',
                                    message: 'Contraseña renovada correctamente!',
                                    variant: 'bold'
                                }))
                                resetForm()
                                // recallAction()
                                handleClose()
                                setButtonDisabled(false)
                            }
                            else {
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'warning',
                                    title: 'Alerta!',
                                    message: 'Los datos podrian no haberse guardado!',
                                    variant: 'bold'
                                }))
                                setButtonDisabled(false)
                            }
                            // console.log(valores)
                        } catch (error) {
                            dispatch(toastToggleAction({
                                show: true,
                                bg: 'danger',
                                title: 'Error!',
                                message: 'Error al renovar contraseña cliente!',
                                variant: 'bold'
                            }))
                            setButtonDisabled(false)
                        }
                    }

                    createClient();
                }}
            >
                {({ values, handleChange }) => (
                    <React.Fragment>
                        <Form>
                            <Modal.Body>
                                <div className="row">
                                    <Label
                                        title={'Nueva contraseña'}
                                    >
                                        <InputTextBasic
                                            id='password'
                                            placeholder='Nueva contraseña'
                                            name='password'
                                            icon={<ion-icon name="key"></ion-icon>}
                                            type={showPass ? 'text' : 'password'}
                                            onChange={handleChange}
                                            value={values.password}
                                            disabled={buttonDisabled}
                                            // pattern='[A-Za-z0-9]{4,8}'
                                            minLength={4}
                                            maxLength={8}
                                        />
                                    </Label>
                                    <div className="row">
                                        <CheckBoxBasic
                                            id='activo'
                                            name='activo'
                                            label='Mostrar contraseña'
                                            onChange={handleShowPass}
                                            value={showPass}
                                            disabled={buttonDisabled}
                                        />
                                    </div>

                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancelar
                                </Button>
                                <ButtonSubmitSave
                                    text='Guardar'
                                    // color='#df5312'
                                    disabled={buttonDisabled}
                                />
                                {/* <Button variant="primary" onClick={handleClose}>
                                    Cancelar
                                </Button> */}
                            </Modal.Footer>
                        </Form>
                    </React.Fragment>
                )}
            </Formik>
        </Modal>
    )
}
