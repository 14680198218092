import { Navigate, Outlet } from "react-router-dom";
import React from 'react';

export const ProtectedDashboard = ({
    isAllowed,
    redirectTo = "/",
    children,
}) => {
    if (!isAllowed) {
        return <Navigate to={redirectTo} replace />;
    }

    return children ? children : <Outlet />;
};

export const ProtectedAuth = ({
    isAllowed,
    redirectTo = "/dashboard/home",
    children,
}) => {
    if (isAllowed) {
        return <Navigate to={redirectTo} replace />;
    }

    return children ? children : <Outlet />;
};