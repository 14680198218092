import React from 'react'

export default function GeneralContainer({ children }) {
    return (
        <section className='content p-0 mb-4' >
            <div className='container-fluid'>
                <div className="row gx-3">
                    {children}
                </div>
            </div>
        </section>
    )
}
