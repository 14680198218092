import { Formik, Form } from 'formik'
import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ButtonSubmitSave from '../../../../components/button/ButtonSubmitSave'
import InputBeforeIcon from '../../../../components/form/InputBeforeIcon'
import Label from '../../../../components/label/Label'
import { toastToggleAction } from '../../../../redux/action/toastAction'
import { UpdateCashNoteApi } from '../../../../http/Services/NoteApi'

export default function ModalUpdateNoteCash({ show, handleClose, recallAction, data }) {
    const [buttonDisabled, setButtonDisabled] = useState(false)

    const dispatch = useDispatch()

    return (
        <>
            <Modal show={show} onHide={handleClose} className='elevation-1' size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h6 fw-bold text-secondary'>Registrar efectivo</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        // user_id: user.value,
                        cuaderno_id: data.id,
                        moneda: data.moneda,
                        billete: data.billete,
                        dolar: data.dolar,
                        faltante: data.faltante,
                        tipo_cambio: data.tipo_cambio ? data.tipo_cambio : 0
                    }}

                    onSubmit={(valores, { resetForm }) => {
                        // console.log(valores)
                        // setButtonDisabled(true)
                        setButtonDisabled(true)
                        // setButtonSubmit(true)
                        // setButtonSubmit(false)
                        // confirmedAction()
                        const createHistoryNote = async () => {
                            try {
                                // console.log(valores)
                                let res = await UpdateCashNoteApi(valores)
                                if (res.status === 200) {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'success',
                                        title: 'Exito!',
                                        message: 'Modificado correctamente!',
                                        variant: 'bold'
                                    }))
                                    recallAction()
                                    handleClose()
                                    setButtonDisabled(false)
                                    resetForm()
                                    // confirmedAction()
                                }
                                else {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'warning',
                                        title: 'Alerta!',
                                        message: 'Los datos podrian no haberse cargado!',
                                        variant: 'bold'
                                    }))
                                    // setButtonDisabled(false)
                                }
                            } catch (error) {
                                console.log(error)
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'danger',
                                    title: 'Error al registrar venta!',
                                    message: 'Llene los datos correctamente!',
                                    variant: 'bold'
                                }))
                                setButtonDisabled(false)
                            }
                        }

                        createHistoryNote();
                    }}
                >
                    {({ values, handleChange, handleBlur }) => (
                        <React.Fragment>
                            <Form>
                                <Modal.Body className=''>
                                    <div className="row gx-2">
                                        <div className="col-6 mb-2">
                                            <Label
                                                title='Monedas'
                                            >
                                                <InputBeforeIcon
                                                    id='moneda'
                                                    placeholder='moneda'
                                                    name='moneda'
                                                    type='number'
                                                    onChange={handleChange}
                                                    value={values.moneda}
                                                    disabled={buttonDisabled}
                                                    required={true}
                                                    min={0}
                                                    step={0.1}
                                                    param='Bs.'
                                                />
                                            </Label>
                                        </div>
                                        <div className="col-6 mb-2">
                                            <Label
                                                title='Billetes'
                                            >
                                                <InputBeforeIcon
                                                    id='billete'
                                                    placeholder='billete'
                                                    name='billete'
                                                    type='number'
                                                    onChange={handleChange}
                                                    value={values.billete}
                                                    disabled={buttonDisabled}
                                                    required={true}
                                                    min={0}
                                                    step={0.1}
                                                    param='Bs.'
                                                />
                                            </Label>
                                        </div>
                                        <div className="col-6 mb-2">
                                            <Label
                                                title='Dolares'
                                            >
                                                <InputBeforeIcon
                                                    id='dolar'
                                                    placeholder='dolar'
                                                    name='dolar'
                                                    type='number'
                                                    onChange={handleChange}
                                                    value={values.dolar}
                                                    disabled={buttonDisabled}
                                                    required={true}
                                                    min={0}
                                                    step={0.1}
                                                    param='$us.'
                                                />
                                            </Label>
                                        </div>
                                        <div className="col-6 mb-2">
                                            <Label
                                                title='Faltantes'
                                            >
                                                <InputBeforeIcon
                                                    id='faltante'
                                                    placeholder='faltante'
                                                    name='faltante'
                                                    type='number'
                                                    onChange={handleChange}
                                                    value={values.faltante}
                                                    disabled={buttonDisabled}
                                                    required={true}
                                                    min={0}
                                                    step={0.1}
                                                    param='Bs.'
                                                />
                                            </Label>
                                        </div>
                                        <div className="col-6 mb-2">
                                            <Label
                                                title='Tipo de cambio'
                                            >
                                                <InputBeforeIcon
                                                    id='tipo_cambio'
                                                    placeholder='tipo_cambio'
                                                    name='tipo_cambio'
                                                    type='number'
                                                    onChange={handleChange}
                                                    value={values.tipo_cambio}
                                                    disabled={buttonDisabled}
                                                    required={true}
                                                    min={0}
                                                    step={0.01}
                                                    param='Bs.'
                                                />
                                            </Label>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancelar
                                    </Button>
                                    <ButtonSubmitSave
                                        text='Registrar efectivo'
                                        color='#00bfa5'
                                        disabled={buttonDisabled}
                                    />
                                </Modal.Footer>
                            </Form>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>
        </>
    )
}
