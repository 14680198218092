import React, { useState } from 'react'
import { DefaultTab } from '../../../components/tab/DefaultTab'
import ClientProfile from './ClientProfile'
import { ModalForm } from '../../../components/modal/ModalForm'
import { EditarClienteForm, EditarGeoClienteForm, EditarPassClienteForm, EliminarClienteForm } from './ClienteTabFormDatas'
import ClientImageModalCreate from './ClientImageModalCreate'

export const ClienteTab = ({ data, setData }) => {
    const [dataCliente, setDataCliente] = useState({
        data: null,
        recall: true,
        // nav: true,
        // navParam: '',
        navRef: 'id',
        loading: false,
        subId: 'clientId',
        filters: ['nombres', 'codigo'],
        tabIndex: 'Perfíl',
        titleCard: 'Detalles del cliente',
        tabs: ['Perfíl', 'Deudas', 'Prestamos', 'Garantías'],
        urlApi: `/users_cli/`,
        modalEditarCliente: false,
        modalEditarFotoNegocioCliente: false,
        modalEditarGeoCliente: false,
        modalEditarPassCliente: false,
        modalEliminarCliente: false,
        buttons: [
            // {
            //     className: 'info bi bi-eye-fill',
            //     onClick: () => console.log("location")
            // },
            {
                className: 'warning bi bi-pencil-square',
                onClick: () => setDataCliente(x => ({ ...x, modalEditarCliente: true }))
            },
            {
                className: 'primary bi bi-geo-alt-fill',
                onClick: () => setDataCliente(x => ({ ...x, modalEditarGeoCliente: true }))
            },
            {
                className: 'success bi bi-image-fill',
                onClick: () => setDataCliente(x => ({ ...x, modalEditarFotoNegocioCliente: true }))
            },
            {
                className: 'secondary bi bi-lock-fill',
                onClick: () => setDataCliente(x => ({ ...x, modalEditarPassCliente: true }))
            },
            {
                className: 'danger bi bi-trash-fill',
                onClick: () => setDataCliente(x => ({ ...x, modalEliminarCliente: true }))
            },
        ]
    })
    return (
        <>
            <DefaultTab
                data={data}
                setData={setData}
                setItem={setDataCliente}
                item={dataCliente}
            >
                {
                    dataCliente.tabIndex === 'Perfíl' && data.selected &&
                    <ClientProfile
                        data={dataCliente.data}
                        loading={dataCliente.loading}
                    />
                }
                {
                    dataCliente.tabIndex === 'Deudas' &&
                    <div>
                        hola deudas
                    </div>
                }
            </DefaultTab>
            {
                dataCliente.modalEditarCliente &&
                <ModalForm
                    show={dataCliente.modalEditarCliente}
                    onHide={() => setDataCliente(x => ({ ...x, modalEditarCliente: false }))}
                    urlApi={'/userCliente'}
                    method='put'
                    formData={EditarClienteForm(dataCliente.data)}
                />
            }
            {
                dataCliente.modalEditarGeoCliente &&
                <ModalForm
                    show={dataCliente.modalEditarGeoCliente}
                    onHide={() => setDataCliente(x => ({ ...x, modalEditarGeoCliente: false }))}
                    urlApi={'/userCliente/params'}
                    method='put'
                    formData={EditarGeoClienteForm(dataCliente.data)}
                />
            }
            <ClientImageModalCreate
                id={dataCliente?.data?.id}
                open={dataCliente.modalEditarFotoNegocioCliente}
                openModalImageCreate={() => setDataCliente(x => ({ ...x, modalEditarFotoNegocioCliente: false }))}
            />
            {
                dataCliente.modalEditarPassCliente &&
                <ModalForm
                    show={dataCliente.modalEditarPassCliente}
                    onHide={() => setDataCliente(x => ({ ...x, modalEditarPassCliente: false }))}
                    urlApi={'/userCliente/params'}
                    method='put'
                    formData={EditarPassClienteForm(dataCliente.data)}
                />
            }
            {
                dataCliente.modalEliminarCliente &&
                <ModalForm
                    show={dataCliente.modalEliminarCliente}
                    onHide={() => setDataCliente(x => ({ ...x, modalEliminarCliente: false }))}
                    urlApi={'/userCliente'}
                    method='delete'
                    formData={EliminarClienteForm(dataCliente.data)}
                    back={true}
                />
            }
        </>
    )
}
