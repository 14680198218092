import React from 'react'
import styled from 'styled-components'

const TrBasic = styled.tr`
    /* :nth-child(even){ */
    :nth-child(odd){
        background: rgb(231, 231, 231);
    }
    :hover{
        background: rgb(214, 214, 214);
    }
`;
export const Tr = ({ children, onClick, mark, color = '' }) => {
    return (
        <TrBasic
            onClick={onClick}
            style={{
                cursor: onClick ? 'pointer' : '',
                // fontSize:"14px"
                fontSize: mark ? '14px' : '15px',
                color: color
            }}

            className={`${mark ? 'table-success fw-bold' : ''}`}
        >
            {children}
        </TrBasic>
    )
}
