import React from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { toastIconDisabledAction } from '../../redux/action/toastIconAction';

export default function ToastIcon() {
    const dispatch = useDispatch();
    const toast = useSelector(state => state.toastIcon)
    return (
        <ToastContainer className="p-3" position={'middle-center'}>
            <Toast
                className="d-inline-block m-1"
            // bg={toast.bg}
            onClose={() => dispatch(toastIconDisabledAction())} show={toast.show} delay={3000} autohide
            >
                <Toast.Body className=''>
                    {/* <span className="me-auto"> */}
                        <i className="bi bi-check-circle-fill text-success d-flex justify-content-center"
                        style={{
                            fontSize:'100px'
                        }}
                        ></i>
                    {/* </span> */}
                    {/* Exito! */}
                </Toast.Body>
            </Toast>
        </ToastContainer>
    )
}
