import React, { useEffect, useState } from 'react'
import GeneralContainer from '../../globalParams/GeneralContainer'
import { RequestAuthPaginated } from '../../../http/httpRequest'
import { DefaultTable } from '../../../components/table/DefaultTable'
import { useDispatch, useSelector } from 'react-redux'
import { Routes } from 'react-router-dom'
import { Route } from 'react-router-dom'
import { ClienteTab } from './ClienteTab'
import { ModalForm } from '../../../components/modal/ModalForm'
import { EditarClienteForm, EliminarClienteForm } from './ClienteTabFormDatas'
import { ClienteTabGeneralButtons } from './ClienteTabGeneralButtons'

export const ClientesBoard = () => {
    const dispatch = useDispatch();
    const globalParams = useSelector(state => state.globalParams)
    const [dataClientes, setDataClientes] = useState({
        data: [],
        recall: true,
        nav: true,
        navParam: '',
        navRef: 'id',
        loading: false,
        subId: 'clientId',
        params: {
            totalItems: 0,
            totalPages: 1,
            currentPage: 1,
            pageSize: 25,
            // nombres: ''
            filterBy: 'nombres',
            filterParam: ''
        },
        filters: ['nombres', 'codigo'],
        selected: null,
        modalCrearCliente: false,
        modalEditarCliente: false,
        modalEliminarCliente: false
    })
    const getClientes = async () => {
        await RequestAuthPaginated(
            'get',
            '/users_cli/pag',
            setDataClientes,
            'data',
            dispatch,
            dataClientes.params
        )
    }

    useEffect(() => {
        getClientes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataClientes.recall, dataClientes?.params?.pageSize, dataClientes?.params?.currentPage, dataClientes.params?.nombres, dataClientes.params?.filterParam, dataClientes.params?.filterBy, globalParams.recall1])

    return (
        <GeneralContainer>
            <ClienteTabGeneralButtons
                setDataClientes={setDataClientes}
                modals={{ modalCrearCliente: dataClientes.modalCrearCliente }}
            />
            <DefaultTable
                data={dataClientes}
                setData={setDataClientes}
                headers={[
                    {
                        label: 'Código',
                        value: ['codigo'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                        alignRight: true
                    },
                    {
                        label: 'Nombres',
                        value: ['nombres', 'apellido_paterno'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                        avatar: 'avatar'
                    },
                    {
                        label: 'Telefono',
                        value: ['telefono'],
                        type: 'default',
                        multiLine: true,
                        lineBold: [0],
                    },
                    {
                        label: 'Encargados',
                        value: ['vendedores'],
                        type: 'list',
                        items: ['nombres'],
                        separator: ' ',
                    },
                    {
                        label: 'Estado',
                        value: 'estado',
                        type: 'tag-simple',
                    },
                    {
                        label: 'Fecha de registro',
                        value: 'createdAt',
                        type: 'date',
                        funcTransform: ''
                    },
                    {
                        label: 'Acciones',
                        type: 'acciones',
                        actions: [
                            {
                                adCNButton: 'warning',
                                adCNIcon: 'pen-to-square',
                                title: 'Editar',
                                func: (item) => setDataClientes(x => ({ ...x, modalEditarCliente: true })),
                                // disabledFunc: (item) => {
                                //     if (item.estado === 'inactivo')
                                //         return true
                                //     return false
                                // }
                            },
                            {
                                adCNButton: 'danger',
                                adCNIcon: 'trash',
                                title: 'Editar',
                                func: () => setDataClientes(x => ({ ...x, modalEliminarCliente: true })),
                            },
                        ]
                    }
                ]}
            />
            <Routes>
                <Route
                    path=':clientId/*'
                    element={
                        <ClienteTab
                            setData={setDataClientes}
                            data={{ recall: dataClientes?.recall, selected: dataClientes?.selected, subId: dataClientes?.subId }}
                        />
                    } />
            </Routes>
            {
                dataClientes.modalEditarCliente &&
                <ModalForm
                    show={dataClientes.modalEditarCliente}
                    onHide={() => setDataClientes(x => ({ ...x, modalEditarCliente: false }))}
                    urlApi={'/userCliente'}
                    method='put'
                    formData={EditarClienteForm(dataClientes.selected)}
                />
            }
            {
                dataClientes.modalEliminarCliente &&
                <ModalForm
                    show={dataClientes.modalEliminarCliente}
                    onHide={() => setDataClientes(x => ({ ...x, modalEliminarCliente: false }))}
                    urlApi={'/userCliente'}
                    method='delete'
                    formData={EliminarClienteForm(dataClientes.selected)}
                    back={true}
                />
            }
        </GeneralContainer>
    )
}
