import React from 'react'
import CardBasic from '../../../components/card/CardBasic'
import CardBasicMin from '../../../components/card/CardBasicMin'
import CardButton from '../../../components/card/CardButton'
import { CardDeudas } from './CardDeudas'
import { formatDefaultDate } from '../../../utils/utilities'

export default function CardAdmin({ note, cardState, handleShowConfirmation }) {

    return (
        <div className="row gap-0">
            {
                <>
                    <CardDeudas
                        title='Deudas'
                        color='danger'
                        urlApi='/deudas/pag'
                        headers={[
                            {
                                label: 'Deudor',
                                value: ['deudorCodigo', 'deudorDetalles'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Detalles',
                                value: ['tipo'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Cuaderno',
                                value: ['cuaderno'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Monto inicial Bs.',
                                value: ['saldo_inicial'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Saldo Bs.',
                                value: ['saldo'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            // {
                            //     label: 'Estado',
                            //     value: ['estado'],
                            //     type: 'default',
                            //     multiLine: true,
                            //     lineBold: [0],
                            //     alignRight: true
                            // },
                            {
                                label: 'Fecha de Prestamo',
                                value: ['createdAt'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true,
                                transform: true,
                                func: formatDefaultDate
                            },
                            {
                                label: 'Fecha límite',
                                value: ['fecha_limite'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true,
                                transform: true,
                                func: formatDefaultDate
                            },
                        ]}
                    />
                    <CardDeudas
                        title='Préstamos'
                        color='primary'
                        urlApi='/deudaEnvases/préstamos/pag'
                        headers={[
                            {
                                label: 'Deudor',
                                value: ['deudorCodigo', 'deudorDetalles'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Detalles',
                                value: ['tipo'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Cuaderno',
                                value: ['cuaderno'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Monto inicial Bs.',
                                value: ['saldo_inicial'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Saldo Bs.',
                                value: ['saldo'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            // {
                            //     label: 'Estado',
                            //     value: ['estado'],
                            //     type: 'default',
                            //     multiLine: true,
                            //     lineBold: [0],
                            //     alignRight: true
                            // },
                            {
                                label: 'Fecha de Prestamo',
                                value: ['createdAt'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true,
                                transform: true,
                                func: formatDefaultDate
                            },
                            {
                                label: 'Fecha límite',
                                value: ['fecha_limite'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true,
                                transform: true,
                                func: formatDefaultDate
                            },
                        ]}
                    />

                    <CardDeudas
                        title='Garantías'
                        color='yellow'
                        urlApi='/deudaEnvases/garantías/pag'
                        headers={[
                            {
                                label: 'Deudor',
                                value: ['deudorCodigo', 'deudorDetalles'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Detalles',
                                value: ['tipo'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Cuaderno',
                                value: ['cuaderno'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Monto inicial Bs.',
                                value: ['saldo_inicial'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            {
                                label: 'Saldo Bs.',
                                value: ['saldo'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true
                            },
                            // {
                            //     label: 'Estado',
                            //     value: ['estado'],
                            //     type: 'default',
                            //     multiLine: true,
                            //     lineBold: [0],
                            //     alignRight: true
                            // },
                            {
                                label: 'Fecha de Prestamo',
                                value: ['createdAt'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true,
                                transform: true,
                                func: formatDefaultDate
                            },
                            {
                                label: 'Fecha límite',
                                value: ['fecha_limite'],
                                type: 'default',
                                multiLine: true,
                                lineBold: [0],
                                alignRight: true,
                                transform: true,
                                func: formatDefaultDate
                            },
                        ]}
                    />
                </>
            }
        </div>

    )
}
// https://ionic.io/ionicons/v2