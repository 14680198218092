import React from 'react'
import { Button } from 'react-bootstrap'

export default function ButtonIcon({
    icon,
    variant,
    title,
    dataToggle,
    dataTarget,
    onClick,
    disabled = false,
    color = ""
}) {
    return (
        <Button
            variant={variant}
            style={{ padding: '0px 5px' }}
            title={title}
            data-toggle={dataToggle}
            data-target={dataTarget}
            onClick={onClick}
            disabled={disabled}
            className={color}
        >
            <i className={icon} ></i>
        </Button>
    )
}
