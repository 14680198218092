import React from 'react'


export default function InputBasic(props) {
    return (
        <input
            {...props}
            className='form-control fw-bold'
            style={{fontSize:'14px'}}
        />
    )
}
