import React from 'react'

export default function ButtonSubmitBasic({ text, disabled, color }) {
    return (
        <button
            type="submit"
            className={`btn btn-block fw-bold ${color && color} elevation-3`}
            // style={{ background: '#00bfa5', color: 'white' }}
            style={{
                color: 'rgb(250,250,250)',
                width: '100%'
            }}
            disabled={disabled}
        >
            {text}
        </button>
    )
}
