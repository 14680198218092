import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')


export function DateConvert(dateConv) {
    if (dateConv) {
        var formatDate = 'LLL'
        if (formatDate === 'LLLL') {
            const date = moment(dateConv, 'YYYY-MM-DDTHH:mm:ss').format('LL')
            const hour = moment(dateConv, 'YYYY-MM-DDTHH:mm:ss').format('LT')
            return date + ' a horas ' + hour

        } else if (formatDate === 'utc') {
            if (dateConv === '0001-01-01T00:00:00') {
                return null
            } else {
                return new Date(dateConv)
            }
        } else {
            return moment(dateConv, 'YYYY-MM-DDTHH:mm:ss').format(formatDate)
        }
    }
    return null
}

export function DateConvertEs(date) {
    const monthNamesEs = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    const monthNamesEn = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    var dateGeneral = date.split(" ")
    var count = 0
    var month = dateGeneral[0]

    monthNamesEn.forEach(element => {
        if (dateGeneral[0] === element) {
            month = monthNamesEs[count]
            return month
        }
        count++
    });

    try {
        return month + " " + dateGeneral[1] + " " + dateGeneral[2] + " " + dateGeneral[3] + " " + dateGeneral[4]
    } catch (error) {
        return date
    }
}
export function GetDateHour(dateConv) {
    if (dateConv) {
        var formatDate = 'LLL'
        // if (formatDate === 'LLLL') {
        //     console.log("asd")
        //     const date = moment(dateConv, 'YYYY-MM-DDTHH:mm:ss').format('LL')
        //     const hour = moment(dateConv, 'YYYY-MM-DDTHH:mm:ss').format('LT')
        //     return date + ' a horas ' + hour

        // } else if (formatDate === 'utc') {
        //     if (dateConv === '0001-01-01T00:00:00') {
        //         return null
        //     } else {
        //         return new Date(dateConv)
        //     }
        // } else {
        // const fechaComoCadena = "2020-03-09 23:37:22"; // día lunes
        const dias = [
            'domingo',
            'lunes',
            'martes',
            'miércoles',
            'jueves',
            'viernes',
            'sábado',
        ];
        const numeroDia = new Date(dateConv).getDay();
        const nombreDia = dias[numeroDia];
        // console.log("Nombre de día de la semana: ", nombreDia);

        return nombreDia + ", " + moment(dateConv, 'YYYY-MM-DDTHH:mm:ss').format(formatDate)
        // }
    }
    return dateConv
}
export function GetDate(dateConv) {
    if (dateConv) {
        var formatDate = 'LL'
        const dias = [
            'domingo',
            'lunes',
            'martes',
            'miércoles',
            'jueves',
            'viernes',
            'sábado',
        ];
        const numeroDia = new Date(dateConv).getDay();
        const nombreDia = dias[numeroDia];
        return nombreDia + ", " + moment(dateConv, 'YYYY-MM-DD').format(formatDate)
    }
    return dateConv
}

export function GetOnlyDate(dateConv) {
    if (dateConv) {
        var formatDate = 'LL'
        return moment(dateConv, 'YYYY-MM-DD').format(formatDate)
    }
    return dateConv
}

export const formatInputDate = (date) => {
    const year = date.getUTCFullYear()
    const month = String(date.getUTCMonth() + 1).padStart(2, '0')
    const day = String(date.getUTCDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

export const formatInputLocalDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};