import React from 'react'

export default function ButtonBarFlex({
    text,
    disabled,
    color = '#00bfa5',
    onClick,
    fs,
    icon = ''
}) {
    return (
        <button
            onClick={onClick}
            // style={{ background: '#00bfa5', color: 'white' }}
            style={{
                background: color, color: 'rgb(250,250,250)',
                fontSize: fs ? '14px' : '14px',

                padding: '7px 5px',
                // width:'100%',
                fontWeight: 'bold'
            }}
            className={`py-1 btn elevation-2 border border-none bg-${color}`}
            disabled={disabled}
        >
            {icon !== '' ? <span className={`px-2 h4 ${icon}`}></span>: text}
        </button>
    )
}
