import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import InputBasic from '../../../../components/form/InputBasic'
import InputBeforeIcon from '../../../../components/form/InputBeforeIcon'
import Label from '../../../../components/label/Label'
import { DateConvert } from '../../../../utils/dateConvert'

export default function ModalDetailHistoryNote({ show, handleClose, data }) {

    return (
        <>
            <Modal show={show} onHide={handleClose} className='elevation-1' size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h6 fw-bold text-secondary'>Detalles de la venta</Modal.Title>
                </Modal.Header>
                <form>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <Label
                                    title='Cliente'
                                >
                                    <InputBasic
                                        type='text'
                                        value={data.cliente + ' ' + (data.apellido_paterno ? data.apellido_paterno + ' ' : '') + (data.apellido_materno ? data.apellido_materno + ' ' : '')}
                                        disabled={true}
                                    />
                                </Label>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Label
                                    title='Producto'
                                >
                                    <InputBasic
                                        type='text'
                                        value={data.capacidad + ', ' + data.producto + ', ' + data.descripcion}
                                        disabled={true}
                                    />
                                </Label>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Label
                                    title='Metodo de pago'
                                >
                                    <InputBasic
                                        type='text'
                                        value={data.metodo_pago}
                                        disabled={true}
                                    />
                                </Label>
                            </div>
                            <div className="col-6 mb-2">
                                <Label
                                    title='Cantidad'
                                >
                                    <InputBasic
                                        type='number'
                                        value={data.cantidad}
                                        disabled={true}
                                    />
                                </Label>
                            </div>
                            {
                                data.retornable === 1 &&
                                <>
                                    <div className="col-6 mb-2">
                                        <Label
                                            title='Cantidad turril vacio'
                                        >
                                            <InputBasic
                                                type='number'
                                                value={data.cantidad_vacio}
                                                disabled={true}
                                            />
                                        </Label>
                                    </div>
                                    <div className="col-6 mb-2">
                                        <Label
                                            title='Método de intercambio'
                                        >
                                            <InputBasic
                                                type='text'
                                                value={data.metodo_vacio}
                                                disabled={true}
                                            />
                                        </Label>
                                    </div>
                                    <div className="col-6 mb-2">
                                        <Label
                                            title='Estado de turril'
                                        >
                                            <InputBasic
                                                type='text'
                                                value={data.estado_turril}
                                                disabled={true}
                                            />
                                        </Label>
                                    </div>
                                </>
                            }
                            <div className="col-6 mb-2">
                                <Label
                                    title='Venta por unidad'
                                >
                                    <InputBeforeIcon
                                        type='number'
                                        value={data.precio_unitario}
                                        disabled={true}
                                        param='Bs.'
                                        required={true}
                                        min={1}
                                        step={0.1}
                                    />
                                </Label>
                            </div>
                            <div className="col-6 mb-2">
                                <Label
                                    title='Total de venta'
                                >
                                    <InputBeforeIcon
                                        type='number'
                                        value={data.total_venta}
                                        disabled={true}
                                        param='Bs.'
                                        required={true}
                                        min={1}
                                        step={0.1}
                                    />
                                </Label>
                            </div>
                            <div className="col-6 mb-2">
                                <Label
                                    title='Total cobrado'
                                >
                                    <InputBeforeIcon
                                        type='number'
                                        value={data.total_cobrado}
                                        disabled={true}
                                        param='Bs.'
                                        required={true}
                                        min={1}
                                        step={0.1}
                                    />
                                </Label>
                            </div>
                            <div className="col-6 mb-2">
                                <Label
                                    title='Total deuda'
                                >
                                    <InputBeforeIcon
                                        type='number'
                                        value={data.total_deuda}
                                        disabled={true}
                                        param='Bs.'
                                        required={true}
                                        min={1}
                                        step={0.1}
                                    />
                                </Label>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Label
                                    title='Fecha de venta'
                                >
                                    <InputBasic
                                        type='text'
                                        value={DateConvert(data.created_at)}
                                        disabled={true}
                                    />
                                </Label>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Salir
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}
