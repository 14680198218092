import React from 'react'

export default function IconCheckBordered() {
    return (
        <i
            className="bi bi-check-lg bg-success"
            style={{
                borderRadius: "50%",
                padding:'0px 3px'
            }}
        />
    )
}
