import { Formik, Form } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import ButtonSubmitSave from '../../../components/button/ButtonSubmitSave'
import InputBeforeIcon from '../../../components/form/InputBeforeIcon'
import Label from '../../../components/label/Label'
import InputTextTarea from '../../../components/textField/InputTextTarea'
import { CreateWareHouseHistorialApi } from '../../../http/Services/HistoryWarehouseApi'
import { GetWareHousesWithUserApi } from '../../../http/Services/WareHouseApi'
import { toastToggleAction } from '../../../redux/action/toastAction'

export default function ModalAlmacenEgreso({ show, handleClose, load, setLoad, wareHouse }) {
    const [buttonDisabled, setButtonDisabled] = useState(false)

    const dispatch = useDispatch()

    const [userOptions, setUserOptions] = useState([])
    const [userOptionsHome, setUserOptionsHome] = useState([])
    const [userOptionsClient, setUserOptionsClient] = useState([])
    const [user, setUser] = useState(null)
    const [ingreso_de_tipo, setIngreso_de_tipo] = useState("Almacen General")


    useEffect(() => {
        const getLists = async () => {
            // vendedores
            let resList = []
            let res = await GetWareHousesWithUserApi(wareHouse.id, "user");
            if (res.status === 200) {
                res.data.map(data =>
                    resList.push({ value: data.id, label: (data.u_nombre + ' ' + (data.u_apellido_paterno ? data.u_apellido_paterno : ' ') + (data.u_apellido_materno ? data.u_apellido_materno : ' ')) })
                )

                setUserOptions(resList)
            }
            else {
                console.log('no hay productos')
            }
            // almacen general
            let resList2 = []
            let res2 = await GetWareHousesWithUserApi(wareHouse.id, "home");
            if (res2.status === 200) {
                res2.data.map(data =>
                    resList2.push({ value: data.id, label: (data.nombre) })
                )

                setUserOptionsHome(resList2)
            }
            else {
                console.log('no hay productos')
            }
            // clientes
            let resList3 = []
            let res3 = await GetWareHousesWithUserApi(wareHouse.id, "client");
            if (res3.status === 200) {
                res3.data.map(data =>
                    resList3.push({ value: data.id, label: (data.c_nombre + ' ' + (data.c_apellido_paterno ? data.c_apellido_paterno : ' ') + (data.c_apellido_materno ? data.c_apellido_materno : ' ')) })
                )

                setUserOptionsClient(resList3)
            }
            else {
                console.log('no hay productos')
            }
        }
        if (show) {
            getLists()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    const handleChange2 = (e) => {
        console.log(e)
        setUser(e)
    }

    const ingreso_de = (e) => {
        // console.log(e)
        console.log(e.target.value)
        setIngreso_de_tipo(e.target.value)
        setUser(null)
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} className='elevation-1' size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h6 fw-bold text-secondary'>Registrar movimiento</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        // user_id: user.value,
                        egre: 'Ingreso',
                        tipo: 'Traspaso',
                        cantidad: 0,
                        // codigo: '',
                        almacen_id: wareHouse.id,
                        obs: ''
                    }}

                    onSubmit={(valores, { resetForm }) => {
                        setButtonDisabled(true)
                        const createHistoryNote = async () => {
                            try {
                                // valores.user_id = user.value;
                                valores.almacen_general_id = user.value;

                                setUser(null)
                                let res = await CreateWareHouseHistorialApi(valores)
                                if (res.status === 200) {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'success',
                                        title: 'Exito!',
                                        message: 'Creado correctamente!',
                                        variant: 'bold'
                                    }))
                                    setLoad(!load)
                                    handleClose()
                                    setButtonDisabled(false)
                                    resetForm()
                                    // confirmedAction()
                                }
                                else {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'warning',
                                        title: 'Alerta!',
                                        message: 'Los datos podrian no haberse cargado!',
                                        variant: 'bold'
                                    }))
                                    // setButtonDisabled(false)
                                }
                            } catch (error) {
                                console.log(error)
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'danger',
                                    title: 'Error al registrar venta!',
                                    message: 'Llene los datos correctamente!',
                                    variant: 'bold'
                                }))
                                setButtonDisabled(false)
                            }
                        }

                        createHistoryNote();
                    }}
                >
                    {({ values, handleChange, handleBlur }) => (
                        <React.Fragment>
                            <Form>
                                <Modal.Body>
                                    <div className="row">
                                        {
                                            show && wareHouse.cantidad_actual <= 0 &&
                                            <div className="col-12 text-center text-danger">
                                                <strong>El almacen esta vacio!</strong>
                                            </div>
                                        }
                                        <div className="col-sm-6 mb-2">
                                            <Label
                                                title='Ingreso/Reingreso de:'
                                            >
                                                <select
                                                    className="form-select fw-bold"
                                                    name="ingreso_de"
                                                    value={values.ingreso_de}
                                                    onChange={ingreso_de}
                                                    disabled={buttonDisabled}
                                                >
                                                    <option value='Almacen General' label={'Almacen General'} />
                                                    <option value='Vendedores / Puntos de Venta' label={'Vendedores / Puntos de Venta'} />
                                                    <option value='Empresas / Mayoristas' label={'Empresas / Mayoristas'} />
                                                </select>
                                            </Label>
                                        </div>
                                        {
                                            ingreso_de_tipo === "Vendedores / Puntos de Venta" &&
                                            <div className="col-sm-6 mb-2">
                                                <Label
                                                    title='Vendedores / Puntos de Venta'
                                                >
                                                    <Select
                                                        maxMenuHeight={250}
                                                        options={
                                                            userOptions
                                                        }
                                                        name="user_id"
                                                        value={user}
                                                        onChange={handleChange2}
                                                        isDisabled={buttonDisabled}
                                                        // required={true}
                                                        isClearable={true}
                                                        className='fw-bold'

                                                    />
                                                </Label>
                                            </div>
                                        }
                                        {
                                            ingreso_de_tipo === "Almacen General" &&
                                            <div className="col-sm-6 mb-2">
                                                <Label
                                                    title='Almacen General'
                                                >
                                                    <Select
                                                        maxMenuHeight={250}
                                                        options={
                                                            userOptionsHome
                                                        }
                                                        name="user_id"
                                                        value={user}
                                                        onChange={handleChange2}
                                                        isDisabled={buttonDisabled}
                                                        // required={true}
                                                        isClearable={true}
                                                        className='fw-bold'

                                                    />
                                                </Label>
                                            </div>
                                        }
                                        {
                                            ingreso_de_tipo === "Empresas / Mayoristas" &&
                                            <div className="col-sm-6 mb-2">
                                                <Label
                                                    title='Empresas / Mayoristas'
                                                >
                                                    <Select
                                                        maxMenuHeight={250}
                                                        options={
                                                            userOptionsClient
                                                        }
                                                        name="user_id"
                                                        value={user}
                                                        onChange={handleChange2}
                                                        isDisabled={buttonDisabled}
                                                        // required={true}
                                                        isClearable={true}
                                                        className='fw-bold'

                                                    />
                                                </Label>
                                            </div>
                                        }
                                        <div className="col-sm-6 mb-2">
                                            <Label
                                                title='Tipo de egreso'
                                            >
                                                <select
                                                    className="form-select"
                                                    name="tipo"
                                                    value={values.tipo}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    // style={{ boxShadow: 'none' }}
                                                    disabled={buttonDisabled}
                                                >
                                                    <option value='Salida Ventas' label={'Salida Ventas'} />
                                                    <option value='Traspaso' label={'Traspaso'} />
                                                    <option value='Retorno almacén' label={'Retorno almacén'} />
                                                </select>
                                            </Label>
                                        </div>
                                        <div className="col-sm-6 mb-2">
                                            <Label
                                                title='Tipo de ingreso'
                                            >
                                                <select
                                                    className="form-select"
                                                    name="egre"
                                                    value={values.egre}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    // style={{ boxShadow: 'none' }}
                                                    disabled={buttonDisabled}
                                                >
                                                    <option value='Ingreso' label={'Ingreso'} />
                                                </select>
                                            </Label>
                                        </div>
                                        <div className="col-6 mb-2">
                                            <Label
                                                title='Cantidad'
                                            >
                                                <InputBeforeIcon
                                                    id='cantidad'
                                                    placeholder='Cantidad'
                                                    name='cantidad'
                                                    type='number'
                                                    onChange={handleChange}
                                                    value={values.cantidad}
                                                    disabled={buttonDisabled}
                                                    required={true}
                                                    min={1}
                                                    max={values.tipo === "Traspaso" ? wareHouse.cantidad_actual : ''}
                                                />
                                            </Label>
                                        </div>
                                        {/* <div className="col-6 mb-2">
                                            <Label
                                                title='DOC'
                                            >
                                                <InputBeforeIcon
                                                    id='codigo'
                                                    placeholder='DOC'
                                                    name='codigo'
                                                    type='text'
                                                    onChange={handleChange}
                                                    value={values.codigo}
                                                    disabled={buttonDisabled}
                                                    required={true}
                                                />
                                            </Label>
                                        </div> */}
                                        <div className="col-md-6">
                                            <Label
                                                title='Observaciones'
                                            >
                                                <InputTextTarea
                                                    rows={2}
                                                    onChange={handleChange}
                                                    placeholder='Observaciones...'
                                                    value={values.obs}
                                                    name='obs'
                                                    disabled={buttonDisabled}
                                                />
                                            </Label>
                                        </div>
                                        <div className=" col-12 mt-3">
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12 col-lg-6 row d-flex justify-content-center">
                                                    <div className="row table-primary">
                                                        <div className="col-8"><small>Cantidad actual</small></div>
                                                        <div className="col-4  text-primary overflow-hidden">
                                                            <strong>{wareHouse.cantidad_actual}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="row table-success py-1">
                                                        <div className="col-8"><small>Movimiento</small></div>
                                                        <div className="col-4  text-success overflow-hidden">
                                                            <strong>{values.cantidad}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="row table-danger py-1">
                                                        <div className="col-8"><small>Stock restante</small></div>
                                                        <div className="col-4  text-danger overflow-hidden">
                                                            <strong>{values.tipo === "Ingreso" ? (Number(wareHouse.cantidad_actual) + Number(values.cantidad)) : (Number(wareHouse.cantidad_actual) - Number(values.cantidad))}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancelar
                                    </Button>
                                    {/* <ButtonSubmitSave
                                        text='Registrar venta'
                                        color='#00bfa5'
                                        disabled={!buttonSubmit && values.precio_unitario !== 0 && client && values.producto_id !== "0" && values.producto_id !== 0 && ((values.cantidad - values.cantidad_vacio <= 0 && values.metodo_vacio === 'normal') || (values.cantidad - values.cantidad_vacio > 0 && values.metodo_vacio !== 'normal')) ? confirmed : true}
                                    /> */}
                                    <ButtonSubmitSave
                                        text='Registrar Movimiento'
                                        color='#00bfa5'
                                        disabled={user != null ? buttonDisabled : true}
                                    // disabled={buttonDisabled}
                                    />
                                    {/* <input type="submit" value="asd" /> */}
                                </Modal.Footer>
                            </Form>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>
        </>
    )
}
