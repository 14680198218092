import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { DefaultRequestAuth } from '../../http/httpRequest'

export const DefaultTab = ({ data, setData, setItem, item, children }) => {
    const [fullwidth, setFullWidth] = useState(false)
    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch();
    const globalParams = useSelector(state => state.globalParams);

    const getCliente = async () => {
        await DefaultRequestAuth(
            'get',
            item.urlApi + params[data.subId],
            setItem,
            'data',
            dispatch,
        )
    }

    useEffect(() => {
        if (data.selected == null && params[data.subId] !== null && params[data.subId] !== undefined) {
            setData(x => ({ ...x, selected: { id: Number(params[data.subId]) } }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getCliente()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params[data.subId], globalParams.recall2])


    return (
        <div className={`col-12 col-md-${fullwidth ? '12' : '6'} p-1`}>
            {
                item.data === null ?
                    <div className='card'>
                        <div className='card-header p-2 text-secondary text-center'>
                            <i className='fa fa-triangle-exclamation' /> Sin registros para mostrar!
                        </div>
                    </div>
                    :
                    <div className='card'>
                        <div className='card-header p-1'>
                            <div className='row'>
                                <div className='col-8'>
                                    {item.titleCard}
                                </div>
                                <div className='col-4 d-flex justify-content-end'>
                                    <div className='p-0'>
                                        <i
                                            className='bi bi-arrows btn py-0 px-1'
                                            onClick={() => {
                                                setFullWidth(x => !x)
                                            }}
                                        />
                                    </div>
                                    <div className='p-0'>
                                        <i className='bi bi-x-lg btn py-0 px-1' onClick={() => {
                                            setData(prevData => ({ ...prevData, selected: null }))
                                            navigate('..')
                                        }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-body p-0 overflow-hidden'>
                            <div className='d-flex row justify-content-end gap-2 p-2'>
                                {
                                    item?.buttons &&
                                    <div>
                                        {
                                            item?.buttons?.map((button, index) => (
                                                <button
                                                    key={index}
                                                    className={`mr-2 elevation-2 btn btn-${button.className}`}
                                                    onClick={() => button.onClick()}
                                                // disabled={user && (user.departament.nombre === 'Administración' || item.estado !== 'Activo') ? false : true}
                                                />
                                            ))
                                        }
                                    </div>
                                }
                                <div>
                                    <div className='d-flex overflow-scroll d-flex'>
                                        {
                                            item?.tabs?.map((tab, index) => (
                                                <div
                                                    className='nav-item'
                                                    key={index}
                                                    onClick={() => setItem(i => ({ ...i, tabIndex: tab }))}
                                                >
                                                    <a className={`nav-link p-2 rounded-top ${item.tabIndex === tab ? 'active bg-yellow shadow fw-semibold' : ''}`} id='pills-home-tab' data-toggle='pill' href='#pills-home' role='tab' aria-controls='pills-home' aria-selected='true'>{tab}</a>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className='card card-warning card-outline' />
                                    {children}
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}
