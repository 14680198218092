import { CREATE_LOCATION_CLIENT } from "../type";

export const initialState = {
    db: [
        {
            id:1,
            name: "Alberto Quispe",
            status:true,
            lat: "-16.49775373740065",
            long: "-68.19933993464714"
        },
        {
            id:2,
            name: "Maria Luisa",
            status:false,
            lat: "-16.51819156894668",
            long: "-68.158389832073"
        },
        {
            id:3,
            name: "Lucia Beltran",
            status:true,
            lat: "-16.5288097004411",
            long: "-68.20443509353777"
        },
    ]
}

export function locationClientReducer(state = initialState, action) {
    switch (action.type) {
        case CREATE_LOCATION_CLIENT: {

            return {
                ...state,
                db: [action.payload, ...state.db]
            }
        }
        default:
            return state;
    }
}