import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Avatar from '../../../../assets/profile.png'
import { Table } from '../../../../components/table/Table'
import { Td } from '../../../../components/table/Td'
import { Tr } from '../../../../components/table/Tr'
import { GetClientsBarrelDebtorsApi } from '../../../../http/Services/ClientApi'
import { GetGlobalDataApi } from '../../../../http/Services/UserApi'
import { getGlobalDataAction } from '../../../../redux/action/globalDataAction'
import { DateConvert } from '../../../../utils/dateConvert'
import { VerificationImageExist } from '../../../../utils/VerificationImage'
import Detail from '../../../globalParams/Detail'
import Info from '../../../globalParams/filter/Info'
import Paginator from '../../../globalParams/filter/Paginator'
import Searcher from '../../../globalParams/filter/Searcher'
import GeneralContainer from '../../../globalParams/GeneralContainer'
import { onChangeInputData, order, orderNum } from '../../../globalParams/GlobalDataFilter'
import Loading from '../../../globalParams/Loading'
import { ThParam } from '../../../globalParams/ThParam'


export default function BarrelDebtors() {

    const dispatch = useDispatch()

    const [stateData, setStateData] = useState("loading...")
    const [load, setLoad] = useState(false)
    const [data, setData] = useState([])
    const [back, setBack] = useState([])
    const [items, setItems] = useState([])
    const [item, setItem] = useState({})
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [total, setTotal] = useState(0)
    const [desc, setDesc] = useState(true)
    const [byQuery, setByQuery] = useState("")
    const [full, setFull] = useState(true)


    useEffect(() => {
        setStateData("loading...")
        setItem({})
        const getOrders = async () => {
            try {
                let res = await GetClientsBarrelDebtorsApi();
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        setStateData("")
                        setByQuery("")
                    }
                    else {
                        setStateData("sin registros...")
                    }
                    setDesc(false)
                    setCurrentPage(1)
                    setData(res.data)

                    getGlobalData()
                }
            } catch (error) {
                setStateData("Error al cargar lista")
            }

        }
        getOrders()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load])


    useEffect(() => {
        setTotal(back.length)
        let div = back.length / perPage
        if (div - parseInt(div) === 0) {
            setTotalPages(div)
        }
        else {
            setTotalPages(parseInt(div) + 1)
        }
    }, [back, perPage])

    useEffect(() => {
        setCurrentPage(1)
    }, [perPage])


    useEffect(() => {
        // no replicar a = b por que se toma como si fuera el mismo
        const dataBackup = Array.from(data)
        setItems([
            ...dataBackup
                // .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery) || a.telefono.toLowerCase().includes(byQuery))
                .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery))
                .splice((currentPage * perPage - perPage), perPage)
        ])
        setBack([
            ...data
                // .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery) || a.telefono.toLowerCase().includes(byQuery))
                .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery))
        ])

        let div = back.length / perPage
        if (div - parseInt(div) === 0) {
            setTotalPages(div)
        }
        else {
            setTotalPages(parseInt(div) + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, currentPage, perPage])

    const getGlobalData = async () => {
        try {
            let res = await GetGlobalDataApi();
            if (res.status === 200) {
                dispatch(getGlobalDataAction(res.data))
            }
        } catch (error) {
            console.log('error in reducers')
        }
    }

    const onChangeInput = (e) => {
        onChangeInputData(
            setByQuery,
            e,
            data,
            currentPage,
            setCurrentPage,
            setItems,
            perPage,
            setBack,
            setStateData
        )
    }


    return (
        <GeneralContainer>
            <div className="row gx-3">
                <div className={`col-12 col-md-${full ? '12' : '6'}`}>
                    {
                        <>
                            <div className="card card-danger card-outline">
                                <div className="p-1">
                                    <Searcher
                                        onChangeInput={onChangeInput}
                                        byQuery={byQuery}
                                    />
                                </div>
                                <div className="p-1">
                                    <Paginator
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        totalPages={totalPages}
                                        setPerPage={setPerPage}
                                        perPage={perPage}
                                        setLoad={setLoad}
                                        load={load}
                                        stateData={stateData}
                                        setFull={setFull}
                                        full={full}
                                    />
                                </div>
                                <div className=" p-1 table-warning rounded-top">
                                    <Info
                                        total={total}
                                        currentPage={currentPage}
                                        perPage={perPage}
                                        totalPages={totalPages}
                                    />
                                </div>
                                <div className="card-body p-1">
                                    <div className="content-basic">
                                        <Table>
                                            <thead>
                                                <Tr>
                                                    {/* <ThParam
                                    label='Id'
                                    onClick={() => orderNum('id')}
                                /> */}
                                                    <ThParam
                                                        label='Código'
                                                        onClick={() => order('codigo', data, desc, setData, setDesc)}
                                                        width='110px'
                                                    />
                                                    <ThParam
                                                        label='Cliente'
                                                        onClick={() => order('nombre', data, desc, setData, setDesc)}
                                                        width='130px'
                                                    />
                                                    <ThParam
                                                        label='Saldo'
                                                        onClick={() => orderNum('saldo_cantidad', data, desc, setData, setDesc)}
                                                        width='90px'
                                                    />
                                                    <ThParam
                                                        label='Estado'
                                                        onClick={() => order('estado', data, desc, setData, setDesc)}
                                                        width='85px'
                                                    />
                                                    <ThParam
                                                        label='Tipo'
                                                        onClick={() => order('metodo_vacio', data, desc, setData, setDesc)}
                                                        width='85px'
                                                    />
                                                    <ThParam
                                                        label='Fecha límite'
                                                        onClick={() => order('fecha_limite', data, desc, setData, setDesc)}
                                                        width='130px'
                                                    />
                                                    <ThParam
                                                        label='Fecha de registro'
                                                        onClick={() => orderNum('id', data, desc, setData, setDesc)}
                                                        width='170px'
                                                    />
                                                </Tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    stateData !== "" ?
                                                        <tr>
                                                            <td colSpan={5}>
                                                                {stateData === "loading..."
                                                                    ?
                                                                    <Loading />
                                                                    :
                                                                    <div
                                                                        style={{
                                                                            fontSize: '14px',
                                                                            fontWeight: 'bold',
                                                                            color: 'rgba(0,0,0,.4)',
                                                                            padding: '5px'
                                                                        }}
                                                                    >
                                                                        {stateData}
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                        :
                                                        items?.map((data) => (
                                                            <Tr
                                                                key={data.id}
                                                                onClick={() => setItem(data)}
                                                                mark={item && item.id === data.id && true}
                                                            >
                                                                <Td
                                                                    width='110px'
                                                                >
                                                                    <div className="row g-0">
                                                                        <div className="col-12 d-flex"
                                                                            style={{
                                                                                alignItems: 'center',
                                                                                overflow: 'hidden'
                                                                            }}
                                                                        >
                                                                            <img
                                                                                className="p-0"
                                                                                src={data && data.avatar
                                                                                    ?
                                                                                    require(`../../../../assets/${VerificationImageExist(data.avatar)}`) : Avatar}
                                                                                alt="img"
                                                                                style={{
                                                                                    width: '30px',
                                                                                    height: '30px',
                                                                                    alignItems: 'center',
                                                                                    textAlign: 'center',
                                                                                    verticalAlign: 'middle'
                                                                                }}
                                                                            />
                                                                            <span className='pl-1'>
                                                                                {data.c_codigo ? data.c_codigo : '---- ----'}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Td>
                                                                <Td
                                                                    width='130px'
                                                                >
                                                                    {data.nombre}{' '}
                                                                    {data.apellido_paterno}{' '}
                                                                    {data.apellido_materno}
                                                                </Td>
                                                                <Td
                                                                    width='90px'
                                                                >
                                                                    {data.saldo_cantidad}
                                                                </Td>
                                                                <Td
                                                                    width='85px'
                                                                >
                                                                    {
                                                                        // user && user.departament.nombre === "Administración" &&
                                                                        <div
                                                                            className='btn btn-link text-decoration-none p-0'
                                                                        >
                                                                            <small><b><i className={`text-${data.estado ? (data.estado === 'pendiente' ? 'danger' : (data.estado === 'entregado' ? 'success' : 'secondary')) : 'secondary'}`}>{data.estado}</i></b></small>
                                                                        </div>
                                                                    }
                                                                </Td>
                                                                <Td
                                                                    width='85px'
                                                                >
                                                                    <div
                                                                        className='btn btn-link text-decoration-none p-0'
                                                                    >
                                                                        <small><b><i className={`text-${data.metodo_vacio ? (data.metodo_vacio === 'garantia' ? 'secondary' : (data.metodo_vacio === 'prestamo') ? 'primary' : 'dark') : ''}`}>{data.metodo_vacio}</i></b></small>
                                                                    </div>
                                                                </Td>
                                                                <Td
                                                                    width='130px'
                                                                >
                                                                    {data.fecha_limite ? data.fecha_limite : ''}
                                                                </Td>
                                                                <Td
                                                                    width='170px'
                                                                >
                                                                    {data.created_at ? DateConvert(data.created_at) : ''}
                                                                </Td>
                                                            </Tr>
                                                        ))
                                                }
                                            </tbody >
                                        </Table >
                                    </div>
                                </div>
                                {
                                    total > perPage && stateData !== "loading..." &&
                                    <>
                                        <div className=" p-1 table-warning rounded-top">
                                            <Info
                                                total={total}
                                                currentPage={currentPage}
                                                perPage={perPage}
                                                totalPages={totalPages}
                                            />
                                        </div>
                                        <div className="p-1">
                                            <Paginator
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                totalPages={totalPages}
                                                setPerPage={setPerPage}
                                                perPage={perPage}
                                                setLoad={setLoad}
                                                load={load}
                                                stateData={stateData}
                                                setFull={setFull}
                                                full={full}
                                                hiddenpaginator={true}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
                {
                    item.id &&
                    <div className={`col-12 col-md-${full ? '12' : '6'}`}>
                        <div className="card">
                            <div className='card-header p-1'>
                                <div className='row'>
                                    <div className='col-8'>
                                        Detalles de cliente
                                    </div>
                                    <div className="col-4 d-flex justify-content-end">
                                        <div className='p-0'>
                                            <i className='bi bi-x-lg btn py-0 px-1' onClick={() => setItem({})} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <Detail
                                    detail={[
                                        { label: 'Código cliente', value: item.codigo },
                                        { label: 'Cliente', value: item.nombre + ' ' + (item.apellido_paterno ? item.apellido_paterno : '') + ' ' + (item.apellido_materno ? item.apellido_materno : '') },
                                        { label: 'Telefono cliente', value: item.telefono },
                                        { label: 'Producto', value: item.producto },
                                        { label: 'Estado de la deuda', value: item.estado ? item.estado : '--', color: item.estado === "entregado" ? 'table-success' : (item.estado === "pendiente" ? 'table-danger' : 'table-secondary') },
                                        { label: 'Método de intercambio', value: item.metodo_vacio },
                                        { label: 'Saldo inicial', value: item.saldo_cantidad_inicial },
                                        { label: 'Saldo restante', value: item.saldo_cantidad },
                                        { label: 'Fecha límite de prestamo', value: DateConvert(item.fecha_limite) },
                                        { label: 'Fecha de apertura', value: DateConvert(item.created_at) }
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </GeneralContainer>
    )
}