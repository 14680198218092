import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RequestAuthPaginated } from '../../../http/httpRequest';
import { DefaultTable } from '../../../components/table/DefaultTable';

export const CardDeudas = ({ headers, title, color, urlApi }) => {
    const dispatch = useDispatch();
    const globalParams = useSelector(state => state.globalParams)
    const [dataDeudas, setDataDeudas] = useState({
        data: [],
        recall: true,
        nav: false,
        navParam: '',
        navRef: 'id',
        loading: false,
        subId: 'cuaderno_id',
        params: {
            totalItems: 0,
            totalPages: 1,
            currentPage: 1,
            pageSize: 25,
            // nombres: ''
            filterBy: 'nombres',
            filterParam: ''
        },
        filters: ['nombres', 'codigo'],
        selected: null,
        modalCrearCliente: false,
        modalEditarCliente: false,
        modalEliminarCliente: false
    })
    const getClientes = async () => {
        await RequestAuthPaginated(
            'get',
            urlApi,
            setDataDeudas,
            'data',
            dispatch,
            dataDeudas.params
        )
    }

    useEffect(() => {
        getClientes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataDeudas.recall, dataDeudas?.params?.pageSize, dataDeudas?.params?.currentPage, dataDeudas.params?.nombres, dataDeudas.params?.filterParam, dataDeudas.params?.filterBy, globalParams.recall1])

    return (
        // <GeneralContainer>
        <div className={`col-12 col-md-6`}>
            <div className='card'>
                <div className={`card-header bg-${color} text-center fw-bold font-italic text-lg p-1`}>
                    {title}
                </div>
                <DefaultTable
                    showSearch={false}
                    data={dataDeudas}
                    setData={setDataDeudas}
                    headers={headers}
                    fw={true}
                />
            </div>
        </div>
        // </GeneralContainer>
    )
}
